import { getDefaultState } from './state';

export default {
  showMessage(state, payload) {
    state.notificationText = payload.text;
    state.notificationColor = payload.color;
    state.notificationIimeout = payload.timeout;
  },
  reset(state) {
    const initialState = getDefaultState();
    Object.keys(initialState).forEach((key) => {
      state[key] = initialState[key];
    });
  },
};
