export default {
  namespaced: true,
  state: {
    videoBooking: false,
  },
  getters: {},
  mutations: {
    setVideoBooking(state, changedValue) {
      state.videoBooking = changedValue;
    },
  },
  actions: {},
};
