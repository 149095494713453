const commonRoutes = [
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import('@/views/employee/PersonalProfile.vue'),
    meta: {
      layout: 'content',
      titleKey: 'profileTitle',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/account-settings/AccountSettings.vue'),
    meta: {
      layout: 'content',
      titleKey: 'pageTitleSettings',
    },
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('@/views/Error403.vue'),
    meta: {
      layout: 'content',
      titleKey: 'Forbidden',
    },
  },
];

export default commonRoutes;
