<template>
  <div v-if="text" name="snackbars">
    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      :top="'top'"
      :style="{
        top: overlayShown
          ? '0px'
          : $route.name === 'meine-bav'
          ? '98px'
          : $vuetify.breakpoint.smAndDown
          ? '62px'
          : '56px',
        zIndex: overlayShown ? '9000' : $vuetify.breakpoint.smAndDown ? '9000' : '7',
      }"
      fixed
      style="z-index: 7"
      content-class="toast"
    >
      {{ $t(text) }}

      <template v-if="timeout === '-1'" v-slot:action="{ attrs }">
        <v-icon v-bind="attrs" color="white" size="20" class="mr-1" @click="show = false">
          {{ icons.mdiCloseCircle }}
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiCloseCircle } from '@mdi/js';
import { ref, getCurrentInstance, computed, onMounted, watch } from '@vue/composition-api';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const show = ref(false);
    const color = ref('');
    const text = ref('');
    const timeout = ref(-1);
    const overlayShown = computed(() => vm.$store.state.app.shallContentShowOverlay);

    return {
      overlayShown,
      show,
      color,
      text,
      timeout,
      icons: { mdiCloseCircle },
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage' && !this.show) {
        this.text = state.notifications.notificationText;
        this.color = state.notifications.notificationColor;
        this.timeout = state.notifications.notificationIimeout;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss">
.toast {
  max-width: calc(100vw - 365px);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}
.v-snack__wrapper {
  min-width: calc(100vw - 336px) !important;
  max-width: 1440px !important;
  border-radius: 3px !important;
}

@media only screen and (max-width: 959px) {
  .toast {
    max-width: calc(100vw - 32px);
  }
  .v-snack__wrapper {
    z-index: 9000;
    transform: translateY(-15px);
    min-width: calc(100vw - 32px) !important;
    max-width: calc(100vw - 32px) !important;
    border-radius: 0 !important;
  }
}
</style>
