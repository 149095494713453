export default {
  uploadQueue(state) {
    return state.uploadQueue;
  },
  uploadedData(state) {
    return state.uploadedData;
  },
  cancelRequest(state) {
    return state.cancelRequest;
  },
  currentStatus(state) {
    return state.currentStatus;
  },
  totalProgress(state) {
    return state.totalProgress;
  },
  failedUploads(state) {
    return state.failedUploads;
  },
  requestInProgress(state) {
    return state.uploadQueue.length;
  },
};
