// eslint-disable-next-line object-curly-newline
import { mdiCartOutline, mdiChartTimelineVariant, mdiInformation, mdiMonitorDashboard } from '@mdi/js';

export default [
  {
    title: 'menuBavInfo',
    icon: mdiInformation,
    to: 'bav-info',
    action: 'read',
  },
];
