import * as Sentry from '@sentry/vue';
import { login, twoFactorLogin, changePassword, changePasswordRequired } from '../../../api/fusion-auth';
import { getCompaniesList, getCompanyById, getCompanyConfig, getCompanyWelcomeConfig } from '../../../api/companies';
import { getContractByEmployeeId, getEmployeeById } from '../../../api/employee';
import router from '@/router';
import { i18n } from '@/plugins/i18n';
import { SET_PIWIK_COMPANY_ID_DIMENSION, SET_PIWIK_USER_ID } from '@/plugins/piwik/dimensions';

export const LOGIN_ACTION = 'LOGIN';
export const IDENTIFY_USER = 'IDENTIFY_USER';
export const LOGIN_TWO_FACTOR = 'LOGIN_TWO_FACTOR';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_REQUIRED = 'CHANGE_PASSWORD_REQUIRED';
export const DETECT_LANGUAGE = 'DETECT_LANGUAGE';

export default {
  async [CHANGE_PASSWORD]({ commit }, payload) {
    try {
      await changePassword(payload.currentPassword, payload.password);
      commit('showMessage', {
        text: 'successChangePassword',
        color: '#4caf50',
        timeout: '-1',
      });
    } catch (e) {
      if (
        e.response.data.fieldErrors &&
        e.response.data.fieldErrors.password[0].code === '[breachedPasswordOnly]password'
      ) {
        commit('showMessage', {
          text: 'errorBreachedPassword',
          color: 'error',
          timeout: '-1',
        });
      } else {
        commit('showMessage', {
          text: 'errorChangePassword',
          color: 'error',
          timeout: '-1',
        });
      }
    }
  },
  async [CHANGE_PASSWORD_REQUIRED]({ commit }, payload) {
    try {
      await changePasswordRequired(payload.password, payload.token);
      router.push('/');
      commit('showMessage', {
        text: 'successChangePassword',
        color: '#4caf50',
        timeout: '-1',
      });
    } catch (e) {
      if (
        e.response &&
        e.response.data.fieldErrors &&
        e.response.data.fieldErrors.password[0].code === '[breachedPasswordOnly]password'
      ) {
        commit('showMessage', {
          text: 'errorBreachedPassword',
          color: 'error',
          timeout: '-1',
        });
      } else {
        commit('showMessage', {
          text: 'errorChangePassword',
          color: 'error',
          timeout: '-1',
        });
      }
    }
  },
  async [DETECT_LANGUAGE]({ state, commit, dispatch, getters }, user) {
    const { preferredLanguages } = user;

    let companyLangForm = 'de_form';

    if (state.companyData && state.companyData?.languagePreference) {
      companyLangForm = state.companyData.languagePreference.form === 'LANGUAGE_INFORMAL' ? 'de_coll' : 'de_form';

      commit('setCompanyLangForm', companyLangForm);
    }

    if (preferredLanguages.length) {
      i18n.locale = preferredLanguages[0] === 'de' ? companyLangForm : preferredLanguages[0];
    } else {
      i18n.locale = companyLangForm;
    }
  },
  async [IDENTIFY_USER]({ state, commit, dispatch, getters }, user) {
    let welcomeConfig;

    if (!user.data || !user.data.companyId) {
      const companies = await getCompaniesList(0, 0);
      user.data = {
        companyId: companies.data?.page[0]?.id ?? '',
        companyIdsAssigned: companies.data?.page?.map(({ id }) => id) ?? [],
        employeeId: user.id,
      };
    } else {
      getEmployeeById(user.id).then(async () => {
        const contract = await getContractByEmployeeId(user.id);
        commit('setContract', contract.contractStatus || {});
      });
    }
    const companySettings = await getCompanyById(user.data.companyId);
    const companyConfig = await getCompanyConfig(user.data.companyId);

    commit('setCompanyData', companySettings?.data);
    commit('setCompanyConfig', { ...companyConfig?.data, welcomeConfig });

    dispatch(DETECT_LANGUAGE, user);
    SET_PIWIK_USER_ID(user.id);
    SET_PIWIK_COMPANY_ID_DIMENSION(user.data.companyId);

    // persist 2fa state and set user id for Sentry
    // this.$auth.setUser({
    //     ...this.$auth.user,
    //     twoFactorMethod: state.twoFactorMethod,
    //     companySettings: companySettings || { settings2fa: 'DISABLE' },
    //     userId: userId,
    // })

    if (
      !state.twoFactorId &&
      companySettings &&
      companySettings.data &&
      companySettings.data.settings2fa !== 'DISABLE'
    ) {
      if (companySettings.data.settings2fa === 'FORCE') {
        return router.push('/2fa');
      }
    }

    Sentry.setUser(user);
    commit('setLoggedInStatus', true);
    return router.push('/');
  },
  async [LOGIN_TWO_FACTOR]({ commit, dispatch }, payload) {
    return twoFactorLogin(payload.twoFactorId, payload.code).then((res) => {
      commit('setAccessToken', res.data.token);
      commit('setUserData', res.data.user);
      commit('determineActiveRole', res.data.user.registrations[0].roles);

      dispatch(IDENTIFY_USER, res.data.user);
    });
  },
  async [LOGIN_ACTION]({ commit, dispatch }, payload) {
    commit('setLoginError', null);

    return login(payload.email, payload.password)
      .then((res) => {
        if (res.data.changePasswordId) {
          localStorage.setItem('accessToken', res.data.changePasswordId);
          router.push({
            name: 'change-password',
            query: {
              token: res.data.changePasswordId,
              leakedPassword: true,
            },
          });
        }

        if (res.data.twoFactorId) {
          if (res.data.methods && res.data.methods.length > 0) {
            commit('setTwoFactorMethod', res.data.methods[res.data.methods.length - 1]);
          }
          commit('setTwoFactorId', res.data.twoFactorId);
          router.push('/2fa-login');
        } else if (res.data.user) {
          commit('setAccessToken', res.data.token);
          commit('setUserData', res.data.user);
          commit('determineActiveRole', res.data.user.registrations[0].roles);
          dispatch(IDENTIFY_USER, res.data.user);
        }
      })
      .catch((err) => {
        if (err.hasOwnProperty('name')) {
          switch (err.name) {
            case 'PrivacyPolicyNotAcceptedError':
              this.app.router.push({
                name: 'accept-privacy-policy',
                query: {
                  fromWelcomePage: data.fromWelcomePage,
                },
              });
              break;

            case 'PasswordChangeRequired':
              router.push({
                name: 'change-password',
                query: {
                  token: err.changePasswordId,
                  fromWelcomePage: data.fromWelcomePage,
                  leakedPassword: true,
                },
              });
              break;

            default:
              commit('setLoginError', 'errorLoginCredentials');
          }
        } else {
          commit('setLoginError', 'errorLoginCredentials');
        }
      });
  },
};
