import { uploadDocument } from '@/api/document';

export default {
  async createController({ commit }, payload) {
    const controller = new AbortController();
    commit('addRequest', controller);
    return controller;
  },
  async uploadDocuments({ commit }, payload) {
    const upload = await uploadDocument(payload.formData, payload.controller);
    return upload.data;
  },
};
