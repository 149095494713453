import axios from '@/plugins/axios';

export const getAssignedCustomerSuccessManager = (id) => {
  return axios.get(`/consultants/companies/${id}`);
};

export const getAssignedEmployeeConsultant = (id) => {
  return axios.get(`/consultants/employees/${id}`);
};

export const fetchConsultants = () => {
  return axios.get(`/consultants`);
};

export const unassignConsultant = (companyId, consultantId) => {
  return axios.delete(`/consultants/${consultantId}/companies/${companyId}`);
};

export const assignConsultant = (companyId, consultantId) => {
  return axios.put(`/consultants/${consultantId}/companies`, {
    companyId,
  });
};
