import axios from '@/plugins/axios';
import { LANGUAGE_FORMAL, LANGUAGE_INFORMAL, ORG_TYPE_NEW } from '@/constants';
import { config } from '../../../appConfig';

const ASSETS_URL = config.variables.ASSETS_BASE_URL;

const buildQuery = (offset, limit, search) => {
  let query = '';

  if (offset || offset === 0) {
    query += `&offset=${offset}`;
  }

  if (limit || limit === 0) {
    query += `&limit=${limit}`;
  }

  if (search) {
    query += `&searchString=${search}`;
  }

  return query;
};

export const getCompaniesList = (offset = 0, limit = 15, search) => {
  const q = buildQuery(offset, limit, search);
  return axios.get(`/companies?${q}`);
};
export const getCompanyById = (companyId) => axios.get(`/companies/${companyId}`);
export const getCompanyConfig = (companyId, type = 'api') => {
  let url = `onboarding/company/${companyId}`;

  if (type !== 'api') {
    url = `${ASSETS_URL}/companies/${companyId}/${companyId}.json`;
  }

  return companyId ? axios.get(url) : null;
};
export const getCompanyWelcomeConfig = (companyId) => axios.get(`${ASSETS_URL}/companies/welcome/${companyId}.json`);
export const getCompanyExampleCalculations = (type, companyId) => {
  let url = `${ASSETS_URL}/examples/degura_examples.json`;

  if (type === 'custom') {
    url = `${ASSETS_URL}/companies/${companyId}/examples/examples.json`;
  }

  if (type === 'api') {
    url = `/exampleCalculations/${companyId}/`;
  }
  return axios.get(url);
};
export const createExampleCalculation = async (id, fileDE, fileEN, data, isTemplate = false) => {
  const url = isTemplate ? `/exampleCalculations/${id}/organization` : `/exampleCalculations/${id}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let retId;
  const payload = new FormData();
  payload.append('fileDE', fileDE);
  payload.append('fileEN', fileEN);

  payload.append(
    'exampleCalculations',
    new Blob([JSON.stringify(data)], {
      type: 'application/json',
    }),
  );

  await axios.post(url, payload, config);
  return retId;
};

export const editCompanyExampleCalculation = async (calculationId, fileDE, fileEN, data, isTemplate = false) => {
  const url = `/exampleCalculations/calculation/${calculationId}${isTemplate ? '?editTemplate=true' : ''}`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  let retId;
  const payload = new FormData();
  payload.append('fileDE', fileDE);
  payload.append('fileEN', fileEN);

  payload.append(
    'exampleCalculations',
    new Blob([JSON.stringify(data)], {
      type: 'application/json',
    }),
  );

  await axios.put(url, payload, config);
  return retId;
};

export const deleteCompanyExampleCalculation = async (id, payload) => {
  const url = `/exampleCalculations/calculation/${id}`;

  await axios.delete(url).catch((err) => {
    throw new Error(err);
  });
};

export const useOrgTemplateForCompany = (companyId, calculationId) => {
  return axios.post('/exampleCalculations/template', {
    companyId,
    calculationId,
  });
};

export const removeOrgTemplateForCompany = (companyId, calculationId) => {
  return axios.delete('/exampleCalculations/template', {
    data: {
      companyId,
      calculationId,
    },
  });
};

export const getOrgTemplateUsage = (calculationId) => {
  return axios.get(`/exampleCalculations/template/${calculationId}`);
};

export const getExampleCalculationsTemplates = async () => {
  const url = '/exampleCalculations/template';
  return axios.get(url);
};

export const getCompanyProviderPlan = (type, companyId, providerKey, planKey) => {
  let baseUrl = `${ASSETS_URL}/providers`;

  if (type === 'custom') {
    baseUrl = `${ASSETS_URL}/companies/${companyId}/providers`;
  }

  if (type === 'api') {
    baseUrl = `/onboarding/${companyId}/plans`;
    return axios.get(baseUrl);
  }
  return axios.get(`${baseUrl}/${providerKey}/plans/${planKey}/plan.json`);
};

export const getProviderDetails = (providerId) => {
  return axios.get(`/providers/${providerId}`);
};

export const deactivateCompany = (companyId) => axios.delete(`/companies/${companyId}/deactivate`);

const createOrg = async (name) => {
  let retId;
  await axios
    .post('/organizations', { organizationName: name })
    .then((res) => (retId = res.data.id))
    .catch((err) => {
      throw new Error(err);
    });
  return retId;
};

export const createCompany = async (payload) => {
  let sanitizedOrgId = payload.orgId;
  if (payload.orgType === ORG_TYPE_NEW) {
    await createOrg(payload.organization)
      .then((res) => (sanitizedOrgId = res))
      .catch((err) => {
        throw new Error(err);
      });
  }

  const data = {
    companyName: payload.name,
    employeeFacingName: payload.displayName,
    organization: {
      id: sanitizedOrgId,
    },
    languagePreference: {
      language: payload.language.slice(0, 2),
      form: payload.useFormalLang ? LANGUAGE_FORMAL : LANGUAGE_INFORMAL,
    },
  };

  const createdCompanyId = await axios.post('/companies', data).catch((err) => {
    throw new Error(err);
  });
  return createdCompanyId.data;
};

export const changeCompany2faSettings = (id, settings) => {
  return axios.post(`/companies/${id}?settings2fa=${settings}`);
};

export const updateCompany = (id, payload) => {
  return axios.put(`/companies/${id}`, payload);
};
