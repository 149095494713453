<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      :persistent="persistent"
      :max-width="maxWidth"
      :transition="isFullScreen ? 'dialog-bottom-transition' : 'dialog-transition'"
      :fullscreen="isFullScreen"
      :message="message"
    >
      <v-card>
        <v-toolbar v-if="isFullScreen" dark color="primary" :class="isFullScreenFixed ? 'v-toolbar--fixed' : ''">
          <slot name="actionsFullScreenClose"></slot>
          <slot name="title" :title="title">
            <v-toolbar-title class="white--text pl-3 pl-sm-5">{{ title }}</v-toolbar-title>
          </slot>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <slot name="actionsFullScreenSave"></slot>
          </v-toolbar-items>
        </v-toolbar>
        <slot v-if="!isFullScreen" name="title" :title="title">
          <v-card-title v-if="title" :class="align == 'center' ? 'justify-center' : ''">
            <span class="headline">{{ title }}</span>
          </v-card-title>
          <v-card-text v-if="message">
            <p class="text-center mb-0">{{ message }}</p>
          </v-card-text>
        </slot>

        <div
          v-if="success || warning || error"
          class="v-card__text d-flex flex-column align-center justify-center text-center"
        >
          <v-avatar v-if="success" size="70" color="success" class="mb-5 v-avatar--dialog">
            <v-icon color="white" size="40">
              {{ icons.mdiCheckBold }}
            </v-icon>
          </v-avatar>

          <v-avatar v-if="error" size="70" color="white" class="mb-5 mt-5 v-avatar--dialog">
            <v-icon color="error" size="90">
              {{ icons.mdiAlertCircle }}
            </v-icon>
          </v-avatar>

          <v-avatar v-if="warning" size="70" color="white" class="mb-5 mt-5 v-avatar--dialog">
            <v-icon color="warning" size="90">
              {{ icons.mdiAlertCircle }}
            </v-icon>
          </v-avatar>

          <slot name="content"></slot>
        </div>

        <div v-else class="v-card__text pl-0 pr-0 pl-sm-5 pr-sm-5">
          <div :class="hasForm === true ? 'container' : ''">
            <slot name="content"></slot>
          </div>
        </div>

        <div v-if="!isFullScreen" class="v-card__actions" :class="stacked === true ? 'd-flex flex-column' : ''">
          <v-spacer v-if="align == 'center'"></v-spacer>
          <slot name="actions"></slot>
          <v-spacer v-if="align == 'center'"></v-spacer>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiCheckBold, mdiAlertCircle, mdiClose } from '@mdi/js';
import { getCurrentInstance, toRefs, watch } from '@vue/composition-api';

export default {
  name: 'ModalWindow',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 600,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    isFullScreenFixed: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
    },
    hasForm: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'center',
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { isOpen } = toRefs(props);

    watch(isOpen, (v) => {
      vm.$store.commit('app/TOGGLE_CONTENT_OVERLAY', v);
    });

    return {
      icons: {
        mdiCheckBold,
        mdiAlertCircle,
        mdiClose,
      },
    };
  },
};
</script>

<style lang="scss">
header.v-toolbar--fixed {
  border-radius: 0 !important;
  z-index: 9000 !important;
}
.v-card__title {
  word-break: break-word;
}
.v-dialog__content--active .v-toolbar--fixed {
  position: fixed;
  margin-left: 1px;
  margin-right: 1px;
  left: 0;
  right: 0;
  z-index: 1;
  + div {
    padding-top: 64px !important;
  }
}

.v-avatar--dialog {
  span {
    border-radius: 0 !important;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-toolbar__title {
    font-size: 16px !important;
  }
}
</style>
