/* eslint-disable max-len */
export default {
  agb: 'General Terms and Conditions',
  dpp: 'Data Privacy Policy',
  acceptAgbDppStart: 'I hereby confirm that I have read the ',
  acceptAgbDppAnd: ' and the ',
  acceptAgbDppEnd: ' and agree to them',
  // Forbidden page
  forbiddenPageTitle: 'Sorry, you don‘t have permission to access this content.',
  forbiddenPageBtn: 'Go back',
  // Not found page
  notFoundPageTitle: 'Ooops, we could not find the page you requested.',
  notFoundPageDescriptionNotLogged:
    'Maybe you are using a link, that is no longer available. Please “login here” and update your bookmark (optional).',
  notFoundPageDescriptionLogged:
    'Maybe you are using a link, that is no longer available.  Please “login here” and update your bookmark (optional).',
  notFoundBtnLogged: 'Go to login',
  notFoundBtnNotLogged: 'Go to login',
  welcomeChangePwTitle: 'Welcome to your Degura Pension Cloud',
  welcomeChangePwDescription:
    'Please choose a secure password. The password should be minimum 8 characters long and contain at least 1 upper case letter, 1 lower case letter, 1 number and 1 special character. In the next step you can log in with your email and your password.',
  setupBavDirectly: 'Set up bAV directly',
  setupBavDirectlyConfirm:
    'I hereby confirm that a company pension is to be applied for on my behalf via my employer. I acknowledge that I will receive my insurance certificate and the associated documents no later than the start of the insurance.',
  setupBavDirectlyRadio: 'A company pension is to be established for me directly',
  setupBavWithOfferRadio: 'I would like to request a non-binding offer',
  healthInsuranceProvider: 'Health insurance provider',
  switchLanguageToolTip: 'Switch language',
  profileToolTip: 'Profile',
  companyDocumentsPageNavTitle: 'Company documents',
  sharedDocumentsPageNavTitle: 'Shared documents',
  employeePageNavTitle: 'Employees',
  // Admin Calculations
  deleteCalculationsConfirmation: 'You are about to remove {count} calculations. Do you want to proceed?',
  // Admin - employees
  formLabelShareWith: 'Share with',
  labelSearchEmployee: 'Search employee',
  keyAccountYourClientCompaniesEmployeeDocuments: 'assigned to your customer companies employee documents',
  adminEmployeesPageTitle: '⁣Degura Pension Cloud - Administration - Employee',
  adminCompaniesPageTitle: '⁣Degura Pension Cloud - Administration - Company documents',
  tableHeaderLastName: 'Last name',
  tableHeaderFirstName: 'First name',
  tableHeaderCompany: 'Company',
  tableHeaderOrganization: 'Organization',
  tableHeaderSalutation: 'Title',
  tableHeaderStatus: 'Status',
  uploadDocument: 'Upload document',
  deleteEmployeeConfirmation: 'You are about to remove {count} employee. Do you want to proceed?',
  deleteCompanyConfirmation: 'You are about to remove the company "{name}". Do you want to proceed?',
  successExcelImportHeadline:
    '{count} new employees were imported from the list. They are now included in the table below.',
  // Profile
  CONTACT_METHOD: 'Preferred contact method',
  PHONE: 'Phone',
  EMAIL: 'Email',
  PHONE_EMAIL: 'Any',
  labelEmployeeStartDate: 'Employment start date',
  // Bav info
  bavInfoNextToCalculations: 'Continue to sample calculations',
  bavInfoNextToProviders: 'Continue to insurance providers',
  bavInfoBackToCalculations: 'Back to sample calculations',
  bavInfoBackToGeneral: 'Back to general information',
  bavInfoNextToCalculationsMobile: 'calculations',
  bavInfoNextToProvidersMobile: 'Providers',
  bavInfoBackToGeneralMobile: 'General',
  bavInfoBackToCalculationsMobile: 'Calculations',
  // My BAV
  myBavCheckDataConfirm: 'Confirmation',
  myDocumentStatusInfoTitle: 'Document status',
  myDocumentStatusInfo1: 'Pending - document is still being scanned',
  myDocumentStatusInfo2: 'OK - document was successfully uploaded',
  myDocumentStatusInfo3: 'Failed - document could not be uploaded',

  myBavNetContributionLabel: 'Net company pension contribution in EUR',
  myBavNetContributionDescription:
    'Below you can enter the net contribution you would like to pay monthly into your company pension.',
  myBavBeneficiaryDescription:
    'Who would you like to designate as the beneficiary to receive the agreed benefit in case of death of the insured person. You have full flexibility and control and you can change it at any time according to your wishes.',
  stepperButtonContinue1: 'Continue to financial data',
  stepperButtonContinue2: 'Continue to company pension data',
  stepperButtonContinue3: 'Continue to data check',
  stepperButtonContinue4: 'Request a non-binding offer',
  stepperButtonContinue4Direct: 'Set up company pension directly',
  stepperButtonBack2: 'Back to personal data',
  stepperButtonBack3: 'Back to financial data',
  stepperButtonBack4: 'Back to company pension data',
  myBavCreateRequest: 'Create new request',
  myBavRequestContractChange: 'Request contract change',
  myBavDetailsTitle: 'Contract details',
  myBavDocumentsDescription:
    'In this area, you will find all the important documents that have been uploaded by your personal advisor or HR department. For files you uploaded previously, it is marked, whether they have been shared with your personal advisor or HR department.',
  myBavStatus: 'Company pension status:',
  // Change BAV Status
  successChangeStatus: 'The status was changed successfully',
  myBavSearchNFilter: 'Search & filter',
  myBavSearchLabel: 'Search documents',
  myBavUploadDocumentText1: 'Please drag and drop or ',
  myBavUploadDocumentText2: ' your file (5 files max)',
  myBavRecycleBin: 'Documents in recycle bin',
  myBavActive: 'Active documents',
  myBavRecycleBinConfirmation: 'Are you sure about deleting the selected document permanently?',
  myBavRecycleBinConfirmationMultiple: 'Are you sure about deleting all selected documents permanently?',
  myBavPermanentDeletionConfirmation: 'Are you sure about deleting the selected document permanently?',
  myBavPermanentDeletionConfirmationMultiple: 'Are you sure about deleting all selected documents permanently?',
  myBavUploadModalTitle: 'Upload document',
  myBavUploadModalDocLabel: 'Document',
  myBavUploadModalCategoryLabel: 'Category*',
  myBavUploadModalShare: 'Share with HR',
  myBavUploadModalShareInfo:
    'All uploaded documents were automatically shared with your personal consultant at Degura GmbH. Would you also like to share the document(s) with your responsible HR department? If so, please activate it here.',
  myBavEditModalTitle: 'Edit document',
  myBavEditModalSliderLabel: 'HR department',
  myBavNoResults: 'Unfortunately there are no results',
  myBavStatusFilter: 'Filter by status',
  myBavCategoryFilter: 'Filter by category',
  myBavActions: 'Actions',
  myBavDeleteSelected: 'Delete selected',
  myBavPersonalAdvisor: 'Personal advisor',
  myBavPersonalAdvisorF: 'Personal advisor',
  myBavDocumentsPerPage: 'Documents per page: ',
  myBavKeyAccManagerF: 'Key Account manager',
  myBavKeyAccManager: 'Key Account manager',

  // Common
  browse: 'Browse',
  remove: 'Remove',
  verify: 'Verify',
  edit: 'Edit',
  delete: 'Delete',
  download: 'Download',
  upload: 'Upload',
  selected: 'Selected',
  overview: 'Overview',
  yes: 'Yes',
  no: 'No',
  itemsPerPage: 'Items per page: ',

  doYouWishToContinue: 'Would you like to continue?',
  doWishToContinueWithoutSaving: 'You have unsaved changes. Do you want to proceed without saving?',
  twoFactorMethodInUseIntro: 'Each time you log in, you will get a one-time code afterwards on this phone number.',
  // Settings
  settingsChangePasswordTitle: 'In this area, you can change your current password.',
  // Change Contract Status
  settingsChangeContractStatus: 'In this area, you can change your contract status.',
  // 2fa
  twoFactorSuccessHeadline: 'Congratulations!',
  twoFactorSuccessMsg: 'You have set up your two-step-authentification successfully.',
  twoFactorProblem: 'Are you having trouble setting up the 2-step-login?',
  twoFactorMobileNumberLabel: 'Mobile number',
  numberVerify: 'Verify number',
  codeVerify: 'Verify code',
  // Validation
  requiredFieldError: 'This field is required',
  requiredFileError: 'Please select a file',
  emailIsNotValid: 'Please add a valid email address.',

  // Welcome Login
  welcomeTitle: '',
  // Forgot Password
  forgotPwTitle: '⁣Degura Pension Cloud - Forgot password',
  forgotPwWelcome: 'Reset your password',
  forgotPwWelcomeMsg: 'Please enter your email address, so that we can send you a link to reset your password.',
  forgotPwEmail: 'Email / Username',
  forgotPwReset: 'Reset',
  forgotPwCancel: 'Cancel',
  forgotPwProblem: 'Having trouble logging in?',
  forgotPwSupportLink: 'Contact support',
  forgotPwConfirm: 'Password reset email sent!',
  forgotPwConfirmMsg: 'We have sent you an email with a link to reset your password.',
  forgotPwBack: 'Back to login page',
  // Login
  loginTitle: '⁣Degura Pension Cloud - Login',
  loginWelcome: 'Welcome to your Degura Pension Cloud',
  loginWelcomeToken: 'Please fill in your login data that you have received with your Degura Pension Cloud documents.',
  loginWelcomeMsg: 'Please enter your email address or username and password.',
  loginEmail: 'Email / Username',
  loginPassword: 'Password',
  loginForgetPw: 'Forgot your password?',
  loginBtnText: 'Log in',
  loginProblem: 'Having trouble logging in?',
  loginSupportLink: 'Contact support',
  // Footer
  footerCopyright: 'Copyright 2023 - Degura GmbH ',
  footerLink1: 'Imprint',
  footerLink2: 'Data privacy',
  // Outdated
  messageStopImpersonation: 'Please click here to stop switching roles.',
  messageImpersonation: 'Right now, you have the role of',
  pageTitle2faSettings: '⁣Degura Pension Cloud - ⁣2FA-settings',
  pageTitle2faSetup: '⁣Degura Pension Cloud - ⁣2FA-setup',
  pageTitle2faVerification: '⁣Degura Pension Cloud - 2FA-verification',
  pageTitleAcceptPrivacyPolicy: '⁣Degura Pension Cloud - Accept privacy policy',
  pageTitleAddEmployee: '⁣Degura Pension Cloud - Add employee',
  pageTitleChangePassword: '⁣Degura Pension Cloud - Change password',
  pageTitleConsultation: 'Degura Pension Cloud - Consultation',
  pageTitleEmployeeOverview: '⁣Degura Pension Cloud - Overview',
  pageTitleFaq: '⁣Degura Pension Cloud - FAQ',
  pageTitleForgotPassword: '⁣Degura Pension Cloud - Forgot password',
  pageTitleHomeEmployee: '⁣Degura Pension Cloud - Home',
  pageTitleImprint: '⁣Degura Pension Cloud - Imprint',
  pageTitleLogin: '⁣Degura Pension Cloud - Login',
  pageTitleMyPension: '⁣Degura Pension Cloud - My company pension',
  pageTitlePlayground: '⁣Degura Pension Cloud - Playground',
  pageTitlePrivacyPolicy: '⁣Degura Pension Cloud - Privacy policy',
  pageTitleSettings: '⁣Degura Pension Cloud - Settings',
  pageTitleRegistrationSuccessful: '⁣Degura Pension Cloud - Successful registration',
  pageTitleConsultationDecline: '⁣Degura Pension Cloud - Consultation',
  pageTitleConsultationOffer: '⁣Degura Pension Cloud - Consultation',
  pageTitleConsultationTotalContributionsGraphic: '⁣Degura Pension Cloud - Consultation',
  pageTitleConsultationValueDevelopmentGraphic: 'Degura Pension Cloud - Consultation',
  pageTitleCompanyDocuments: '⁣Degura Pension Cloud - Company documents',
  pageTitleEmployeeDocuments: '⁣Degura Pension Cloud - Employee documents',
  pageTitleMyDocuments: 'Degura Pension Cloud - My documents',
  pageTitleError404: '⁣Degura Pension Cloud - 404',
  pageTitleMyProfile: '⁣Degura Pension Cloud - My profile',
  pageTitleUserProfile: '⁣Degura Pension Cloud - User profile',
  pageTitleWelcome: 'Degura Pension Cloud - Welcome',
  pageTitleIndex: 'Degura Pension Cloud - Home',
  menuConsultation: 'Consultation',
  menuEvents: 'Events',
  menuMyPension: 'My company pension',
  menuDocuments: 'Documents',
  menuFAQ: 'FAQ',
  linkImprint: 'Imprint',
  linkPrivacy: 'Data privacy',
  privacyPolicyLabel: 'Privacy policy',
  passwordNoticeLabel: 'Here',
  siteTitle: 'Degura Pension Cloud',
  linkHeaderOwnProfile: 'Personal profile',
  linkHeaderSettings: 'Settings',
  linkHeaderSwitchRole: 'Switch role',
  linkHeaderSwitchRoleToEmployee: 'Switch to employee profile',
  linkHeaderSwitchRoleToHR: 'Switch to HR profile',
  linkHeaderRoleHR: 'HR Manager',
  linkHeaderRoleEmployee: 'Employee',
  linkHeaderRoleAM: 'Account manager',
  linkHeaderSwitchLanguage: 'Switch to german',
  linkHeaderLogout: 'Logout',
  linkForgotPassword: 'Forgot password?',
  linkGoBack: 'Back',
  linkHere: 'here',
  checkboxStayLoggedIn: 'Stay logged in',
  checkboxAcceptPrivacyPolicy: 'I hereby accept the {0}.',
  checkboxCheckAllLabel: 'Select all',
  checkboxDecline:
    'After consideration, I have decided that I do not wish to take advantage of a company pension via my employer at this time. I am aware that I can revoke my decision at any time, and that I can also submit the company pension to my employer at a later date.',
  checkboxAccept:
    'I hereby confirm that I wish to apply for a company pension through my employer. I have taken note that my insurance certificate and relevant documents will be sent to me no later than the commencement of insurance.',
  checkboxShareWithHR: 'Share documents with HR',
  toggleTextPasswordShow: 'Show password',
  toggleTextPasswordHide: 'Hide password',
  toggleSortDocumentUp: 'Sort by documents in ascending order',
  toggleSortDocumentDown: 'Sort by documents in descending order',
  toggleSortCategoryUp: 'Sort by categories in ascending order',
  toggleSortCategoryDown: 'Sort by categories in descending order',
  toggleSortDateTimeUp: 'Sort by date in ascending order',
  toggleSortDateTimeDown: 'Sort by date in descending order',
  altTextLogo: 'Degura logo',
  altTextLogoClient: '{COMPANY} logo',
  altTextGradientGraphic: 'Blue background',
  altTextIconConsultantPhone: 'Phone number of consultant',
  altTextCheckSingleDoc: 'Select file',
  altTextDOCXIcon: 'DOCX file',
  altTextDOCIcon: 'DOC file',
  altTextXLSXIcon: 'XLSX file',
  altTextXLSIcon: 'XLS file',
  altTextPNGIcon: 'PNG file',
  altTextJPGIcon: 'JPG file',
  altTextPDFIcon: 'PDF file',
  altTextUploadedPDFIcon: 'Uploaded PDF file',
  altTextInProgressPDFIcon: 'File is uploading',
  altTextFailedUploadAlertIcon: 'Failed upload',
  altTextCompanyPensionIcon: 'Selected company pension contribution',
  altTextReferencePersonIcon: 'Beneficiary',
  altTextDownloadIcon: 'Download of',
  altTextErrorMessageIcon: 'Error message',
  altTextCloseIcon: 'Close window',
  formLabelOptional: '(optional)',
  formLabelVideoCall: 'Request video consultation',
  formLabelStartChat: 'Start chat',
  formLabelProfileSettings: 'Profile and settings',
  formLabelEmail: 'Email',
  formLabelEmailLogin: 'Email or username',
  formPlaceholderEmail: 'Enter email address',
  formContactPlaceholderEmail: 'e.g.: john.doe@gmail.com',
  formLabelPassword: 'Password',
  formPlaceholderPassword: 'Enter password',
  formLabelNewPassword: 'New password',
  formPlaceholderNewPassword: 'Enter new password',
  formLabelRepeatPassword: 'Repeat password',
  formPlaceholderRepeatPassword: 'Repeat password',
  formLabelReset: 'Reset',
  formLabelGoBack: 'Back',
  formLabelEditIcon: 'Edit field',
  formLabelLastName: 'Last name',
  formLabelFirstName: 'First name',
  formLabelCompany: 'Company',
  formLabelSalutation: 'Title',
  formLabelSalutationFemale: 'Mrs.',
  formLabelSalutationMale: 'Mr.',
  formLabelSalutationDiverse: 'Diverse',
  formLabelPostalCode: 'Postal code',
  formLabelCity: 'Residence',
  formLabelStreetAddress: 'Street & Nr.',
  formLabelCountry: 'Country',
  formPlaceholderCountry: 'Select country',
  formLabelTaxResidence: 'Tax domicile',
  formPlaceholderTaxResidence: 'Select country',
  formLabelDateOfBirth: 'Date of birth',
  formPlaceholderDateOfBirth: 'DD.MM.YYYY',
  formLabelPlaceOfBirth: 'Place of birth',
  formLabelCountryOfBirth: 'Country of birth',
  formPlaceholderCountryOfBirth: 'Select country',
  formLabelEmailPersonal: 'Private email',
  formLabelEmailBusiness: 'Business email',
  formLabelPhone: 'Phone number',
  formLabelPersonalNumber: 'Personal number',
  formLabelNationality: 'Nationality',
  formPlaceholderNationality: 'Select country',
  formLabelProfession: 'Profession',
  formLabelTaxClass: 'Tax class',
  formPlaceholderTaxClass: 'Select tax class',
  formLabelFactor: 'Factor',
  formLabelTaxClass1: 'I',
  formLabelTaxClass2: 'II',
  formLabelTaxClass3: 'III',
  formLabelTaxClass4: 'IV',
  formLabelTaxClass5: 'V',
  formLabelTaxClass6: 'VI',
  formLabelChildTaxAllowance: 'Child tax allowance',
  formPlaceholderChildTaxAllowance: 'Please select',
  formLabelChurchTax: 'Church tax',
  formPlaceholderChurchTax: 'Please select',
  formLabelYes: 'Yes',
  formLabelNo: 'No',
  formLabelNetContribution: 'Net contribution to company pension',
  formLabelEmployersContribution: 'Employer subsidy',
  formLabelHealthInsurance: 'Health insurance',
  formPlaceholderHealthInsurance: 'Please select',
  formLabelHealthInsuranceStatutory: 'Statutory',
  formLabelHealthInsurancePrivate: 'Private',
  formLabelContribution: 'Contribution rate',
  formLabelContributionPerc: 'Contribution rate (in %)',
  formPlaceholderContribution: 'e.g. 15.7',
  formLabelTotalCont: 'Total contribution PHI',
  formPlaceholderTotalCont: 'e.g. 600.00',
  formLabelCareInsurance: 'Total contribution care insurance',
  formPlaceholderCareInsurance: 'e.g. 250.00',
  formLabelTaxLevy: 'Income tax levy',
  formPlaceholderTaxLevy: 'e.g. 900.00',
  formLabelGrossWage: 'Gross wage, € (monthly)',
  formPlaceholderGrossWage: 'e.g. 1600.00',
  formLabelOldPassword: 'Old password',
  formLabelRepeatPasswordProfile: 'Repeat',
  formLabelNotificAdress: 'Changes of address',
  formLabelNotificContract: 'Contract adjustments',
  formLabelNotificCompanyLeave: 'Employee resignation',
  formLabelNotificStatus: 'Changes of status',
  formLabelResults: 'Results',
  formLabelButtonResults: 'Adapt displayed results',
  formPlaceholderSearch: 'Search / filter for',
  formPlaceholderDocuments: 'e.g. Sampledocument.pdf',
  formLabelSubmitSearch: 'Start search',
  formLabelCancelEdit: 'Cancel',
  formLabelSaveEdit: 'Save',
  formLabelStatusStandard: 'Default',
  formLabelStatusParentalLeave: 'Parental leave',
  formLabelStatusSickLeave: 'Sick leave',
  formLabelStatusResigned: 'Terminated',
  formLabelShareDocument: 'Share document',
  formLabelDeleteDocuments: 'Delete multiple documents',
  formLabelDownloadDocuments: 'Download multiple documents',
  formLabelDocsPaginationLeft: 'Show previous documents',
  formLabelDocsPaginationRight: 'Show next documents',
  formLabelCheckAllDocs: 'Select all documents',
  formLabelDeleteDocument: 'Delete file',
  formLabelDownloadDocument: 'Download file',
  formLabelRemoveFile: 'Remove file',
  formLabelEditCategory: 'Edit category',
  formLabelPhoneOrMobile: 'Phone/mobile number',
  formLabelPensionSlider: 'Your contribution',
  formLabelReferencePerson: 'Beneficiary',
  formPlaceholderReferencePerson: 'Spouse',
  formLabelEditReferencePerson: 'Edit beneficiary',
  formLabelDeleteSearch: 'Clear input',
  formLabelSetContribution: 'Your contribution',
  formLabelNewContribution: 'New contribution',
  formLabelExpand: 'Expand section',
  formLabelCollapse: 'Collapse section',
  formLabelLanguageGerman: 'German',
  formLabelLanguageGermanFormal: 'German (formal)',
  formLabelLanguageGermanInformal: 'German (informal)',
  formLabelLanguageEnglish: 'English',
  formLabelExistingContract: 'Existing company pension plan',
  formLabelLanguagePreference: 'Language preference',
  labelGoBack: 'Back',
  paginationLabelTill: 'to',
  formPlaceholderSelect: 'Select...',
  buttonLogin: 'Login',
  buttonConfirm: 'Confirm',
  buttonReset: 'Reset',
  buttonPlusIconFAQ: 'Open answer box',
  buttonMinusIconFAQ: 'Close answer box',
  buttonVideoCall: 'Video consultation',
  buttonStartChat: 'Start chat',
  buttonSavePassword: 'Save password',
  buttonCategoryFilter: 'Filter for category',
  buttonSelectFile: 'Choose file',
  buttonSelectCategory: 'Set document category',
  buttonEdittCategory: 'Edit document category',
  buttonCancel: 'Cancel',
  buttonUpload: 'Upload',
  buttonConclude: 'Complete',
  buttonAcceptOffer: 'Take out CP',
  buttonRequestOffer: 'Request offer',
  buttonShowContractDetails: 'Show my contract details',
  buttonPrivateChat: 'Private chat',
  buttonDeclineOffer: 'Submit offer waiver',
  buttonUploadOldContract: 'Deposit company pension',
  buttonPersonalProfile: 'Go to personal profile',
  buttonProductDataSheetDownload: 'Download product data sheet',
  buttonProductFactSheetDownload: 'Download fact sheet',
  buttonBack: 'Back',
  buttonContinue: 'Continue',
  buttonSend: 'Send',
  buttonMoveCompanyPension: 'Move company pension',
  buttonAcceptAndContinue: 'Accept & continue',
  buttonGoToLogin: 'Go to login page',
  buttonFinish: 'Close',
  buttonExampleDownload: 'Sample calculation',
  buttonCompanyInfoDownload: 'Company info',
  buttonContinueToPortal: 'Continue to Degura Pension Cloud',
  buttonProceed: 'Proceed',
  buttonEditData: 'Edit data',
  buttonCancelChanges: 'Cancel changes',
  buttonSaveData: 'Save data',
  buttonSaveCookie: 'Save cookies',
  buttonAdd: 'Add',
  buttonAddEmployee: 'Add employee',
  tableHeaderDocuments: 'Documents',
  tableHeaderCategory: 'Category',
  tableHeaderActions: 'Actions',
  tableHeaderSecurityScanStatus: 'Scan',
  tableHeaderCompanies: 'Company',
  tableHeaderTimeDate: 'Date / Time',
  tableHeaderSharedWith: 'Shared with',
  tooltipUserSystemId: 'This is a personal identification number assigned by the system.',
  tooltipNotificEmail: 'Here you can adjust the email address to which your notifications will be sent.',
  tooltipDeleteMultipleDocuments: 'Please select documents, before being able to deleting them.',
  tooltipDownloadMultipleDocuments: 'Please select documents, before being able to downloading them.',
  tooltipDocsPaginationLeft: 'You are on the first page of the document list.',
  tooltipDocsPaginationRight: 'There are no further documents.',
  tooltipVideocallButton: 'Arrange a video appointment',
  tooltipChatButton: 'Chat with consultant',
  tooltipShareFileButton: 'Share file with employee',
  tooltipEditEmployeeButton: 'Edit employee',
  copyright: `© ${new Date().getFullYear()} Degura GmbH. All rights reserved.`,
  userGreeting: 'Hello, {USER_FIRSTNAME}!',
  personalConsultant: '{CONSULTANT_FIRSTNAME}',
  consultantDescriptionMale: 'Degura Account manager',
  consultantDescriptionFemale: 'Degura Account manager',
  formTitleLogin: 'Login',
  exampleCalcsTaxLabel: 'Taxes',
  exampleCalcsSocialLabel: 'Social security',
  exampleCalcsGrossWageLabel: 'Gross salary',
  exampleCalcsNetWageLabel: 'Net salary',
  exampleCalcsTotalContrLabel: 'Total contribution',
  exampleCalcsMonthlyLabel: 'mthly.',
  exampleCalcsTaxSavingsLabel1: 'Taxes & social security',
  exampleCalcsTaxSavingsLabel2: 'savings',
  exampleCalcsEmployerSubsidyLabel: "Employer's subsidy",
  exampleCalcsOwnContributionLabel: 'Own contribution',
  exampleCalcsWithBAVLabel: 'with company pension',
  exampleCalcsWithoutBAVLabel: 'without company pension',
  exampleCalcsCapitalPaymentHeadline1: 'Projected capital payment',
  exampleCalcsCapitalPaymentHeadline2: 'depending on the value development',
  exampleCalcsGuaranteedPaymentLabel: 'Guaranteed',
  exampleCalcsPredictedPaymentLabel: 'Projected',
  aboutBavSocialSavingsLabel: 'Social savings',
  aboutBavGraphicsRetirementLabel1: 'so far',
  aboutBavGraphicsRetirementLabel2: 'Retirement provision',
  faqQuestion1: 'Is the company pension scheme worthwhile for every employee?',
  faqAnswer1:
    '<p>With a company pension plan, you build up an additional retirement provision thanks to subsidies from your employer and the state. This is worthwhile for you as an employee because it increases your financial leeway in retirement. At the end of the term, you also have the option of deciding - a lifelong pension, no matter how old you get, a one-time lump-sum payment at the start of retirement, or a mix of both.</p>',
  faqQuestion2: 'How much may my monthly contribution be?',
  faqAnswer2:
    '<p>You decide that yourself. The state subsidizes a contribution of up to 604.00 EUR gross incl. employer subsidies per month. You can pay up to 302.00 EUR into your contract without deducting taxes and social security contributions. An additional 302.00 EUR are also tax-free.</p>',
  faqQuestion3: 'What monthly contribution is recommended?',
  faqAnswer3:
    '<p>You decide how much you want to set aside. A good guideline for your own contribution is about 5 to 8 percent of your net income. This contribution is topped up by the state and your employer. Already with an own contribution of 50.00 EUR you can generate a contribution of 120.00 EUR, while with an own contribution of 100.00 EUR a contribution of 240.00 EUR is possible.<br><br>The general rule is: better more at the beginning than too little at the end. If you set the contribution a little higher at the beginning or in the middle of your working life, you can reduce it at any time. But if you set it too low right from the start, it may be too late at the end to adjust it so that your pension is sufficient for you in old age.</p>',
  faqQuestion4: 'Can I adjust my contributions if my financial situation changes?',
  faqAnswer4:
    '<p>You decide how much you pay into your company pension. You can increase, reduce or pause and reactivate the selected contribution amount.</p>',
  faqAnswer5:
    '<p>You can pause your contract at any time without giving any reason.<br>*** When you resume work after your parental leave, you can continue your contract as usual. If you then have a new employer, skip to the question, "What happens to my contract if my employer changes?"<br>*** You always have the option of continuing to pay into your company pension plan with your own funds. This can be especially interesting in the case of self-employment.<br>***"Hartz IV-proof": Your savings from the company pension plan (bAV) do not count as savings that you must use up before you are entitled to unemployment benefit II. In this case, your occupational pension capital would be safe from being offset by the state.</p>',
  faqQuestion6: 'Can I continue my contract privately abroad?',
  faqAnswer6:
    '<p>With some providers, it is also possible to continue a contract privately from abroad. In this case, it is best to contact your responsible advisor. </p>',
  faqQuestion7: 'What happens to my contract if my employer changes?',
  faqAnswer7:
    '<p>"Your contributions paid in up to now will not be lost if you change jobs.<br><br>There are basically three options here:<br><br>1. your contract is continued by your new employer.<br><br>2. your saved capital from the company pension scheme is paid into the pension scheme of the new employer.<br>3. you leave your existing contract inactive and receive a new one. Your entitlement to benefits from the previous company pension plan will, of course, remain unaffected.<br><br>Your new employer decides which of these options to choose. --> LINK (Offboarding Guideline) <--"	</p>',
  faqQuestion8: 'Is my company pension safe if the employer files for insolvency?',
  faqAnswer8:
    '<p>Your company pension is protected in the event of insolvency. Even if things go badly for your company and the company becomes insolvent, you will still receive your company pension as agreed.</p>',
  faqQuestion9: 'From when am I entitled to payment of my company pension?',
  faqAnswer9:
    '<p>You are entitled to benefits as soon as you draw your statutory old-age pension. The start of the period of entitlement to the statutory old-age pension depends on your statutory retirement age.</p>',
  faqQuestion10: 'How do I get my company pension paid out?',
  faqAnswer10:
    '<p>You decide.<br>You can have your saved capital paid out at the start of the pension either once or in the form of a lifelong monthly pension. <br>A mixed form is also possible, in which you take out 30% of the capital at the beginning of the pension and have the remaining savings paid out in the form of a monthly lifelong pension.',
  faqQuestion11: 'What happens in case of my death?',
  faqAnswer11:
    '<p>In the event of death before pension commencement, all contributions paid in to date go to the surviving dependents* previously specified in the contract.<br>In the event of death after pension commencement, the survivors* continue to receive a survivor’s pension determined by the pension guarantee period.</p>',
  faqQuestion12: 'Does the company pension scheme reduce my statutory pension?',
  faqAnswer12:
    '<p>Your entitlements to the statutory pension are minimized proportionally, as the contributions to the company pension are a so-called deferred compensation. However, this difference is offset many times over by the additional entitlements from your company pension plan.</p><p>This graphic illustrates this</p><img class="faq-graphic" src="{imgMorePensionEN}" alt="Impact of company pension on retirement pension">',
  faqQuestion13: 'Which payouts are possible?',
  faqAnswer13:
    '<p>The payout* of your company pension depends on the performance of your tariff. Information on company pension plans and sample calculations can be found under the menu item "About company pension".<br>In this chart you can see how the performance of your company pension with different value developments can affect your monthly pension and the capital.</p>',
  faqQuestion14: 'What effect does the company pension have on my tax return?',
  faqAnswer14:
    '<p>Your company pension has no effect on the annual wage tax equalization, which is why you do not have to declare it separately in your tax return. When preparing your tax return, you only need to check the box on the form - if you have any questions, please contact your tax advisor.</p>',
  faqQuestion15: 'Do I have to pay taxes and social security contributions on my lifetime pension?',
  faqAnswer15:
    '<p>Yes, the lifelong pension is subject to tax and long-term care insurance, which means that contributions are due. Contributions to health insurance, on the other hand, are only paid if your monthly company pension exceeds the annually increasing tax-free amount.</p><p>For most employees, the pension is lower than their salary during their working life. In this case, the tax savings during your working life are higher than at retirement age.</p>',
  faqQuestion16: 'Do I have to pay taxes on my capital payment?',
  faqAnswer16:
    '<p>When you receive a lump-sum payment of your company pension in Germany, you may be subject to various taxes. You have to pay income tax on the payout, which depends on your individual tax rate. Depending on your individual circumstances, you may also be required to pay health and long-term care insurance.</p>',
  faqQuestion17: 'What costs are incurred when taking out a direct insurance policy?',
  faqAnswer17:
    '<p>When you take out a company pension using the direct insurance method, you incur acquisition costs, sales costs and ongoing administrative costs. You can obtain more detailed information on this from your advisor and in the consumer information.</p>',
  notSpecified: 'Not specified',
  mandatoryFieldNote: '* Mandatory field',
  companyPensionStatusOnboarded: 'Onboarded',
  companyPensionStatusContacted: 'Contacted',
  companyPensionStatusOfferOptedOut: 'Declined',
  companyPensionStatusOfferRequested: 'Consultation',
  companyPensionStatusOfferAccepted: 'Closed',
  companyPensionStatusSigned: 'Contracted',
  companyPensionStatusOldContract: 'Existing contract',
  companyPensionStatusApplication: 'Application submitted',
  companyPensionStatusSuspended: 'Suspended',
  tabAll: 'All',
  tabDeleted: 'Deleted',
  exampleCalcCaseLabel1: 'Case 1:',
  exampleCalcCaseLabel2: 'Case 2:',
  exampleCalcCaseLabel3: 'Case 3:',
  exampleCalcGrossWageLabel: 'Gross wage:',
  exampleCalcContributionLabel: 'Net contribution:',
  exampleCalcDateOfBirthLabel: 'Date of birth:',
  exampleCalcChurchTaxLabel: 'Church tax:',
  exampleCalcChildTaxAllowanceLabel: 'Child tax allowance:',
  exampleCalcEmployersSubsidyLabel: 'Employer subsidy:',
  exampleCalcEmployersContributionLabel: 'Employer contribution: ',
  exampleCalcEmployersContributionLabel2: "Employer's contribution",
  exampleCalcTaxClassLabel: 'Tax class:',
  uploadInfo1: 'Drag a maximum of five files into this area to share them.',
  uploadInfo2: 'or',
  titleMaxUploadAmount: 'Too many files selected',
  messageMaxUploadAmount: 'Please select a maximum of five files at once!',
  progressOnboarding: 'Introduction',
  progressExamples: 'Sample calculations',
  progressCTA: 'Decision',
  progressAcceptData: 'Personal data',
  progressAcceptContact: 'Contact details',
  progressAcceptTax: 'Fiscal data',
  progressAcceptBAV: 'Company pension details',
  progressOnboarded: 'Onboarded',
  progressOfferRequested: 'Offer requested',
  progressOfferAccepted: 'Company pension set up',
  progressOfferDeclined: 'Waived',
  progressOldContract: 'Old contract deposited',
  paragraphFunctionality1:
    '{COMPANY} pays you a gross salary. Social insurance and taxes are then deducted from this gross salary. Only the net amount remains at your free disposal.',
  paragraphFunctionality2:
    'This net salary forms the financial basis for the entire livelihood. You pay your running costs for the family, for the house, the apartment or the car from it. Since the statutory pension insurance is no longer sufficient in old age, many people also use their net salary to make private provisions for their retirement. The company pension works differently – and is therefore becoming increasingly important.',
  paragraphFunctionality3:
    'The company pension enables you to use parts of your gross salary for your old-age provision that would otherwise go to the state. \n\nBecause {COMPANY} cares about your retirement provisions, you will receive an additional allowance from them. This way, you end up saving twice as much!',
  noticeConfirmCorrectness:
    'By clicking on “Continue” I confirm the correctness of my data and agree that I may be contacted by telephone for any further questions.\n\n Furthermore I confirm that important information as well as copies of personal forms may be sent to me via email.',
  noticeConfirmRenunciation: 'Your action: I waive my option for a company pension.',
  noticeConfirmAccept: 'Your choice: I want a company pension to be set up for me.',
  noticeConfirmRequest: 'Your choice: I would like to request an individual offer for a company pension.',
  noticeContributionExample:
    'This calculation is for illustrative purposes only, without taking into account your personal tax and social security contributions. We will send you a calculation tailored to your personal situation in the individual offer.',
  noticePensionCalculatorTaxData:
    'In order to achieve even more accurate results with the company pension calculator, we need some tax information from you. You can enter these here.',
  noticeSendLoginLink:
    'Request your personal login link here by email, to inform yourself about company pension in the Degura Pension Cloud.',
  noticeAlreadyCreatedPassword: 'You already created a password? {0} to get directly to the login page.',
  noticeSubsidyLimit:
    '{COMPANY} subsidises your bAV with {SUBSIDY} up to a salary level of the contribution assessment limit. If your salary is above this limit, the subsidy does not apply.',
  infoDecline: 'You will receive a confirmation of your waiver.',
  infoDeclineEmail: 'Provide an email address to receive a confirmation of your waiver in this form.',
  infoAccept: 'We will get in touch with you shortly regarding all next steps.',
  descriptionCTADeclineOffer:
    'I have already made other provisions for my retirement and do not wish to take advantage of the benefits a company pension offers.',
  pensionSliderWageLabel: 'Your gross salary (monthly)',
  fieldLabelEmployersContribution: 'Employer subsidy',
  fieldLabelTotalContribution: 'Total contribution',
  calculatorBoxNote: '* The calculated data are approximations, which may differ from the actual result.',
  notFoundDocuments: 'There are no documents containing the search criteria you entered.',
  searchFilterLabel: 'Filter',
  searchFilterAddedToday: 'Added today',
  searchFilterAddedLast7Days: 'Added in last 7 days',
  introFAQ: 'Your questions, our answers',
  introHomepageWelcome: 'Welcome to your Degura Pension Cloud',
  homepageSectionOneText: 'I would like to get information about my company’s company pension plan.',
  homepageSectionOneButton: 'Learn more',
  homepageSectionTwoText:
    'I would like to set up an appointment to speak to someone about questions that I have about my company’s company pension offer.',
  homepageSectionTwoButton: 'Video consultation',
  homepageSectionThreeText:
    'I would like to speak now with someone about questions that I have about my company’s company pension offer.',
  homepageSectionThreeButton: 'Start chat',
  homepageSectionFourText:
    "I have already made other provisions for my retirement and do not wish to take advantage of my company's company pension offer.",
  homepageSectionFourButton: 'Submit waiver',
  introEmployeeWelcome: 'Welcome to your {COMPANY} company pension portal',
  introDecline: 'Are you certain you do not want to take advantage of a company pension?',
  introAcceptOrRequest: 'Your first step towards a carefree retirement',
  introSubFAQ: 'In this section you find the most important questions and answers concerning the company pension.',
  subheadlineFAQ: 'Common',
  subHeadingFAQ: 'Most asked questions',
  introSubEmployeeWelcome:
    'We are happy you’re here. This section gives you an overview of the services and advantages of a company pension.',
  introSubDecline:
    'We are sorry to see you go. After completing your cancellation you will not receive any further emails from us. Just in case you change your mind, you can revoke your decision at any time.',
  introSubAcceptOrRequest:
    'It’s nice to see you having opted for a company pension. You’re almost there! Fill in the required information about yourself and let us know your desired monthly contribution. Your personal consultant will then contact you to sort out all further details with you.',
  introSubMyCP: 'In this section you can check your current company pension status.',
  headlineConsultantMale: 'Your consultant',
  headlineConsultantFemale: 'Your consultant',
  headlineContactMale: 'Your personal contact',
  headlineContactFemale: 'Your personal contact',
  headlineLogin: 'Welcome to your Degura Pension Cloud!',
  headlineChangePassword: 'Change your password',
  headlineResetPassword: 'Reset your password',
  headlineCheckInbox: 'Check your email inbox',
  headlineFAQ: 'Frequently asked questions',
  headlineEmployeeProfile: 'Your personal profile',
  headlineEmployeeSettings: 'Your personal settings',
  headlineContactFAQ: 'Still have questions?',
  subHeadlineFunctionality1: 'Familiar scenario without a company pension:',
  subHeadlineFunctionality2: 'Familiar scenario with company pension:',
  subHeadlineFunctionality3: 'Current scenario with company pension:',
  subheadlineContactFAQ: 'Your personal consultant will be happy to take the time to answer them for you.',
  headlineContact: 'Still have questions?',
  headlinePersonalData: 'Personal data',
  headlineTaxData: 'Tax data',
  headlineSecurity: 'Security settings',
  headlineNotifications: 'Email notifications',
  headlineEmployeeEdit: 'Profile of {NAME}',
  headlineEmployeeStatus: 'Employee status',
  headlineCompanyPensionStatus: 'Company pension status',
  headlineSharedDocuments: 'Shared documents',
  headlineUnsavedChanges: 'Unsaved changes',
  headlineCancelEdit: 'Cancel editing',
  headlineDocuments: 'Your documents',
  headlineUploadOverlay1: 'Define the document category',
  headlineFileUploadOverlay: 'Your files are being uploaded',
  headlineFinishUpload: 'Complete file upload',
  headlineCTAAcceptOffer: 'Ready for your retirement?',
  headlineCTARequestOffer: 'Do you need more information?',
  headlineCTAPrivateChat: 'Do you have an individual question?',
  headlineStatusProgressBar: 'Everything you need to know',
  headlineIntroVideo: 'What is a company pension?',
  headlineFunctionality: 'What kind of financial advantages does a company pension offer?',
  headlineExampleCalculations: 'How can the financial benefits of a company pension be calculated?',
  headlineExamplesSubsidies: 'Share of subsidies in the total contribution',
  headlineCapitalPayment: 'Capital growth during pay-in phase',
  headlineExamplesTotalContribution: 'With a company pension you profit twice over!',
  headlineProductDataSheetDownload: 'Your {COMPANY} product data sheets',
  headlineExampleCalculationsDownload: 'The sample calculation for you to download',
  headlineCompanyInfoDownload: 'Information on the {COMPANY} bAV',
  headlineInsuranceProductOverview: 'Overview of your insurance products',
  headlineConsultationCTA: 'Congratulations! You are now a company pension expert.',
  headlinePersonalDataInput: 'Please enter your data to confirm your action',
  headlineDecline: 'Waiver of a bAV',
  headlineAcceptOffer: 'Please fill in the following fields about your person',
  headlineContactDataInput: 'Please let us know how we can reach you',
  headlineCompanyPensionInput: 'Determine your individual monthly contribution',
  headlineCompanyPensionCalculator: 'Calculate your advantages',
  headlinePensionCalculator: 'Your potential contribution after employer subsidy',
  headlineCaseOfDeath: 'Which person should be entitled to benefit in case of death?',
  headlineTaxDataInput: 'To finalise your personal offer, we require your tax information',
  headlineCheckData: 'Personal information',
  headlineMonthlyContribution: 'Monthly contribution',
  headlineEvents: 'Stay up to date',
  headlineEmployeeDocuments: 'Personal documents',
  headlineEmployerDocuments: 'All documents at a glance',
  headlineCompanyPensionStatusBar: 'Your company pension application status',
  headlineMoveCompanyPension: 'Are you changing companies?',
  headlinePrivacyPolicy: 'Data privacy information for clients and prospects',
  headlineNewEvents: 'New events',
  headlineOldEvents: 'Previous events',
  headlineInsurer: 'Insurance company',
  headlineDeleteDocuments: 'Delete documents',
  headlineDeleteSelectedDocuments: 'Delete selected documents',
  headlineArchiveDeleteSelectedDocuments: 'Permanently delete selected documents',
  headlineAcceptPrivacyPolicy: 'Your privacy is important to us',
  headlineFileNotFound: 'The page you are looking for does not exist',
  headlineProductDataSheet: 'Product data sheet',
  headlineContractProposals: 'Contract proposals',
  headlineFundOverview: 'Fund overview',
  headlineConsumerInformation: 'Consumer information',
  headlineInsuranceConditions: 'Insurance conditions',
  headlineCookieConsentForFeature: 'Use of cookies',
  headlineRegistrationSuccess: 'Registration successful!',
  headlineCreateCompany: 'Create company',
  newOrg: 'Create new',
  existingOrg: 'Use existing',
  messageDeleteSelectedDocuments: 'You are about to delete {count} document(s). Are you sure?',
  messageArchiveDeleteSelectedDocuments: 'You are about to permanently delete {count} document(s). Are you sure?',
  messageEmployeeEdit:
    'In this section, you can edit tax and personal information, view the company pension status or share documents with the employee.',
  headlineDeleteSelectedEmployees: 'Delete employees',
  messageEmployeeDataChangedTitle: 'Updated!',
  messageEmployeeDataChanged: 'Your data has been updated successfully.',
  messageAcceptPrivacyPolicy:
    'In our {0} you can read how we secure and process your data. \n\n For us to be allowed to do so, we need your consent.',
  messageDeleteSelectedEmployees: 'You are about to delete {count} employees. Are you sure?',
  subheadingLogin: 'Please fill in your login data that you have received with your Degura Pension Cloud documents.',
  receivedEmailNoticeResetPassword:
    'If your email address is registered in our system, you have received an email with a link to reset your password.',
  emailMissingNoticeResetPassword: 'If you have not received an email, please contact your HR department.',
  subheadingResetPassword: 'Please enter your email address so that we can send you a link to reset your password.',
  messageFAQ:
    'The bAV is characterised by special features regarding taxes, state support as well as potential returns. How so? Below you will find a summary of the most important questions and answers regarding the company pension.',
  messageConsultationCTA:
    'Now it’s on you to decide!\n \n If there are still any questions left unanswered, you can clarify them in a private chat with your personal advisor.\n \n If you already made a decision, you can also opt for a company pension directly or ask for a non-binding, individually tailored offer. In both cases your advisor will contact you afterwards and clarify all further contractual contents with you personally.\n \nWhat would you like to decide on?',
  messageContactFAQ: 'Our consultants will gladly take the time to answer them for you.',
  messageContractualDocuments:
    'In this area you will find all the documents relevant to your company, such as the pension plan and group contracts for your employees.',
  messageContact: 'Our consultants would be happy to answer them.',
  subHeaderOwnProfile:
    'This section is an overview of your personal and tax data. You can make any adjustments here. Please note that we may have to make changes in your contractual documents if you have already concluded a company pension with us.',
  messageEmployeeSettings: 'This area allows managing settings for your user account.',
  headlineDeleteEmployee: 'Delete employee',
  messageUnsavedChanges:
    'You are about to leave the site without saving your changes. If you proceed, the following changes will be lost:',
  messageCancelEdit: 'You are about to cancel the editing. If you continue, the following changes will be lost:',
  messageStatusProgressBar:
    'Get an overview of how a company pension works in three simple steps. Afterwards you decide!',
  messageCTAAcceptOffer:
    'Take advantage of the financial benefits a company pension offers and start making provisions for the future today.',
  messageeIntroVideo:
    'In the following video, you learn more about the services of a company pension and how you can use them for yourself.',
  messageExampleCalculations:
    'In the following examples, you can see what the advantages of a company pension are based on different gross wages and contributions. Just choose the example that comes closest to your situation.\n \nIn order to receive an offer that is completely tailored to you and your situation, you are welcome to request an individual and non-binding offer later on, free of charge.',
  messageTotalContributionWithoutEmployerContribution:
    'As you can see, {NAME} invests {PERSONAL_CONTRIBUTION} of their net salary in the company pension. This results - due to lower gross wages - in a total of {SOCIAL_CONTRIBUTION} per month in tax and social security savings. This amount is paid directly into the company pension, as does the employer’s subsidy of {EMPLOYER_SUBSIDY_CURRENCY}. Instead of only {PERSONAL_CONTRIBUTION}, {NAME} ultimately saves {TOTAL_CONTRIBUTION} each month.',
  messageTotalContributionWithEmployerContribution:
    'As you can see, {NAME} invests {PERSONAL_CONTRIBUTION} of their net salary in the company pension. This results - due to lower gross wages - in a total of {SOCIAL_CONTRIBUTION} per month in tax and social security savings. This amount is paid directly into the company pension, as does the employer’s subsidy of {EMPLOYER_SUBSIDY_CURRENCY} and the allowance of {EMPLOYER_CONTRIBUTION_LABEL}. Instead of only {PERSONAL_CONTRIBUTION}, {NAME} ultimately saves {TOTAL_CONTRIBUTION} each month.',
  messageExampleSubsidies:
    'We assume that {NAME} will pay {PERSONAL_CONTRIBUTION} per month into her company pension for {CONTRACT_DURATION} years. The total amount of their own contribution after {CONTRACT_DURATION} years will be {TOTAL_PAID_OWN}.\n \n The resulting guaranteed capital payment after this period will be {GUARANTEED_CAPITAL_PAYMENT}.\n \n This means that {TOTAL_PAID_OTHER}, which {NAME} will receive after retirement, will consist exclusively of subsidies.',
  messageExampleValueDevelopment:
    'In this example, it is furthermore assumed that {NAME}’s company company pension generates an annual return of {ANNUAL_RETURN} over the same period ({CONTRACT_DURATION} years).\n\nThis increaes the total capital payment to {CAPITAL_PAYMENT_PROJECTED}.',
  messageTaxDataInput: 'You will find all necessary data on your salary statement.',
  messageCompanyPensionInput:
    'Here you can let us know how much of your net salary you would like to invest in your company pension. You can also change this amount again later on.',
  messageCaseOfDeath:
    'If you do not want to specify a person at this time, choose the option "No selection". You can change this setting at any time.',
  messageCheckData: 'Please check your entered data. If necessary, please correct them here.',
  messageProductDataSheetDownload:
    "Download your product data sheet here to find out more about your employer's company pension product.",
  messageExampleCalculationsDownload:
    'Here you can download the PDFs of the sample calculation. There you will find all the information presented above in more detail, using payroll accounting as an example, as well as evaluations of capital payments and subsidies.',
  messageCompanyInfoDownload:
    'Download more information on how exactly your company supports you with a company pension here.',
  messageInsuranceProductOverview:
    'Below is a list of all the products that your employer makes available to you under the company pension.\n\nYou are free to choose which product you would like to invest in. In the PDFs that we have made available for download below, you will find all the important information you need to make your decision.',
  messageEmployeeEvents: 'This area shows an overview of all current processes concerning your company pension.',
  messageEmployerEvents: 'This area shows an overview of all current processes within your company.',
  messageEmployeeDocuments:
    'This section lists your most important personal documents such as policies and quotation invoices at a glance.',
  messageEmployerDocuments:
    'In this section, you can find an overview of all documents that you are sharing with {COMPANY} employees.',
  messageCompanyPensionCalculator:
    'Use the pension company calculator to check which allowances you can expect for different monthly contributions. This might help you to decide!',
  messageUploadOldContract: 'You already have a company pension? Simply deposit your contract here.',
  messageMoveCompanyPension:
    'Your contributions to the company pension will not be lost if you change jobs. Tell us about your new employer and talk to him or her about the form in which the company pension should be continued.',
  messageDeleteDocuments:
    'You are about to delete documents. With this action you move the selected documents irrevocably into the "Deleted" folder. Do you want to continue?',
  messageFileNotFound:
    '<p>Unfortunately we could not find the page you are looking for. Check the URL to see if a spelling mistake has crept in.</p><p>It is also possible that this page has been moved or no longer exists. If this problem persists, please contact our Degura Support.</p>',
  messageShareWithHR:
    'Your documents are automatically shared with your personal consultant. If you also want to share your documents with your HR department, please tick the checkbox below.',
  messageCookieConsentForFeature:
    'In order to be able to use our functions such as chat and scheduling appointments, it is necessary that you agree to the use of cookies. By clicking on [Confirm] you can give us your consent for this.',
  mobileFormLabelBurgerMenuIcon: 'Main navigation',
  logoDescriptionCooperation: 'In cooperation with',
  successDocumentsDeletedHeadline: 'Document deleted!',
  successDocumentsDeleted: 'The selected document has been deleted.',
  successDocumentsUploaded: 'Your documents have been successfully uploaded.',
  successChangeFieldHeadline: 'Saved!',
  successChangeFieldChangeContract:
    'The field was updated successfully. Your personal consultant will arrange for a correction to be made in the contract documents within the next days.',
  successChangePassword: 'The password was changed successfully.',
  successHeadline: 'Success!',
  successDeclineOfferMessage: 'Your waiver for a company pension was successfully submitted.',
  successAcceptOfferMessage:
    'Your contract request has been successfully submitted. Your personal consultant will contact you again in the upcoming days.',
  successRequestOfferMessage:
    'Your request for an offer has been successfully submitted. Your personal consultant will contact you again in the upcoming days.',
  successGrantChangeMessage: 'The visibility settings for the document were successfully updated.',
  errorLoginCredentials: 'The username or password you entered is not correct. Please try again.',
  errorLoginChangePassword: 'The passwords you entered do not match. Please repeat your entry.',
  errorExcelImportHeadline:
    'The following rows in the Excel file were erroneous. Please correct them and repeat the import for these records.',
  errorExcelImportInLines: 'in rows:',
  errorExcelImportCompanyUnknown: 'Company unknown',
  errorExcelImportInvalidEmail: 'Invalid email address',
  errorExcelImportInvalidSalutation: 'Invalid title',
  errorExcelImportDuplicate: 'Records already found in system',
  errorExcelImportWrongFormat: 'The file you uploaded is not an Excel file.',
  errorExcelImportWrongFileStructure: 'The file you uploaded does not match the template provided.',
  errorExcelImportAccessDenied: 'You are not allowed to manage employees for the respective company',
  errorExcelImportInvalidStreetAddress: 'Invalid street',
  errorExcelImportInvalidPostalCode: 'Invalid postal code',
  errorExcelImportInvalidLanguagePreference: 'Invalid language preference',
  errorExcelImportInvalidCountry: 'Unknown country defined',
  errorPercentageInvalid: 'The value must be between 0 and 100',
  errorChangeFieldHeadline: 'Ooops!',
  errorChangeField: 'The field could not be updated. Should the error occur again, please contact the Degura Support.',
  errorChangePassword:
    'Your password could not be changed. Please try again. If this should turn out to be a recurring problem, please contact the Degura Support.',
  errorPasswordForgottenInvalidEmail:
    'Looks like there is no email address linked to your account, please contact the Degura Support.',
  errorInvalidEmail: 'Please enter a valid email address, \n e.g.: john.doe@gmail.com',
  errorInvalidPhone: 'Number invalid. Please check it again.',
  errorPhoneLength: 'Phone/mobile number must have at least 10 digits.',
  errorGrantChangeMessage:
    'Unfortunately, the visibility settings for the document could not be updated. If this problem persists, please contact the Degura Support.',
  errorSendLoginLink:
    'Unfortunately we could not find a valid email address to which we can send your login link. Please enter your email address here:',
  errorInvalidPhoneMax: 'Phone/mobile number should have a maximum of 20 digits.',
  changePasswordTokenExpiredMessage: 'The token to change your password has expired.',
  redirectMessageSuccessChangePassword:
    'Password was changed successfully! You will be redirect to the login page in {0} seconds.',

  BENEFICIARY_LEGAL_SUCCESSION: 'Legal succession',
  BENEFICIARY_PARTNER: 'Partner in joint household management',
  BENEFICIARY_DEATH_GRANT: 'Beneficiary of the death grant',
  BENEFICIARY_NONE: 'No selection',

  DOC_CUSTOMER_INFO: 'Customer Information',
  DOC_REQUEST: 'Request',
  DOC_CONVERSION_AGREEMENT: 'Conv. Agr. (EUV)',
  DOC_CONSULTATION: 'Consultation Protocol',
  DOC_CONSUMER_INFO: 'Consumer information',
  DOC_POLICY: 'Policy',
  DOC_TERMS: 'Terms',
  DOC_FORM: 'Form',
  DOC_BALANCE_REPORT: 'Balance report',
  DOC_INCIDENT: 'Incident',
  DOC_PRODUCT_INFO: 'Information',
  DOC_OTHER: 'Other',
  DOC_OFFER: 'Offer',
  DOC_CHECKLIST: 'Checklist',
  DOC_CLIENT_INFO: 'General information',
  DOC_PROCESSING_AGREEMENT: 'Processing agreement',
  DOC_MANDATE: 'Mandate',
  DOC_LIST_REPORT: 'List report',
  DOC_COLLECTIVE_AGREEMENT: 'Collective agreement',
  DOC_PENSION_SCHEME: 'Pension scheme',
  DOC_REPORTING: 'Reporting',
  DOC_WAIVER: 'Waiver',
  DOC_SECURITY_SCAN_PENDING: 'Pending',
  DOC_SECURITY_SCAN_CLEAN: 'OK',
  DOC_SECURITY_SCAN_INFECTED: 'Infected',
  successTitle: 'Changed!',
  placeholderSearchEmployee: 'e.g.: Frank Foster',
  uploadInProgress: 'Upload in progress',
  uploadFailed: 'Upload failed',
  completeProcess: 'Complete',
  downloadExampleInEnglish: 'You can download a generic example calculation in English language by clicking this link:',
  exampleFileLinkLabel: 'Download',
  errorPasswordTooShort:
    'Password should have a minimum of 8 characters and contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.',
  // error password hint box text
  errorPasswordTitle: 'Password requirements',
  errorPasswordCharacters: '8 characters',
  errorPasswordUpperCase: '1 uppercase',
  errorPasswordLowerCase: '1 lowercase',
  errorPasswordNumber: '1 number',
  errorPasswordSpecialCharacter: '1 special character',
  // ... //
  errorPasswordRequired: 'Please enter a password',
  errorPasswordRepeatRequired: 'Please repeat the password',
  errorPasswordMatch: "The passwords don't match",
  error404Title: 'Ooops - something is missing!',
  error404Message: 'Our system could not find what it’s looking for. Please contact our support.',
  errorGenericTitle: 'Error',
  errorGenericMessage: 'Unfortunately, there was a processing error. Please contact our support.',
  errorSessionExpiredTitle: 'Session expired',
  errorSessionExpiredMessage: 'Your session has expired. Please log in again.',
  errorNotAuthorizedTitle: 'Access has been denied',
  errorNotAuthorizedMessage: 'Unfortunately, you do not appear to have the right privileges for this action.',
  errorTooYoung: 'You need to be at least 18 years old.',
  uploadCancelled: 'Upload cancelled',
  messageEmployeeImported: 'The employee has been created in the system and is now listed in the employee list.',
  errorEmployeeDuplicateEmail: 'An employee already exists with this email address',
  chartLabelContributionOwn: 'Own contribution',
  chartLabelContributionOther: 'Subsidies',
  successExistingContractPostedHeadline: 'Contract successfully posted!',
  successExistingContractPosted: 'Your existing company pension policy has been added to your profile.',
  errorExistingContract:
    'Something went wrong, unfortunately. Please try again or contact your personal Degura consultant.',
  errorChangePasswordNoEmail:
    'Unfortunately, there is no email address linked to your account. Please contact you Degura consultant.',
  toggleLableShareWithHR: 'HR department',
  headlineShareWithHR: 'Share documents with HR',
  messageToggleShareWithHR:
    'You are about to share the following document with your HR department. Would you like to continue?',
  errorEmployeeInvalidSalutation: 'Invalid salutation',
  successDocumentCategoryChangeMessage: 'The document category has been updated.',
  titleSidebarButtonExpand: 'Expand',
  titleSidebarButtonCollapse: 'Collapse',
  auth2faRemove: 'Remove two-factor-authentication',
  aut2faSetupDoNotShow: 'Do not show again',
  aut2faSetupHeading: 'Two-Factor-Authentication',
  aut2faSetupIntro:
    'To help secure your account please set up your 2-step-login. Each time you log in, you will get a one-time code afterwards on your mobile phone.',
  auth2faSetupInstructions1: 'Please enter below the SMS code that you received on your mobile phone',
  auth2faSetupInstructions2: 'You have',
  auth2faSetupInstructions3: 'seconds left to put it in.',
  auth2faPhoneLabel: 'Please enter your mobile number',
  auth2faLabelCode: 'Confirmation code',
  auth2faCodeRequired: 'Please enter the confirmation code',
  auth2faSendCode: 'Send code again',
  auth2faSetupExpired1: 'Your time to enter the SMS code has expired. Please check if your mobile number',
  auth2faSetupExpired2: 'is correct and click below to request a new code.',
  auth2faSetupSuccess: 'Congratulations! You have successfully set up your 2-step-login.',
  auth2faSetupPlaceholder: 'Mobile number',
  auth2faConfirmCodePlaceholder: 'Code',
  auth2faLoginIntro1: 'Please enter below the SMS code that you received on your mobile phone',
  auth2faLoginIntro2: 'You have',
  auth2faLoginIntro3: 'seconds left to put it in.',
  auth2faLoginExpired1: 'Your time to enter the SMS code has expired. Please check if your mobile number',
  auth2faLoginExpired2: 'is correct and click below to request a new code.',
  auth2faSettingsHeading: 'Two-Factor-Authentication settings',
  auth2faOldNumber: 'Old mobile number',
  auth2faNewNumber: 'New mobile number',
  auth2faCongratulations: 'Congratulations!',
  auth2faCongratulationsMsg: '2FA was set up successfully.',
  auth2faSettingsMenuButton: '2FA settings',
  auth2faBackToLogin: 'Back to login',
  auth2faBackToNumber: 'Back to number input',
  auth2faToPortal: 'To the portal',
  auth2faLater: 'Set up later',
  linkLater: 'Later',
  contractChangeRequest: 'Contract change request',
  contractChangeRequestIntroHr:
    "What change would you like to make to the employee's current company pension contract?",
  contractChangeRequestIntro: 'What change would you like to make to your current company pension contract?',
  contractChangeRequestLead: 'Please fill in the following fields so that we can process your request.',
  contractChangeRequestSend: 'Send change',
  contractChangeRequestConfirmHeadline: 'We have received your request!',
  contractChangeRequestConfirmMsg:
    'We will take care of your request immediately. You can view the status of your request on your company pension page at any time.',
  contractChangeRequestOkay: 'Okay',
  changeRequest: 'Change requests',
  changeRequestSelectPlaceholder: 'Please select…',
  requestsTitle: 'Requests',
  requestsDescription:
    'Below are all of your change requests. If you have any questions, comments, etc. about your requests, then please contact us through the chat button bottom right on the page.',
  requestFieldType: 'Request type',
  requestFieldCurrent: 'Current value',
  requestFieldInitial: 'Initial value',
  requestFieldReporter: 'Reporter',
  requestFieldSelect: 'Please select...',
  requestFieldNew: 'New value',
  requestsTableColumnType: 'Type',
  requestsTableColumnTime: 'Date',
  requestsTableColumnStatus: 'Status',
  submit: 'Submit',

  personalProfileDescription:
    'This area allows the editing of your profile information that will be used for your company pension. Please note that once you have made your choice and would like to make changes to the choice, edit the details, etc., then you will need to complete this through the "My company pension" page.',
  personalProfileTitle: 'Personal profile',
  mobileNumberAlreadyInUse: 'Mobile number is already in use.',

  errorBreachedPassword: 'Password is a known breached one, please input a new one.',
  passwordNeedsToBeChanged:
    'Your password does not meet the password requirements so you will need to change your password before you can log in.',
  bAVOptionsQuestion: 'What would you like to do next?',
  bavOption1: 'Get more info about the company pension',
  bavOption2: 'Book a personal consultation',
  bavOption3: 'Continue with non-binding offer',
  bavOption3Onboarded: 'Display my current contract details',
  bavOption4: 'View my company pension contract details',
  waiverText: 'You like to waive your company pension benefit?',
  waiverLink1: 'Have exisitng company pension contract',
  waiverLink2: 'Submit waiver',
  myProfile: 'My profile',
  bookNow: 'Book now',
  moreInformation: 'More information',
  changePassword: 'Change Password',
  changePassWordText: 'In this area you can change your current password.',
  menuBavInfo: 'About company pension',
  menuHome: 'Home',
  menuMyBav: 'My company pension',
  menuFaq: ' FAQ',
  headingAboutBav: 'Everything you need to know',
  homeTitle: '⁣Degura Pension Cloud - Home',
  faqTitle: 'Degura Pension Cloud - FAQ',
  myBavTitle: '⁣Degura Pension Cloud - My company pension',
  profileTitle: '⁣Degura Pension Cloud - Personal Profile',
  bavInfoTitle: '⁣Degura Pension Cloud - About company pension',
  bavInfoHeading1: 'Everything you should know',
  bavInfoHeading2: 'Which kind of financial benefits does a company pension offer?',
  bavInfoIntroText: 'Get a simple overview of how a company pension scheme works in three steps.',
  bavTab1Text: 'General information on company pension',
  bavTab2Text: 'Calculation examples',
  bavTab3Text: 'My insurance providers',
  requestOfferTileHeading: 'Would you like to receive a non-binding offer?',
  requestOfferTileText:
    'How large your company pension will be depends on how long and how much you contribute. We would be happy to share with you how you can top up your statutory pension through a non-binding offer.',
  requestOfferTileButton: 'Request a quote',
  advantagesTileHeading: 'Would you like to be shown the advantages of a company pension in detail?',
  advantagesTileText:
    'There are many ways to improve your financial base for later. We would like explain the company pension and your options to you.',
  advantagesTileButton: 'Arrange a consultation',
  personalProfileInfoText:
    'Changing data on this page does not change your company pension contract. If you want to adjust your company pension contract, please do so through the <a><strong>My company pension</strong></a> page.',
  personalProfileInfoTextHRAdmin:
    'Changing data on this page does not entail any contract changes. If you wish to modify the contract, please do so under <strong><a>Change Requests.</a></strong>',
  personalProfileHeadingPersonal: 'Personal data',
  personalProfileHeadingFinancial: ' Financial data',
  financialInformationNet: 'Company pension contribution (net)',
  financialInformationBeneficiary: 'Beneficiary',
  bavStatus: 'Company pension status',
  bavTabContractDetails: 'Contract details',
  accountSetup: 'Account setup',
  salaryDetails: 'Salary Details',
  bavInformation: 'Company pension information',
  bavSetup: 'Company pension setup',
  summaryInformation: 'Ensuring correctness',
  checkData: 'Checking the data',
  enterInformation: 'Please enter your personal info for a non-binding offer.',
  myContractData: 'My contract data',
  myChangeRequests: 'My change requests',
  largeTilesIntroDeclined: 'You are interested in a company pension after all?',
  largeTilesIntroOld: 'Not satisfied with your current company pension?',
  enterInformation2:
    'In order to be able to create a non-binding offer for you, we need some tax information. Of course, we treat your data very confidentially and comply with the current data protection laws.',
  enterInformation2Direct:
    'In order to be able to set up a company pension for you, we need some tax information. Of course, we treat your data very confidentially and comply with the current data protection laws.',
  enterInformation3:
    'Here you can indicate how much of your net salary you would like to invest in the company pension scheme. You can change this amount at any time.',
  enterInformation4: 'Please check the data you have entered and correct them if necessary.',
  myBavStepperConfirm: 'I confirm the accuracy of my data and agree to be contacted.',
  uploadBavMessage: 'Please upload your existing company pension here',
  uploadBavSelectFile: 'Please select a file',
  uploadBavConfirm:
    'Your current company pension has been successfully uploaded and will be stored in your documents under the “My company pension” page.',
  submitWaiverMessage: 'Are you sure you want to waive your company pension benefit?',
  submitWaiverConfirm:
    'Your waiver has been successfully submitted to us.<br/>Please note that you have the option to revoke your waiver decision at any time and participate in the company pension.',
  adminEmployeeHeadingOverview: 'Employee overview',
  adminEmployeeHeadingLead:
    'You can add new employees through an Excel file (you can download a template <a href="https://assets.degura.de/upload-template/Degura_Mitarbeiterliste_Vorlage.xlsx"> here</a>) or add them individually. <br>You can add new employees and remove old ones at any time.',
  adminAddEmployeeHeading: 'Create employee',
  adminAddEmployeeLead: 'Please fill in the fields for the new employee to add them to your list of employees.',
  adminEmployeeSearchLabel: 'Search ',
  adminCompanyFilterLabel: 'Filter by company',
  adminOrganizationFilterLabel: 'Filter by organization',
  adminCompanyFilterStatusLabel: 'Filter by status',
  adminSuccessDeactivateCompany: 'The company is deleted successfully',
  adminCompanyDocumentHeading: 'Company document overview',
  adminCompanyDocumentsLead:
    'In this area you will find all the documents relevant to your company, such as the pension plan and group contracts for your employees.',
  adminEmployeeUpload: 'Upload employee list',
  adminEmployeeAdd: 'Add employee',
  adminEmployeeTablePerPage: 'Employees per page:',
  profileDeleteUser: 'Delete <b>{USER}</b> from the portal?',
  cookieConsentMessage:
    'By clicking “Accept All Cookies”, you consent to the storage of cookies on your device to improve website navigation, analyze website usage, and support our marketing efforts.',
  cookieConsentHeadline: '',
  cookieConsentDescription:
    'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.',
  cookieConsentAccept: 'Accept',
  cookieConsentManage: 'Manage cookies',
  cookieConsentAnalytics: 'Analytics cookies',
  cookieConsentAnalyticsDesc:
    'Allows us to optimize our website by anonymously tracking user behavior. For more information on our type of tracking, please visit our privacy policy.',
  cookieConsentAcceptAll: 'Accept all cookies',
  cookieConsentUse: 'Our use of cookies',
  cookieConsentManagement: 'Cookie Management',
  cookieConsentStrict: 'Strictly necessary cookies',
  cookieConsentNecessary:
    'Required to save your settings and control the core functions of our platform, such as login, language settings, and chat functions to contact our customer consultants.',
  uploadDocumentIntro1: 'Please drag and drop or  ',
  uploadDocumentIntro2: 'your files (5 files max).',
  uploadDocumentFileTypes: 'PDF, PNG, JPEG, XLSX and XLS are supported up to a size of 10 MB.',
  uploadDocumentUploading: 'Documents are uploading',
  uploadDocumentUploadingCount: 'document(s) uploaded',
  uploadDocumentCancel: 'Cancel upload',
  uploadDocumentCanceledMsg:
    'The document upload was cancelled! In the table, you can check which documents have already been uploaded until the cancellation. If desired, these can be deleted manually.',
  uploadDocumentSuccessMsg: 'The documents have been uploaded successfully!',
  uploadDocumentFailedMsg: 'Upload of document(s) failed!',
  uploadDocumentFailedAlert: 'could not be uploaded successfully due to ',
  uploadDocumentFailedAlert2: '!',
  uploadDocumentTryAgain: 'Try Again',
  uploadDocumentSkip: 'Skip',
  uploadDocumentSupport1: 'Please try again. If the problem persists, please contact your Degura Support. ',
  uploadDocumentSupport2: 'support',
  uploadDocumentNow: 'Just now',

  // Error codes
  CARE_INSURANCE_CONTRIBUTION_INVALID: 'Care insurance contribution is invalid.',
  CHILD_ALLOWANCE_INVALID: 'Child allowance is invalid.',
  CHURCH_TAX_INVALID: 'Curch tax is invalid.',
  CITY_INVALID: 'City is invalid.',
  CITY_OF_BIRTH_INVALID: 'City of birth is invalid.',
  COMPANY_IDENTIFIER_INVALID: 'Company ID is invalid.',
  COMPANY_NAME_INVALID: 'Company name is invalid.',
  COMPANY_NOT_DELETABLE: 'Company could not be deleted.',
  COMPANY_NOT_FOUND: 'Company could not be found.',
  COMPANY_ONBOARDING_ERROR: 'Company onboarding not successful. Company with this ID already exists.',
  COMPANY_PLAN_NOT_FOUND: 'Company plan could not be found. ',
  CONFIGURATION_INVALID: 'Configuration is invalid.',
  CONFIGURATION_NOT_FOUND: 'Configuration could not be found.',
  CONTACT_METHOD_INVALID: 'Contact method is invalid.',
  CONTRACT_STATUS_INVALID: 'Contract status is invalid.',
  COUNTRY_CODE_INVALID: 'Country code is invalid.',
  COUNTRY_INVALID: 'Country is invalid.',
  COUNTRY_OF_BIRTH_INVALID: 'Country of birth is invalid.',
  DATE_OF_BIRTH_INVALID: 'Date of birth is invalid.',
  DOCUMENT_TYPE_INVALID: 'Document type is invalid.',
  EMAIL_BUSINESS_INVALID: 'Business email is invalid.',
  EMAIL_INVALID: 'Email is invalid.',
  EMAIL_PERSONAL_INVALID: 'Personal email is invalid.',
  EMPLOYEE_CITY_INVALID: 'City is invalid.',
  EMPLOYEE_COUNTRY_INVALID: 'Country is invalid.',
  EMPLOYEE_CREATION_DUPLICATE: 'Employee already exists.',
  EMPLOYEE_DATE_OF_BIRTH_INVALID: 'Date of birth is invalid.',
  EMPLOYEE_IDENTIFIER_INVALID: 'Employee ID is invalid.',
  EMPLOYEE_NOT_FOUND: 'Employee could not be found.',
  EMPLOYEE_PHONE_NUMBER_INVALID: 'Phone number is invalid.',
  EMPLOYEE_POSTAL_CODE_INVALID: 'Postal code is invalid.',
  EMPLOYEE_STATUS_INVALID: 'Employee status is invalid.',
  EMPLOYMENT_START_DATE_INVALID: 'Employment start date is invalid.',
  ERROR_EXCEL_IMPORT_ACCESS_DENIED: 'File could not be imported due to missing access rights.',
  ERROR_EXCEL_IMPORT_COMPANY_UNKNOWN: 'File could not be imported due to an unknown company.',
  ERROR_EXCEL_IMPORT_INVALID_COUNTRY: 'File could not be imported due to an invalid city.',
  ERROR_EXCEL_IMPORT_INVALID_EMAIL: 'File could not be imported due to an invalid email address.',
  ERROR_EXCEL_IMPORT_INVALID_LANGUAGE_PREFERENCE: 'File could not be imported due to an invalid language preference.',
  ERROR_EXCEL_IMPORT_INVALID_POSTAL_CODE: 'File could not be imported due to an invalid postal code.',
  ERROR_EXCEL_IMPORT_INVALID_SALUTATION: 'File could not be imported due to an invalid salutation.',
  ERROR_EXCEL_IMPORT_INVALID_STREET_ADDRESS: 'File could not be imported due to an invalid street address.',
  ERROR_EXCEL_IMPORT_WRONG_FORMAT: 'File could not be imported due to an invalid format.',
  EXAMPLE_CALCULATION_NOT_FOUND: 'Example calculations could not be found.',
  GROSS_INCOME_INVALID: 'Gross income is invalid.',
  HEALTH_INSURANCE_CONTRIBUTION_RATE_INVALID: 'Health insurance contribution rate is invalid.',
  HEALTH_INSURANCE_PRIVATE_CONTRIBUTION_INVALID: 'Private health insurance contribution is invalid.',
  HEALTH_INSURANCE_TYPE_INVALID: 'Type of health insurance is invalid.',
  INCOME_TAX_CONTRIBUTION_INVALID: 'Income tax contribution is invalid.',
  LANGUAGE_CODE_INVALID: 'Language code is invalid.',
  LANGUAGE_FORM_INVALID: 'Language form is invalid.',
  LANGUAGE_PREFERENCE_INVALID: 'Language preference is invalid.',
  NATIONALITY_INVALID: 'Nationality is not available.',
  OCCUPATION_INVALID: 'Occupation is invalid.',
  ORGANIZATION_IDENTIFIER_INVALID: 'Organization ID is invalid.',
  ORGANIZATION_NAME_INVALID: 'Organization name is invalid.',
  ORGANIZATION_NOT_FOUND: 'Organization could not be found.',
  PERSONAL_NUMBER_INVALID: 'Personal number is invalid.',
  PLAN_DELETE_ERROR: 'Plan could not be deleted because it is assigned to a company.',
  PLAN_NOT_FOUND: 'Plan could not be found.',
  POSTAL_CODE_INVALID: 'Postal code is invalid.',
  PROVIDER_DELETE_ERROR: 'Provider could not be deleted due to having plans.',
  PROVIDER_NOT_FOUND: 'Provider could not be found. Probably wrong ID.',
  SALUTATION_INVALID: 'Salutation is invalid',
  STREET_ADDRESS_INVALID: 'Street address is invalid.',
  STREET_NUMBER_INVALID: 'Street number is invalid.',
  TAX_CLASS_FACTOR_INVALID: 'Factor of tax class is invalid.',
  TAX_CLASS_INVALID: 'Tax class is invalid.',
  TAX_RESIDENCE_INVALID: 'Tax residence is invalid.',
  CONTRACT_NOT_FOUND: 'Contract could not be found.',
  BENEFICIARY_TYPE_INVALID: 'Type of beneficiary is invalid.',
  CONTRACT_STATUS_TRANSITION_INVALID: 'This status change is invalid.',
  EMPLOYEE_CONTRIBUTION_INVALID: 'Employee contribution is invalid.',
  EMPLOYER_CONTRIBUTION_INVALID: 'Employer contribution is invalid.',
  MESSAGING_IDENTIFIER_INVALID: 'Messaging ID is invalid.',
  TOTAL_CONTRIBUTION_INVALID: 'Total contribution is invalid.',
  CONSULTANT_IDENTIFIER_INVALID: 'Consultant ID is invalid.',
  CONSULTANT_IDENTIFIER_IDENTIFER: 'Consultant ID is invalid.',
  COMPANY_IDENTIFIER_IDENTIFER: 'Company ID is invalid.',
  CONSULTANT_APPOINTMENT_SCHEDULING_LINK_INVALID: 'Link for the counselling appointment is invalid.',
  CONSULTANT_DELETE_ERROR: 'Consultant could not be deleted.',
  CONSULTANT_NOT_FOUND: 'Consultant could not be found.',
  DOCUMENT_FILE_NAME_INVALID: 'Document file name is invalid.',
  DOCUMENT_CONTENT_TYPE_INVALID: 'Document content is invalid.',
  DOCUMENT_SIZE_INVALID: 'Document size is invalid',
  DOCUMENT_CREATED_DATE_INVALID: 'Document creation date is invalid.',
  DOCUMENT_NOT_FOUND: 'Document could not be found.',
  COMPANY_DOCUMENT_MODIFY_NOT_SUPPORTED: 'Changing company documents is not allowed.',
  IDENTIFIER_INVALID: 'ID is invalid.',
  DOCUMENT_CATEGORY_IDENTIFIER_INVALID: 'ID of the document category is invalid.',

  successContractRequestNotification: 'We have received your request and will contact you soon.',
  successOfferRequestNotification: 'We have received your request and will contact you soon.',

  onSmartphone: 'On a smartphone?',
};
