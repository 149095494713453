import { getAssignedCustomerSuccessManager, getAssignedEmployeeConsultant } from '@/api/consultant';
import { ROLE_EMPLOYEE, ROLE_BAV_MANAGER, ROLE_ORG_ADMIN, ROLE_KEY_ACCOUNT } from '@/constants';

export const GET_CONSULTANT = 'GET_CONSULTANT';
export default {
  [GET_CONSULTANT]({ commit, dispatch, rootState }) {
    const grabEmployeeConsultant = async () => {
      commit('SET_LOADING', true);

      getAssignedEmployeeConsultant(rootState.auth.userData.id)
        .then((response) => {
          response.data.headlineTranslationKey = 'myBavPersonalAdvisor';
          commit('SET_CONSULTANT', response.data);
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            commit('SET_CONSULTANT', null);
          }
        })
        .finally(() => commit('SET_LOADING', false));
    };
    const grabCustomerSuccessManager = async () => {
      commit('SET_LOADING', true);

      getAssignedCustomerSuccessManager(rootState.auth.userData.data.companyId)
        .then((response) => {
          response.data.headlineTranslationKey = 'myBavPersonalAdvisor';
          commit('SET_CONSULTANT', response.data);
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            commit('SET_CONSULTANT', null);
          }
        })
        .finally(() => commit('SET_LOADING', false));
    };

    return new Promise(async (resolve, reject) => {
      switch (rootState.auth.activeRole) {
        // Get customer success manager for following roles@
        case ROLE_EMPLOYEE:
          await grabEmployeeConsultant();
          resolve();
          break;
        case ROLE_BAV_MANAGER:
        case ROLE_ORG_ADMIN:
          await grabCustomerSuccessManager();
          resolve();
          break;

        // if user is KEY_ACCOUNT
        // set consultant null
        case ROLE_KEY_ACCOUNT:
          commit('SET_CONSULTANT', null);
          resolve();
          break;
      }
    });
  },
};
