import * as auth from './auth';
import * as notifications from './notifications';
import documents from './documents';
import employees from './employees';
import cookies from './cookies';
import consultant from './consultant';
import bookConsultation from './book-consultation';
import upload from './upload';

export default {
  auth,
  notifications,
  documents,
  employees,
  cookies,
  consultant,
  bookConsultation,
  upload,
};
