import axios from '@/plugins/axios';

// Auths
export const login = (loginId, password) => {
  return axios.post('/authentication/login', { loginId, password });
};
export const twoFactorLogin = (twoFactorId, code) => {
  return axios.post('/authentication/2fa/login', { twoFactorId, code }); // DONE
};
export const getMe = () => {
  return axios.get(`/authentication/user/me`);
};

export const getUser = (userId) => {
  return axios.get(`/authentication/user/${userId}`);
};

// Two factor
export const generate2faSecret = () => {
  return axios.get('/authentication/2fa/secret');
};
export const sendAuthCode = (twoFactorId, methodId) => {
  return axios.post(`/authentication/2fa/send`, { twoFactorId, methodId });
};
export const confirm2fa = (code, mobilePhone, secretBase32Encoded, method = 'sms') => {
  return axios.post(`/authentication/2fa/confirm`, {
    code,
    mobilePhone,
    secretBase32Encoded,
    method,
  });
};
export const send2faConfirmCode = (mobilePhone, method = 'sms') => {
  return axios.post('/authentication/2fa/send-confirm-code', {
    mobilePhone,
    method,
  });
};
export const getRecoveryCodes = () => {
  return axios.get(`/authentication/2fa/recovery-codes`);
};
export const remove2faMethod = (userId, code, methodId) => {
  return axios.delete(`/authentication/2fa?userId=${userId}&code=${code}&methodId=${methodId}`);
};

// User
export const forgotPassword = (loginId) => {
  return axios.post('/authentication/user/forgot-password', { loginId });
};
export const changePassword = (currentPassword, password) => {
  return axios.post('/authentication/user/change-password', {
    currentPassword,
    password,
  });
};
export const changePasswordRequired = (password, changePasswordId) => {
  return axios.post(`/authentication/user/change-password-required`, {
    changePasswordId,
    password,
  });
};
export const updateUserRoles = (userId, roles) => {
  return axios.patch(`/authentication/user/${userId}/roles`, {
    roles,
  });
};
export const updateAssignedCompanies = (userId, companies) => {
  return axios.patch(`/authentication/user/${userId}/assigned-companies`, {
    companyIds: companies,
  });
};
export const fetchHRManagerByCompany = (companyId) => {
  return axios.get(`/authentication/company/${companyId}/hr-manager`);
};
export const remove2faForAllByCompany = (companyId) => {
  return axios.delete(`/authentication/2fa/${companyId}`);
};
export const changePreferredLang = (userId, locale) => {
  return axios.patch(`/authentication/user/${userId}/language`, {
    locale,
  });
};
