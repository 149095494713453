import { mdiHelpCircle } from '@mdi/js';

export default [
  {
    title: 'menuFAQ',
    icon: mdiHelpCircle,
    to: 'faq',
    action: 'read',
  },
];
