<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-badge bottom color="accent" overlap offset-x="12" offset-y="12" dot bordered>
            <v-avatar
              color="#039181"
              class="cursor-pointer"
              :class="{ 'ml-5': !$vuetify.breakpoint.smAndDown }"
              size="40"
              dot
              v-on="{ ...onMenu, ...(!$vuetify.breakpoint.smAndDown && { ...onTooltip }) }"
            >
              <span class="text-md" style="color: #06fccd">{{ initials }}</span>
            </v-avatar>
          </v-badge>
        </template>

        <span>{{ $t('profileToolTip') }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="accent" overlap offset-x="12" offset-y="12" class="ms-4" dot bordered>
          <v-avatar size="40" color="#F3F3F3">
            <span class="text-md" style="font-size: 0.875rem; color: #898989">{{ initials }}</span>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <v-tooltip bottom open-delay="500">
            <template #activator="{ on, attrs }">
              <span
                class="text--primary font-weight-semibold mb-n1"
                style="
                  max-width: 140px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  cursor: pointer;
                "
                v-bind="attrs"
                v-on="on"
              >
                {{
                  typeof userData.firstName !== 'undefined'
                    ? userData.firstName + ' ' + userData.lastName
                    : userData.email
                }}
              </span>
            </template>
            <span> {{ userData.email }}</span>
          </v-tooltip>

          <small class="text--disabled text-capitalize">{{
            activeRole === 'ROLE_EMPLOYEE'
              ? $t('linkHeaderRoleEmployee')
              : activeRole === 'ROLE_BAV_MANAGER'
              ? $t('linkHeaderRoleHR')
              : activeRole === 'ROLE_KEY_ACCOUNT'
              ? $t('linkHeaderRoleAM')
              : activeRole === 'ROLE_ADMIN'
              ? 'Global Administrator'
              : ''
          }}</small>
        </div>
      </div>

      <v-divider v-if="activeRole !== 'ROLE_KEY_ACCOUNT' && activeRole !== 'ROLE_ADMIN'"></v-divider>

      <!-- Profile -->
      <v-list-item
        v-if="activeRole !== 'ROLE_KEY_ACCOUNT' && activeRole !== 'ROLE_ADMIN'"
        :to="{ path: '/profile/' + userData.id }"
        class="mt-2"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccount }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('linkHeaderOwnProfile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="activeRole !== 'ROLE_ADMIN'" class="my-2"></v-divider>

      <v-list-item v-if="userCanSwitchRole && $vuetify.breakpoint.smAndDown" class="mt-2" @click="switchRole">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountSwitch }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ isAdmin ? $t('linkHeaderSwitchRoleToEmployee') : $t('linkHeaderSwitchRoleToHR') }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="userCanSwitchRole && $vuetify.breakpoint.smAndDown" class="my-2"></v-divider>

      <!-- Settings -->
      <v-list-item v-if="activeRole !== 'ROLE_ADMIN'" :to="{ name: 'settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCog }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t('linkHeaderSettings') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('linkHeaderLogout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccount,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCog,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiAccountSwitch,
} from '@mdi/js';
import { useRouter } from '@core/utils';
import { getCurrentInstance, ref, onMounted, computed } from '@vue/composition-api';
import { ROLE_EMPLOYEE, ROLE_ADMIN, ROLE_BAV_MANAGER, HR_ROLES } from '../../../constants';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const { router } = useRouter();
    const { userData } = vm.$store.getters;
    const activeRole = computed(() => vm.$store.getters.activeRole);
    const initials = ref('');
    const isAdmin = computed(() => HR_ROLES.includes(vm.$store.getters.activeRole));
    const isGlobalAdmin = computed(() => vm.$store.getters.activeRole === ROLE_ADMIN);
    const userCanSwitchRole = computed(() => vm.$store.getters.canSwitchRole);

    const getInitials = (str) => {
      const names = str.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    };

    const logoutUser = () => {
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken');
      vm.$store.commit('documents/__RESET');
      vm.$store.commit('resetAuth');
      // Reset ability
      // vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' });
    };

    const switchRole = () => {
      window.open('https://pensioncloud.degura.de');
    };

    onMounted(() => {
      if (userData.firstName) {
        initials.value = getInitials(`${userData.firstName} ${userData.lastName}`);
      } else {
        initials.value = getInitials(userData.username || userData.email);
      }
    });

    return {
      logoutUser,
      userData,
      activeRole,
      initials,
      isAdmin,
      isGlobalAdmin,
      switchRole,
      userCanSwitchRole,

      icons: {
        mdiAccount,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCog,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiAccountSwitch,
      },
    };
  },
};
</script>

<style lang="scss">
.user-profile-menu-content {
  hr {
    transform: none !important;
  }
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
