const PIWIK_DIMENSION = {
  PIWIK_ROLE: 1,
  PIWIK_COMPANY_ID: 2,
};

export const SET_PIWIK_ROLE_DIMENSION = (role) => {
  if (!window._paq) return false;
  window._paq.push(['setCustomDimension', PIWIK_DIMENSION.PIWIK_ROLE, role]);
};

export const SET_PIWIK_COMPANY_ID_DIMENSION = (companyId) => {
  if (!window._paq) return false;
  window._paq.push(['setCustomDimension', PIWIK_DIMENSION.PIWIK_COMPANY_ID, companyId]);
};

export const SET_PIWIK_USER_ID = (userId) => {
  if (!window._paq) return false;
  window._paq.push(['setUserId', userId]);
};
