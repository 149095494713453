import regularGerman from './de';

export default {
  ...regularGerman,
  notFoundPageDescriptionNotLogged:
    'Vielleicht verwenden Sie gerade einen Link, der nicht mehr verfügbar ist. Bitte loggen Sie sich ein und aktualisieren Ihre Lesezeichen (optional).',
  notFoundPageDescriptionLogged:
    'Vielleicht verwenden Sie gerade einen Link, der nicht mehr verfügbar ist. Bitte loggen Sie sich ein und aktualisieren Ihre Lesezeichen (optional).',

  welcomeChangePwTitle: 'Herzlichen Willkommen in Ihrem bAV-Portal',
  welcomeChangePwDescription:
    'Bitte wählen Sie ein sicheres Passwort. Das Passwort sollte mindestens 8 Zeichen, davon 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen enthalten. Im nächsten Schritt können Sie sich mit dem Ihrer E-Mail und Ihrem Passwort einloggen.',
  enterInformation4: 'Bitte überprüfen Sie Ihre eingegeben Daten und korrigieren Sie diese gegebenenfalls.',
  enterInformation3:
    'Hier können Sie angeben, wie viel Ihres Nettolohns Sie in die bAV investieren möchten. Diesen Betrag können Sie jederzeit verändern.',
  enterInformation2:
    'Um ein unverbindliches Angebot für Sie zu erstellen, benötigen wir noch einige steuerliche Angaben. Natürlich gehen wir sehr vertraulich mit Ihren Daten um und halten die aktuellen Datenschutzgesetze ein.',
  enterInformation2Direct:
    'Um direkt eine bAV für Sie einrichten zu können, benötigen wir noch einige steuerliche Angaben. Natürlich gehen wir sehr vertraulich mit Ihren Daten um und halten die aktuellen Datenschutzgesetze ein.',

  // Change requestFieldCurrent
  contractChangeRequestIntroHr: 'Welche Änderung möchten Sie an dem aktuellen bAV-Vertrag des Mitarbeiters vornehmen?',
  contractChangeRequestIntro: 'Welche Änderung möchten Sie an Ihrem aktuellen bAV-Vertrag vornehmen?',
  contractChangeRequestLead: 'Bitte füllen Sie die folgenden Felder aus, damit wir Ihr Anliegen bearbeiten können.',
  contractChangeRequestConfirmHeadline: 'Wir haben Ihre Anfrage erhalten!',
  contractChangeRequestConfirmMsg:
    'Wir kümmern uns umgehend um Ihr Anliegen. Sie haben jederzeit die Möglichkeit den Status Ihrer Anfrage auf Ihrer bAV-Seite einzusehen.',
  // my Bav
  myBavNetContributionDescription:
    'Bitte geben Sie nachfolgend den Nettobeitrag ein, den Sie monatlich in Ihre bAV einzahlen möchten.',
  myBavBeneficiaryDescription:
    'Wen möchten Sie als bezugsberechtigte Person hinterlegen, die im Falle Ihres Todes die vereinbarte Leistung erhalten soll? Dieses Bezugsrecht kann jederzeit von Ihnen geändert werden.',
  myBavDocumentsDescription:
    'In diesem Bereich finden Sie alle wichtigen Dokumente, die von Ihrer persönlichen Beraterin oder Ihrer Personalabteilung für Sie hochgeladen wurden. Für Dokumente, die Sie in der Vergangenheit hochgeladen haben, wird angezeigt, ob diese mit Ihrer persönlichen Beraterin oder Ihrer Personalabteilung geteilt wurden.',
  myBavRecycleBinConfirmationMultiple:
    'Sind Sie sicher, dass Sie alle Dokumente in den Papierkorb verschieben möchten?',
  myBavRecycleBinConfirmation: 'Möchten Sie das ausgewählte Dokument wirklich in den Papierkorb verschieben?',
  // Forgot Password
  forgotPwTitle: '⁣Degura bAV-Portal - Passwort vergessen',
  forgotPwWelcome: 'Ihr Passwort zurücksetzen',
  forgotPwWelcomeMsg:
    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir Ihnen einen Link zum Zurücksetzen Ihres Passwortes zusenden können.',
  forgotPwEmail: 'E-Mail / Benutzername',
  forgotPwReset: 'Zurücksetzen',
  forgotPwCancel: 'Abbrechen',
  forgotPwProblem: 'Sie haben Probleme sich einzuloggen?',
  forgotPwSupportLink: 'Support kontaktieren',
  forgotPwConfirm: 'Sie haben Post!',
  forgotPwConfirmMsg: 'Sie haben von uns eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts erhalten.',
  forgotPwBack: 'Zurück zum Login',
  // Login
  loginTitle: '⁣Degura bAV-Portal - Login',
  loginWelcome: 'Herzlichen Willkommen in Ihrem bAV-Portal',
  loginWelcomeToken:
    'Bitte geben Sie die E-Mail-Adresse oder den Benutzernamen ein, welche(n) Sie mit Ihren Unterlagen zum bAV-Portal von uns erhalten haben und legen Sie ein Passwort fest.',
  loginWelcomeMsg: 'Bitte geben Sie Ihre E-Mail-Adresse oder Ihren Benutzernamen sowie Ihr Passwort ein.',
  loginEmail: 'E-Mail / Benutzername',
  loginPassword: 'Passwort',
  loginForgetPw: 'Passwort vergessen?',
  loginBtnText: 'Login',
  loginProblem: 'Sie haben Probleme sich einzuloggen?',
  loginSupportLink: 'Support kontaktieren',
  // Footer
  footerCopyright: 'Copyright 2023 - Degura GmbH ',
  footerLink1: 'Impressum',
  footerLink2: 'Datenschuutz', // Outdated

  linkHeaderOwnProfile: 'Persönliches Profil',
  formPlaceholderEmail: 'Bitte geben Sie hier Ihre E-Mail-Adresse ein',
  formPlaceholderPassword: 'Bitte geben Sie hier Ihr Passwort ein',
  formPlaceholderNewPassword: 'Geben Sie ein neues Passwort ein',
  formPlaceholderRepeatPassword: 'Bitte wiederholen Sie das Passwort',
  formLabelPensionSlider: 'Ihr Beitrag',
  buttonUpload: 'Hochladen',
  browse: 'Durchsuchen',

  tooltipNotificCompany: 'Sie können für jedes Unternehmen eine E-Mail-Adresse für Benachrichtigungen festlegen.',
  tooltipNotificEmail: 'Hier können Sie die E-Mail-Adresse anpassen, an die Ihre Benachrichtigungen gesendet werden.',
  tooltipRemindMultiple: 'Sie können mehrere Mitarbeiter auswählen und ihnen eine Erinnerung versenden.',
  tooltipDeleteMultiple: 'Sie können mehrere Mitarbeiter auswählen, um diese zu löschen.',
  tooltipLeftArrowDisabled: 'Sie befinden sich auf der ersten Seite.',
  tooltipDeleteMultipleDocuments: 'Wählen Sie zunächst Dokumente aus, bevor Sie diese löschen.',
  tooltipDownloadMultipleDocuments: 'Wählen Sie zunächst Dokumente aus, bevor Sie diese herunterladen.',
  tooltipDocsPaginationLeft: 'Sie befinden sich auf der ersten Seite der Dokumentenliste.',
  tooltipUploadButtonDisabled: 'Bevor der Upload starten kann, legen Sie für jedes Dokument eine Kategorie fest.',
  tooltipAlertIcon: 'Upload fehlgeschlagen. Versuchen Sie es erneut.',
  tooltipConcludeButtonDisabled: 'Sie können den Upload abschließen, sobald alle Dateien geladen sind.',
  userGreeting: 'Guten Tag, {TITLE} {USER_LASTNAME}!',
  personalConsultant: '{TITLE} {CONSULTANT_LASTNAME}',
  formTitleLogin: 'Anmeldung',
  faqAnswer1:
    '<p>Mit einer betrieblichen Altersvorsorge bauen Sie sich dank der Zuschüsse Ihres Arbeitgebers und des Staates eine zusätzliche Altersvorsorge auf. Das lohnt sich für Sie als Arbeitnehmer, weil sich Ihr finanzieller Spielraum im Ruhestand erweitert. Zum Ende der Laufzeit haben Sie dann auch die Möglichkeit sich zu entscheiden - lebenslange Rente, egal wie alt Sie werden, eine einmalige Kapitalauszahlung zum Rentenbeginn oder aber auch ein Mix aus beidem.</p>',
  faqAnswer2:
    '<p>Das entscheiden Sie selbst. Der Staat fördert einen Beitrag bis 604,00 EUR Brutto inkl. Arbeitgeberzuschüsse pro Monat. Bis zu 302,00 EUR können Sie ohne Abzug von Steuern und Sozialversicherungsbeiträgen in Ihren Vertrag einzahlen. Zusätzliche 302,00 EUR sind außerdem steuerfrei.</p><img src="{imgValueMaxContributonDE}"/>',
  faqAnswer3:
    '<p>Wie viel Sie zurücklegen möchten, entscheiden ie selbst. Ein guter Richtwert für Ihren Eigenbeitrag sind etwa 5 bis 8 Prozent des Nettoverdienstes. Dieser Beitrag wird vom Staat und Ihrem Arbeitgeber noch einmal stark aufgestockt. Bereits mit einem Eigenbeitrag von 50,00 EUR können Sie einen Beitrag von 120,00 EUR generieren, während bei einem Eigenbeitrag von 100,00 EUR ein Beitrag von 240,00 EUR möglich ist.</p><p>Generell gilt die Regel: lieber am Anfang mehr, als am Ende zu wenig. Wenn Sie den Beitrag zu Beginn oder in der Mitte Ihres Arbeitslebens etwas höher ansetzen, können Sie ihn jederzeit reduzieren. Setzen Sie ihn aber von Anfang an zu niedrig an, mag es am Ende zu spät sein, ihn so anzupassen, dass Ihnen im Alter Ihre Rente ausreicht.</p><img src="{imgRecomContrDE}"/>',
  faqAnswer4:
    'Sie entscheiden, wie viel Sie in Ihre betriebliche Altersvorsorge einzahlen. Die gewählte Beitragshöhe kann von Ihnen erhöht, reduziert oder pausiert und reaktiviert werden.',
  faqAnswer5:
    '<p>Sie können Ihren Vertrag jederzeit und ohne Angabe von Gründen pausieren.</p><ul><li>Wenn Sie Ihre Arbeit nach Ihrer Elternzeit wieder aufnehmen, können Sie Ihren Vertrag wie gewohnt fortführen. Solltest Sie dann einen neuen Arbeitgeber haben, springen Sie zur Frage: "Was passiert mit meinem Vertrag, wenn sich mein Arbeitgeber ändert?"</li><<li>Sie haben immer die Möglichkeit, mit eigenen Mitteln weiter in Ihre betriebliche Altersvorsorge einzuzahlen. Das kann vor allem im Fall einer Selbstständigkeit interessant sein.</li><li>„Hartz-IV-sicher“: Ihr Erspartes der betriebliche Altersvorsorge (bAV) zählt nicht zu den Ersparnissen, die Sie aufbrauchen müssen, bevor Sie Anspruch auf Arbeitslosengeld II haben. In diesem Fall wäre Ihr bAV-Kapital sicher vor einer Anrechnung durch den Staat.</li></ul>',
  faqAnswer6:
    '<p>Bei einigen Anbietern kann man einen Vertrag auch aus dem Ausland privat weiter fortführen. Wenden Sie sich am besten in diesem Fall an Ihren zuständigen Berater.</p>',
  faqAnswer7:
    '<p>Ihre bisher eingezahlten Beiträge gehen bei einem Arbeitsplatzwechsel nicht verloren.</p><p>Hier gibt es grundsätzlich drei Möglichkeiten:</p><ol><li>Ihr Vertrag wird von Ihrem neuen Arbeitgeber weitergeführt.</li><li>Ihr angespartes Kapital aus der betrieblichen Altersvorsorge  wird in die Versorgungseinrichtung des neuen Arbeitgebers eingezahlt.</li><li>Sie lassen Ihren bisherigen Vertrag ruhen und erhalten einen neuen. Ihr Leistungsanspruch aus der bisherigen betrieblichen Altersvorsorge bleibt dabei selbstverständlich erhalten. Welche dieser Optionen gewählt wird, entscheidet Ihr neuer Arbeitgeber. </li></ol>',
  faqAnswer8:
    '<p>Ihre betriebliche Altersvorsorge ist bei einer Insolvenz geschützt. Selbst wenn es für Ihr Unternehmen schlecht laufen sollte und die Firma insolvent wird, bekommen Sie die betriebliche Altersvorsorge wie vereinbart ausgezahlt.</p>',
  faqAnswer9:
    '<p>Einen Leistungsanspruch erfolgt, sobald Sie die gesetzliche Altersrente beziehen. Der Eintritt in die Bezugszeit der gesetzlichen Altersrente erfolgt abhängig von Ihrem gesetzlichen Renteneintrittsalter.</p>',
  faqAnswer10:
    '<p>Das entscheiden Sie selbst. Sie können sich Ihr angespartes Kapital zum Rentenbeginn entweder einmalig oder in Form einer lebenslangen monatlichen Rente auszahlen lassen. Auch eine Mischform ist möglich, indem Sie sich zu Rentenbeginn 30% vom Kapital herausnehmen und sich die restlichen ersparnissen in Form einer monatlichen lebenslangen Rente auszahlen lassen.</p>',
  faqAnswer11:
    '<p>In einem Todesfall vor Renteneintritt gehen alle bis dato eingezahlten Beiträge an die zuvor vertraglich festgelegten Hinterbliebenen. <p> Im Fall des Todes nach Rentenbeginn bekommen die Hinterbliebenen* weiterhin eine Hinterbliebenenrente ausgezahlt, die durch die Rentengarantiezeit festgelegt ist.</p><img src="{imgDeathDE}"/>',
  faqAnswer12:
    '<p>Ihre Ansprüche zur gesetzlichen Rente minimieren sich anteilig, da es sich bei den Beitägen zur betriebliche Altersvorsorge um eine sogenannte Entgeltumwandlung handelt. Diese Differenz wird jedoch durch die zusätzlichen Ansprüche aus Ihrer betrieblichen Altersvorsorge um ein Vielfaches ausgeglichen.</p><p>Das veranschaulicht Ihnen diese Grafik:</p><img src="{imgStatutory}"/>',
  faqAnswer13:
    '<p>Die Leistung* Ihrer betriebliche Altersvorsorge hängt von der Wertentwicklung Ihres Tarifs ab. Informationen zu den Tarifen der betrieblichen Altersvorsorge, wie auch Beispielrechnungen finden Sie unter dem Menüpunkt “bAV-Info”.<p>In dieser Grafik sehen Sie, wie sich die Leistung Ihrer betriebliche Altersvorsorge mit verschiedenen Wertenwicklungen auf Ihre monatliche Rente und auf das Kapital auswirken kann.</p><img src="{imgBenefits}"/>',
  faqAnswer14:
    '<p>Ihre betriebliche Altersvorsorge hat keinerlei Auswirkungen auf den Lohnsteuerjahresausgleich, weshalb Sie diese nicht gesondert in der Steuererklärung angeben müssen. Beachten bei der Erstellung Ihrer Steuererklärung müssen Sie nur, das setzen eines Kreuzes im Mantelbogen - bei Fragen, wenden Sie sich gerne an Ihren Steuerberater.</p>',
  faqAnswer15:
    '<p>Ja, die lebenslange Rente unterliegt der Steuer- und Pflegeversicherungspflicht somit sind Abgaben fällig. Beiträge zur Krankenversicherung hingegen, zahlen Sie nur dann, wenn Ihre monatliche betriebliche Altersvorsorge über dem sich jährlichen erhöhenden Freibetrag liegt.</p><p>Bei den meisten Arbeitnehmenden fällt die Rente geringer aus, als Ihr Gehalt im Arbeitsleben. In diesem Fall sind die Steuereinsparungen während Ihres Arbeitslebens höher, als im Rentenalter.</p>',
  faqAnswer16:
    '<p>Bei einer Kapitalauszahlung Ihrer betriebliche Altersvorsorge in Deutschland können verschiedene Abgaben anfallen. Sie müssen Einkommensteuer auf die Auszahlung zahlen, die sich nach Ihrem individueellen Steuersatz richtet. Je nach Ihren individuellen Umständen kann auch eine Kranken- und Pflegeversicherungspflicht bestehen.</p>',
  faqAnswer17:
    '<p>Beim Abschluss einer betrieblichen Altersvorsorge im Durchführungsweg der betriebliche Altersvorsorge fallen Abschlusskosten, Vertriebskosten und laufende Verwaltungskosten an. Nähere Informationen dazu erhalten Sie durch Ihren Berater und in den Verbraucherinformationen.</p>',
  roleManagementInfo: 'Die Rollen-Zuweisung ist möglich, sobald Sie ein Unternehmen ausgewählt haben.',
  switchRoleInfo:
    'Sie sind als {USER_ROLE} aktiv. Wechseln Sie zu Ihrem Mitarbeiter-Profil, um Ihre persönlichen und steuerlichen Daten zu bearbeiten.',
  uploadInfo1: 'Bitte ziehen Sie Ihre Datei in das Feld oder',
  myBavUploadDocumentText2: 'Sie Ihre Dateien (max. 5 Dateien)',
  messageMaxUploadAmount: 'Bitte wählen Sie maximal fünf Dateien gleichzeitig aus!',
  paragraphFunctionality1:
    '{COMPANY} zahlt Ihnen ein Bruttogehalt. Von diesem werden Steuern und Sozialversicherungen abgezogen. Zur freien Verfügung bleibt Ihnen damit nur das Netto.',
  paragraphFunctionality3:
    'Die bAV ermöglicht es Ihnen in Form einer sogenannten Entgeltumwandlung Teile des Bruttogehaltes für Ihre Altersvorsorge zu verwenden, die sonst an den Staat gehen würden.\n\n Weil {COMPANY} Ihre Altersvorsorge am Herzen liegt, erhalten Sie einen weiteren Zuschuss obendrauf. Sie sparen im Endeffekt also doppelt!',
  noticeConfirmCorrectness:
    'Durch den Klick auf „Weiter“ bestätige ich die Korrektheit meiner Daten und stimme zu, für eventuelle Rückfragen telefonisch kontaktiert werden zu dürfen.\n\n Weiterhin bestätige ich, dass mir wichtige Informationen sowie Kopien von persönlichen Formularen per E-Mail zugesendet werden dürfen.',
  noticeConfirmRenunciation: 'Ihre Auswahl: Ich verzichte auf eine bAV.',
  noticeConfirmAccept: 'Ihre Auswahl: Eine bAV soll für mich eingerichtet werden.',
  noticeConfirmRequest: 'Ihre Auswahl: Ich möchte ein individuelles Angebot für eine bAV anfragen.',
  noticeContributionExample:
    'Diese Berechnung dient lediglich der Veranschaulichung ohne Berücksichtigung Ihrer persönlichen Steuer- und Sozialversicherungsbeiträge. Eine auf Ihre persönliche Situation abgestimmte Berechnung bekommen Sie von uns im individuellen Angebot zugesendet.',
  noticePensionCalculatorTaxData:
    'Um mit dem Pensionsrechner noch genauere Ergebnisse zu erzielen, benötigen wir einige steuerlichen Angaben von Ihnen. Diese können Sie hier hinterlegen.',
  noticeSendLoginLink:
    'Fordern Sie hier Ihren persönlichen Login-Link per E-Mail an, um sich im bAV-Portal über die betriebliche Altersvorsorge zu informieren.',
  noticeAlreadyCreatedPassword: 'Sie haben bereits ein Passwort erstellt? {0} gelangen Sie direkt zum Login.',
  noticeSubsidyLimit:
    '{COMPANY} bezuschusst Ihre bAV mit {SUBSIDY} bis zu einem Gehaltsniveau der Beitragsbemessungsgrenze (BBG). Liegt Ihr Gehalt über der BBG, so entfällt dieser Zuschuss.',
  infoDecline: 'Sie erhalten eine Bestätigung Ihrer Verzichtserklärung.',
  infoDeclineEmail:
    'Geben Sie E-Mail-Adresse an, wenn Sie eine Bestätigung Ihres Verzichts auf diesem Weg erhalten möchten.',
  infoAccept: 'Wir melden uns umgehend für alle weiteren Schritte bei Ihnen.',
  pensionSliderWageLabel: 'Ihr Bruttolohn in EUR (monatlich)',
  introFAQ: 'Ihre Fragen, unsere Antworten',
  introDecline: 'Sind Sie sicher, dass Sie die Vorteile einer bAV nicht in Anspruch nehmen wollen?',
  introAcceptOrRequest: 'Der erste Schritt hin zu einem sorgenfreien Ruhestand',
  introSubFAQ: 'In diesem Bereich finden Sie die wichtigsten Fragen und Antworten zur bAV zusammengefasst.',
  subheadlineFAQ: 'Allgemein',
  subHeadingFAQ: 'Meistgestellte Fragen',
  introSubEmployeeWelcome:
    'Schön, dass Sie hier sind. In diesem Bereich können Sie sich über die Leistungen und Vorteile einer betrieblichen Altersvorsorge (bAV) informieren.',
  introSubDecline:
    'Wir bedauern Ihre Entscheidung. Nach Ihrer Abmeldung werden Sie keine weiteren E-Mails von uns erhalten. Sollten Sie Ihre Meinung ändern, können Sie Ihren Verzicht jederzeit widerrufen.',
  introSubAcceptOrRequest:
    'Schön, dass Sie sich für eine bAV entschieden haben. Sie haben es fast geschafft! Füllen Sie die erforderlichen Angaben zu Ihrer Person aus und teilen Sie uns Ihren gewünschten Monatsbeitrag mit. Im Anschluss kontaktiert Sie Ihr persönlicher Berater, um alle weiteren Inhalte mit Ihnen zu klären.',
  introSubMyCP: 'In diesem Bereich können Sie Ihren aktuellen bAV-Status abrufen.',
  headlineConsultantMale: 'Ihr Berater',
  headlineConsultantFemale: 'Ihre Beraterin',
  headlineContactMale: 'Ihr persönlicher Ansprechpartner',
  headlineContactFemale: 'Ihre persönliche Ansprechpartnerin',
  headlineLogin: 'Herzlich Willkommen in Ihrem bAV-Portal!',
  headlineResetPassword: 'Ihr Passwort zurücksetzen',
  headlineCheckInbox: 'Schauen Sie in Ihr E-Mail Postfach',
  headlineFAQ: 'Ihre Fragen, unsere Antworten',
  headlineEmployerSettings: 'Ihre Unternehmens-Einstellungen',
  headlineEmployerRoleAssignment: 'Ihre Unternehmens-Rollen',
  headlineEmployeeProfile: 'Ihr persönliches Profil',
  headlineEmployeeSettings: 'Ihre persönlichen Einstellungen',
  headlineContactFAQ: 'Ihre Frage war nicht dabei?',
  subheadlineContactFAQ: 'Ihr(e) persönliche(r) Berater(in) nimmt sich gerne Zeit, diese zu beantworten.',
  headlineContact: 'Sie haben noch Fragen?',
  headlineEmployerWelcome: 'Herzlich Willkommen in Ihrem bAV-Portal',
  headlineContractualDocuments: 'Ihre Auftragsunterlagen',
  headlineDocuments: 'Ihre Dokumente',
  headlineUploadOverlay1: 'Legen Sie die Dokumentenkategorie fest',
  headlineFileUploadOverlay: 'Ihre Dateien werden hochgeladen',
  headlineCTAAcceptOffer: 'Bereit für Ihren Ruhestand?',
  headlineCTARequestOffer: 'Benötigen Sie weitere Informationen?',
  headlineCTAPrivateChat: 'Haben Sie eine individuelle Frage?',
  headlineStatusProgressBar: 'Alles, was Sie wissen müssen',
  headlineExamplesTotalContribution: 'Mit einer bAV profitieren Sie gleich doppelt!',
  headlineProductDataSheetDownload: 'Ihre {COMPANY} Produktdatenblätter',
  headlineConsultationCTA: 'Herzlichen Glückwunsch! Sie sind jetzt bAV Experte.',
  headlinePersonalDataInput: 'Bitte geben Sie zur Bestätigung Ihre Daten ein',
  headlineAcceptOffer: 'Bitte füllen Sie zunächst folgende Felder zu Ihrer Person aus',
  headlineContactDataInput: 'Bitte teilen Sie uns mit, wie wir Sie erreichen können',
  headlineCompanyPensionInput: 'Legen Sie Ihren individuellen Monatsbeitrag fest',
  headlineCompanyPensionCalculator: 'Berechnen Sie Ihre Vorteile',
  headlinePensionCalculator: 'Ihr potenzieller Gesamtbeitrag nach Arbeitgeberzuschuss',
  headlineTaxDataInput: 'Um das Angebot auf Sie abzustimmen, benötigen wir noch einige steuerliche Angaben',
  headlineCheckData: 'Ihre persönlichen Angaben',
  headlineEvents: 'Bleiben Sie auf dem Laufenden',
  headlineCompanyPensionStatusBar: 'Ihr bAV-Antragsstatus',
  headlineMoveCompanyPension: 'Sie wechseln das Unternehmen?',
  headlineReminder: 'Erinnerung versenden',
  headlineAcceptPrivacyPolicy: 'Ihre Privatsphäre ist uns wichtig.',
  headlineFileNotFound: 'Die Seite, die Sie suchen, existiert nicht.',
  messageDeleteSelectedDocuments: 'Sie sind im Begriff {count} Dokument(e) zu löschen. Sind Sie sich sicher?',
  messageArchiveDeleteSelectedDocuments:
    'Sie sind im Begriff {count} Dokument(e) zu unwiderruflich löschen. Sind Sie sich sicher?',
  messageEmployeeEdit:
    'In diesem Bereich können Sie steuerliche und persönliche Informationen bearbeiten, den bAV-Status einsehen oder Dokumente mit dem Mitarbeiter teilen.',
  messageAcceptPrivacyPolicy:
    'In unserer {0} können Sie nachlesen, wie wir Ihre Daten sichern und verarbeiten.\n\n Damit wir das dürfen, benötigen wir Ihre Zustimmung.',
  messageDeleteSelectedEmployees: 'Sie sind im Begriff {count} Mitarbeiter zu entfernen. Sind Sie sich sicher?',
  subheadingLogin:
    'Bitte geben Sie die Login-Daten ein, welche Sie mit Ihren Unterlagen zum bAV-Portal erhalten haben.',
  receivedEmailNoticeResetPassword:
    'Wenn Ihre E-Mail-Adresse in unserem System erfasst ist, haben Sie eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts erhalten.',
  emailMissingNoticeResetPassword:
    'Falls Sie keine E-Mail bekommen haben, wenden Sie sich bitte an Ihre Personalabteilung.',
  subheadingResetPassword:
    'Bitte geben Sie Ihre E-Mail-Adresse an, damit wir Ihnen einen Link zum Zurücksetzen Ihres Passwortes zusenden können.',
  messageFAQ:
    'Die betriebliche Altersvorsorge zeichnet sich durch Besonderheiten in Bezug auf staatliche Unterstützung, Steuern und potenzielle Renditen aus. Inwiefern? Im Folgenden finden Sie die wichtigsten Fragen und Antworten zur bAV zusammengefasst.',
  messageConsultationCTA:
    'Nun entscheiden Sie!\n \nSollten noch Fragen offen sein, können Sie diese im privaten Chat mit Ihrem persönlichen Berater klären.\n \nFalls Sie sich bereits entschieden haben, können Sie auch direkt eine bAV abschließen oder ein unverbindliches, individuell auf Sie abgestimmtes Angebot anfragen. Bei beiden Varianten wird Sie im Anschluss Ihr Berater kontaktieren und alle weiteren, vertraglichen Inhalte mit Ihnen persönlich klären.\n \nWofür möchten Sie sich entscheiden?',
  messageContactFAQ: 'Unsere Berater nehmen sich gerne Zeit, sie Ihnen zu beantworten.',
  messageContact: 'Unsere Berater beantworten sie Ihnen gerne.',
  messageEmployerRoleAssignment:
    'In diesem Bereich können Sie innerhalb Ihrer zu verwaltenden Unternehmen anderen Mitarbeitern Rollen zuweisen, damit diese Sie bei administrativen Aufgaben unterstützen können.',
  messageEmployerSettings: 'In diesem Bereich können Sie die Einstellungen für Ihr Benutzerkonto verwalten.',
  subHeaderOwnProfile:
    'Dieser Bereich ist eine Übersicht über Ihre persönlichen und steuerlichen Daten. Sie können hier beliebig Anpassungen vornehmen. Beachten Sie hierbei, dass eventuell Änderungen in Ihren vertraglichen Unterlagen notwendig werden, falls Sie bereits eine bAV bei uns abgeschlossen haben.',
  messageEmployeeSettings:
    'Dieser Bereich bietet Ihnen die Möglichkeit, die Einstellungen für Ihr Benutzerkonto zu verwalten.',
  messageEmployerWelcome:
    'Dieser Bereich stellt eine Übersicht über alle {COMPANY} Mitarbeiter dar. Nachdem Sie die Mitarbeiterinformationen hochgeladen haben, können Sie hier deren bAV-Status einsehen und Dokumente mit ihnen austauschen.',
  messageEmployeeOverview:
    'Fügen Sie neue Mitarbeiter in einer Excel-Datei (eine Vorlage können Sie {0} herunterladen) oder manuell in der Übersicht hinzu. Sie können nachträglich jederzeit neue Mitarbeiter hinzufügen und alte aus der Liste entfernen.',
  messageDeleteEmployee: '{NAME} ist ein fehlerhafter Eintrag? Entfernen Sie ihn/sie aus der Mitarbeiterliste.',
  messageSaveEditing:
    'Sie sind im Begriff, die Angaben von {NAME} zu verändern. Gegebenenfalls sind Vertragsanpassungen notwendig. Sollen die folgenden Felder angepasst werden?',
  messageUnsavedChanges:
    'Sie sind im Begriff, die Seite zu verlassen, ohne Ihre vorgenommenen Änderungen zu speichern. Wenn Sie fortfahren, gehen folgende Änderungen verloren:',
  messageCancelEdit:
    'Sie sind im Begriff, die Bearbeitung abzubrechen. Wenn Sie fortfahren, gehen folgende Änderungen verloren:',
  messageSaveEmployeeStatus:
    'Sie sind im Begriff, {NAME} als ausgetreten zu melden. Er/Sie erscheint dann nicht mehr in der Mitarbeiterübersicht. Wollen Sie fortfahren?',
  messageDeleteEmployeeOverlay:
    'Sie sind im Begriff, {NAME} zu löschen. Er/Sie wird damit unwiderruflich entfernt. Möchten Sie fortfahren?',
  messageChangeBavStatus:
    'Sie sind im Begriff, den bAV-Status des Mitarbeiters zu ändern. Bitte bestätigen Sie, dass Sie diese Änderung wirklich vornehmen möchten.',
  messageStatusProgressBar:
    'Verschaffen Sie sich in drei einfachen Schritten einen Überblick über die Funktionsweise einer bAV. Im Anschluss entscheiden Sie!',
  messageContractualDocuments:
    'In diesem Bereich finden Sie alle für Ihr Unternehmen relevanten Dokumente, wie Versorgungsordnung und Gruppenverträge Ihrer Mitarbeiter.',
  messageCTAAcceptOffer:
    'Nutzen Sie die finanziellen Vorteile, die eine bAV für Sie bereithält und beginnen Sie heute, für Ihre Zukunft vorzusorgen.',
  messageeIntroVideo:
    'Im folgenden Video erfahren Sie mehr über die Leistungen einer bAV und wie Sie diese für sich nutzen können.',
  messageExampleCalculations:
    'Anhand der folgenden Beispiele werden Ihnen die Vorteile einer bAV basierend auf unterschiedlichen Bruttolöhnen und bAV-Beiträgen veranschaulicht. Wählen Sie einfach das Beispiel aus, das Ihrer Situation am nächsten kommt. Weiter unten stehen Ihnen alle drei Beispiele auch zum Download zur Verfügung.\n \nUm ein vollkommen auf Sie und Ihre Situation abgestimmtes Angebot zu erhalten, können Sie später gerne ein kostenloses, individuelles und unverbindliches Angebot anfragen.',
  messageTaxDataInput: 'Alle erforderlichen Daten finden Sie auf Ihrem Gehaltsauszug.',
  messageCompanyPensionInput:
    'Hier können Sie angeben, wie viel Ihres Nettolohns Sie in die bAV investieren möchten.\n \nDiesen Betrag können Sie später auch noch einmal verändern.',
  messageCaseOfDeath:
    'Wenn Sie derzeit keine Person angeben wollen, wählen Sie die Option „Keine Auswahl“. Dies können Sie jederzeit wieder ändern.',
  messageCheckData:
    'Bitte überprüfen Sie nochmals Ihre eingegebenen Daten. Falls notwendig, können Sie diese hier korrigieren.',
  messageProductDataSheetDownload:
    'Laden Sie sich hier das Produktinformationsblatt herunter, um mehr über das bAV-Produkt Ihres Arbeitgebers zu erfahren.',
  messageExampleCalculationsDownload:
    'Hier können Sie sich die PDFs zur Beispielrechnung herunterladen. Darin finden Sie alle oben dargestellten Informationen etwas ausführlicher am Beispiel von Lohnabrechnungen, sowie Auswertungen von Kapitalleistungen und Zuschüssen.',
  messageCompanyInfoDownload:
    'Hier können Sie sich weitere Informationen zur genauen Ausgestaltung der bAV-Versicherung in Ihrem Unternehmen herunterladen.',
  messageInsuranceProductOverview:
    'Im Folgenden sind alle Produkte aufgelistet, die Ihr Arbeitgeber Ihnen im Rahmen der bAV zur Verfügung stellt.\n\nSie können aus der Auswahl frei wählen, für welches Produkt Sie sich entscheiden möchten. In den PDFs, die wir Ihnen unten zum Download bereit stellen, erhalten Sie alle wichtigen Informationen für Ihre Entscheidung.',
  messageEmployeeEvents: 'Dieser Bereich bietet Ihnen eine Übersicht über alle aktuellen Vorgänge rund um Ihre bAV.',
  messageEmployerEvents:
    'Dieser Bereich bietet Ihnen eine Übersicht über alle aktuellen Vorgänge innerhalb Ihres Unternehmens.',
  messageEmployeeDocuments:
    'In diesem Bereich sind Ihre wichtigsten persönlichen Dokumente hinterlegt und geordnet. Sie finden hier beispielsweise Policen oder Angebotsrechnungen.',
  messageEmployerDocuments:
    'In diesem Bereich finden Sie eine Übersicht aller Dokumente, die Sie mit den {COMPANY} Mitarbeitern teilen.',
  messageCompanyPensionCalculator:
    'Prüfen Sie mit dem Pensionsrechner, welche Zuschüsse Sie bei verschiedenen Monatsbeiträgen erwarten. So trifft sich manche Entscheidung leichter!',
  messageUploadOldContract: 'Sie haben bereits eine bAV? Hinterlegen Sie hier ganz einfach Ihren Bestandsvertrag.',
  messageMoveCompanyPension:
    'Ihre bAV-Beiträge gehen bei einem Arbeitsplatzwechsel nicht verloren. Teilen Sie uns Ihren neuen Arbeitgeber mit und sprechen Sie mit ihm darüber, in welcher Form die bAV fortgeführt werden soll.',
  messageDeleteDocuments:
    'Sie sind im Begriff, Dokumente zu löschen. Mit dieser Aktion verschieben Sie die ausgewählten Dokumente unwiderruflich in den „Gelöscht“-Ordner. Möchten Sie fortfahren?',
  messageImportEmployee:
    'Bitte füllen Sie die Felder für den neuen Mitarbeiter aus, um ihn zu der Liste Ihrer Mitarbeiter hinzuzufügen.',
  messageSelectEmployee: 'Bitte legen Sie einen Mitarbeiter fest, mit dem Sie die Dokumente teilen wollen.',
  messageSelectCompany: 'Bitte wählen Sie ein Unternehmen aus, für das Sie die Dokumente bereitstellen möchten.',
  messageFileNotFound:
    '<p>Leider konnten wir die Seite, nach der Sie suchen, nicht finden. Prüfen Sie die URL, ob sich eventuell ein Schreibfehler eingeschlichen hat.</p><p>Es kann auch sein, dass diese Seite umgezogen wurde oder nicht mehr existiert. Sollte dieses Problem weiter bestehen, kontaktieren Sie gerne unser Degura Support-Team.</p>',
  messageShareWithHR:
    'Ihre Dokumente werden automatisch mit Ihrem persönlichen Berater geteilt. Sollten Sie die Dokumente zusätzlich mit Ihrer zuständigen HR-Abteilung teilen wollen, setzen Sie bitte unten ein Häkchen in der Checkbox.',
  messageCookieConsentForFeature:
    'Um unsere Funktionen wie Chat und Terminvereinbarung nutzen zu können, ist es notwendig, dass Sie der Verwendung von Cookies zustimmen. Durch einen Klick auf [Bestätigen] können Sie uns Ihre Zustimmung dafür geben.',
  successChangeFieldHeadline: 'Gespeichert.',
  successDocumentsUploaded: 'Ihre Dokumente wurden erfolgreich hochgeladen.',
  successChangeFieldChangeContract:
    'Das Feld wurde erfolgreich aktualisiert. In den kommenden Tagen veranlasst Ihr Degura Ansprechpartner eine Korrektur in den Vertragsunterlagen.',
  successDeclineOfferMessage: 'Ihr Verzicht auf eine bAV wurde erfolgreich eingereicht.',
  successAcceptOfferMessage:
    'Ihr Vertragswunsch wurde erfolgreich eingereicht. In den kommenden Tagen wird Ihr persönlicher Berater erneut auf Sie zukommen.',
  successRequestOfferMessage:
    'Ihre Angebotsanfrage wurde erfolgreich eingereicht. In den kommenden Tagen wird Ihr persönlicher Berater erneut auf Sie zukommen.',
  errorLoginCredentials:
    'Der von Ihnen eingegebene Benutzername oder das Passwort ist nicht korrekt. Bitte überprüfen Sie Ihre Eingabe.',
  errorLoginChangePassword: 'Die Passwörter stimmen nicht überein. Bitte wiederholen Sie Ihre Eingabe.',
  errorExcelImportHeadline:
    'Folgende Einträge in der Excel-Datei waren leider fehlerhaft. Bitte korrigieren Sie diese und wiederholen Sie den Import für diese Zeilen.',
  errorChangeFieldHeadline: 'Hoppla.',
  errorChangeField:
    'Dieses Feld konnte nicht aktualisiert werden. Sollte der Fehler erneut auftreten, wenden Sie sich bitte an das Degura Support-Team.',
  errorChangePassword:
    'Ihr Passwort konnte nicht geändert werden. Bitte versuchen Sie es erneut. Sollte das Problem weiterhin auftreten, wenden Sie sich bitte an das Degura Support-Team.',
  errorPasswordForgottenInvalidEmail:
    'Es sieht so aus, als gäbe es keine mit Ihrem Konto verknüpfte E-Mail-Adresse. Bitte wenden Sie sich an Ihren Kundenbetreuer.',
  errorInvalidEmail: 'Bitte geben Sie eine E-Mail-Adresse mit gültigem Format ein.\n Bsp.: max.mustermann@gmail.com',
  errorInvalidPhone: 'Die Nummer ist ungültig. Bitte überprüfen Sie diese nochmal.',
  errorPhoneLength: 'Die Telefonnummer muss mindestens 10 Zeichen lang sein.',
  errorGrantChangeMessage:
    'Die Freigabeeinstellungen für das Dokument konnten leider nicht aktualisiert werden. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an das Degura Support-Team.',
  errorSendLoginLink:
    'Leider konnten wir keine gültige E-Mail-Adresse finden, an die wir Ihren Login-Link senden können. Bitte geben Sie hier Ihre E-Mail-Adresse ein:',
  changePasswordTokenExpiredMessage: 'Das Token zum Ändern Ihres Passworts ist abgelaufen.',
  redirectMessageSuccessChangePassword:
    'Passwort erfolgreich geändert! Sie werden in {0} Sekunden auf die Anmeldeseite umgeleitet.',
  keyAccountYourClientCompaniesAssigned: 'Ihren Kundenunternehmen',
  keyAccountYourClientCompanies: 'Ihre Kundenunternehmen',
  keyAccountYourClientCompaniesEmployees: 'Ihren Kundenunternehmen zugeordnete Mitarbeiter',
  keyAccountYourClientCompaniesEmployeeDocuments: 'Ihren Kundenunternehmen zugeordneten Mitarbeiter-Dokumente',
  successTitle: 'Änderung erfolgreich',
  error404Message:
    'Unser System konnte etwas nicht dort finden, wo es sein sollte. Bitte melden Sie sich bei unserem Support.',
  errorSessionExpiredMessage: 'Ihre Sitzung wurde vom System automatisch beendet. Bitte melden Sie sich erneut an.',
  successExistingContractPosted: 'Ihr bestehender bAV-Vertrag wurde in Ihrem Profil hinterlegt.',
  errorExistingContract:
    'Leider ist etwas schiefgelaufen. Bitte versuchen Sie es noch einmal oder kontaktieren Sie Ihren persönlichen Degura Berater.',
  errorChangePasswordNoEmail:
    'Leider ist in Ihrem Konto keine E-Mail-Adresse hinterlegt. Bitte wenden Sie Sich an Ihren Degura Berater.',
  messageToggleShareWithHR:
    'Sie sind im Begriff, folgendes Dokument für Ihre Personalabteilung freizugeben. Möchten Sie fortfahren?',
  aut2faSetupHeading: 'Zwei-Faktor-Authentifizierung',
  aut2faSetupIntro:
    'Um Ihr Konto zu schützen, richten Sie bitte Ihren Zwei-Schritte-Login ein. Nach jeder Anmeldung erhalten Sie zukünftig einen Code auf Ihr Mobiltelefon.',
  auth2faSetupInstructions1:
    'Bitte geben Sie unten den Code ein, den Sie per SMS auf Ihr Mobiltelefon erhalten haben. Sie haben noch',
  auth2faSetupInstructions2: 'Sekunden Zeit, um den Code einzugeben.',
  auth2faPhoneLabel: 'Bitte geben Sie Ihre Mobilnummer ein.',
  auth2faLabelCode: 'Bestätigungscode',
  auth2faCodeRequired: 'Bestätigungscode',
  auth2faSendCode: 'Code nochmal senden',
  auth2faSetupExpired1: 'Ihre Zeit zum Eingeben des Codes ist abgelaufen. Bitte überprüfe, ob Ihre Mobilnummer',
  auth2faSetupExpired2: 'korrekt ist und klicke auf “Code nochmal senden”, um einen neuen Code anzufordern.',
  auth2faSetupSuccess: 'Herzlichen Glückwünschen! Sie haben Ihren Zwei-Schritte-Login erfolgreich eingerichtet.',
  auth2faSetupPlaceholder: 'Mobilnummer',
  auth2faConfirmCodePlaceholder: 'Code',
  auth2faLoginIntro1: 'Bitte geben Sie unten den Code ein, den Sie per SMS auf Ihr Mobiltelefon ',
  auth2faLoginIntro2: 'erhalten hast. Sie haben noch',
  auth2faLoginIntro3: 'Sekunden Zeit, um den Code einzugeben.',
  auth2faLoginExpired1:
    ' Ihre Zeit zum Eingeben des SMS-Codes ist abgelaufen. Bitte überprüfen Sie, ob Ihre Mobilnummer',
  auth2faLoginExpired2: 'korrekt ist und klicken Sie auf “Code nochmal senden”, um einen neuen Code anzufordern.',
  auth2faSettingsHeading: 'Zwei-Faktoren-Authentifizierung - Einstellungen',

  personalProfileDescription:
    'In diesem Bereich können Sie Ihre Profilinformationen bearbeiten, die für Ihre betriebliche Altersvorsorge verwendet werden. Bitte beachten Sie, dass wenn Sie sich für eine betriebliche Altersvorsorge entschieden haben und Ihre Entscheidung ändern möchten, dies über die Seite "Meine bAV" tun müssen.',
  personalProfileTitle: 'Eigenes Profil',

  errorBreachedPassword: 'Bitte verwenden Sie ein neues Passwort, dieses wird als sehr unsicher eingestuft.',
  passwordNeedsToBeChanged:
    'Ihr Passwort entspricht nicht den Anforderungen. Bitte ändern Sie Ihr Passwort, um sich einloggen zu können.',
  changePassWordText: 'In diesem Bereich haben Sie die Möglichkeit Ihr aktuelles Passwort zu ändern.',
  bavInfoHeading1: 'Alles, was Sie wissen sollten',
  bavInfoHeading2: 'Welche finanziellen Vorteile entstehen für mich durch eine bAV?',
  bavInfoIntroText:
    'Verschaffen Sie sich in drei Schritten einen einfachen Überblick über die Funktionsweise einer bAV.',
  requestOfferTileHeading: 'Sie möchten ein unverbindliches Angebot erhalten?',
  requestOfferTileText:
    'Wie hoch Ihre betriebliche Altersvorsorge ausfallen wird, hängt davon ab, wie lange und wie viel Sie einzahlen. Wir zeigen Ihnen gerne auf, wie Sie Ihre gesetzliche Rente aufbessern können.',
  advantagesTileHeading: 'Sie möchten sich gerne die Vorteile einer bAV im Detail aufzeigen lassen?',
  advantagesTileText:
    'Es gibt viele Möglichkeiten, Ihre finanzielle Basis für später aufzubessern. Wir erklären Ihnen die bAV und Ihre Optionen.',
  menuFAQ: 'FAQ',
  personalProfileInfoText:
    'Das Ändern von Daten auf dieser Seite zieht keine Vertragsänderungen nach sich. Sollten Sie Vertragsdetails anpassen wollen, nehmen Sie diese Änderungen unter <a><strong>Meine bAV</strong></a> vor.',
  personalProfileInfoTextHRAdmin:
    'Das Ändern von Daten auf dieser Seite zieht keine <strong> <a>Vertragsänderung</a></strong> nach sich. Sollten Sie einen Vertrag anpassen wollen, bitten wir Sie dies unter <a><strong>Vertragsanpassungen</strong></a> zu tun.',
  personalProfileHeadingPersonal: 'Persönliche Angaben',
  personalProfileHeadingFinancial: 'Finanzielle Angaben',
  financialInformationNet: 'bAV-Beitrag (netto)',
  financialInformationBeneficiary: 'Bezugsberechtigte Person',
  bavTabContractDetails: 'Vertragsdetails',
  enterInformation: 'Fordern Sie jetzt ganz einfach Ihr persönliches, unverbindliches Angebot an.',
  largeTilesIntroDeclined: 'Sie interessieren sich doch für eine bAV?',
  largeTilesIntroOld: 'Sie sind mit Ihrer aktuellen bAV nicht zufrieden?',
  uploadBavMessage: 'Bitte laden Sie hier Ihren bestehenden bAV-Vertrag hoch',
  uploadBavSelectFile: 'Datei auswählen',
  uploadBavConfirm: 'Ihre aktuelle bAV wurde erfolgreich hochgeladen und in Ihren Dokumenten unter Meine bAV abgelegt.',
  submitWaiverMessage: 'Möchten Sie wirklich auf Ihre bAV-Leistung verzichten?',
  submitWaiverConfirm:
    'Ihre Verzichtserklärung wurde erfolgreich an uns übermittelt.<br/>Sie haben jedoch jederzeit die Möglichkeit, Ihre Verzichtserklärung zu widerrufen und zu einem späteren Zeitpunkt eine betriebliche Altersvorsorge abzuschließen.',
  adminEmployeeHeadingLead:
    'Fügen Sie neue Mitarbeiter in einer Excel-Datei (eine Vorlage können Sie <a href="https://assets.degura.de/upload-template/Degura_Mitarbeiterliste_Vorlage.xlsx">hier</a> herunterladen) oder manuell in der Übersicht hinzu. <br>Sie können jederzeit nachträglich neue Mitarbeiter hinzufügen und alte aus der Liste entfernen.',
  adminAddEmployeeHeading: 'Mitarbeiter anlegen',
  adminAddEmployeeLead:
    'Bitte füllen Sie die Felder für den neuen Mitarbeiter aus, um ihn zu der Liste Ihrer Mitarbeiter hinzuzufügen.',
  adminCompanyDocumentsLead:
    'In diesem Bereich finden Sie alle für Ihr Unternehmen relevanten Dokumente, wie Versorgungsordnung und Gruppenverträge Ihrer Mitarbeiter.',
  cookieConsentMessage:
    'Wenn Sie auf „Alle Cookies akzeptieren“ klicken, stimmen Sie der Speicherung von Cookies auf Ihrem Gerät zu, um die Webseiten-Navigation zu verbessern, die Webseiten-Nutzung zu analysieren und unsere Marketingbemühungen zu unterstützen.',
  cookieConsentHeadline: '',
  cookieConsentDescription:
    'Wir nutzen Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und Ihr Online-Erlebnis zu verbessern. Sie können jederzeit die Kategorien an- und ausstellen.',
  cookieConsentAccept: 'Akzeptieren',
  cookieConsentManage: 'Cookie-Einstellungen',
  cookieConsentAnalytics: 'Analytische Cookies',
  cookieConsentAnalyticsDesc:
    'Die Nutzung von Analytics ermöglicht es uns, unsere Website durch anonymes Aufzeichnen des Benutzerverhaltens zu optimieren. Weitere Informationen zur Art der von uns durchgeführten Nachverfolgung finden Sie in unseren Datenschutzbestimmungen.’',
  cookieConsentAcceptAll: 'Alle Cookies akzeptieren',
  cookieConsentUse: 'Unsere Nutzung von Cookies',
  cookieConsentManagement: 'Cookie-Verwaltung',
  cookieConsentStrict: 'Unbedingt erforderliche Cookies',
  cookieConsentNecessary:
    'Wir benötigen Ihre Zustimmung, um Ihre Einstellungen zu speichern und die Kernfunktionen unserer Plattform zu steuern, z. B. Anmeldung, Spracheinstellungen, sowie Chat-Funktionen, um mit unseren Kundenberatern in Kontakt zu treten.',
  twoFactorSuccessMsg: 'Sie haben Ihre Zwei-Schritte-Login erfolgreich eingerichtet.',
  twoFactorMobileNumberLabel: 'Mobilnummer',
  uploadDocumentSupport1:
    'Bitte versuche es erneut. Sollte das Problem weiterhin bestehen, kontaktiere bitte das Degura Support-Team.',

  successContractRequestNotification: 'Wir haben Ihre Anfrage erhalten und werden uns bei Ihnen melden.',
  successOfferRequestNotification: 'Wir haben Ihre Anfrage erhalten und werden uns bei Ihnen melden.',
};
