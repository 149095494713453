import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import Vue from 'vue';
import Vuex from 'vuex';
import plugins from './plugins';
import app from './app';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins,
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    ...modules,
  },
  strict: process.env.NODE_ENV !== 'production',
});
