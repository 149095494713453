import createPersistedState from 'vuex-persistedstate';

const plugins = [
  createPersistedState({
    paths: ['auth', 'cookies'],
  }),
];

const devPlugins = [...plugins];

export default process.env.NODE_ENV !== 'production' ? plugins : devPlugins;
