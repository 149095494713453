// eslint-disable-next-line object-curly-newline
import { mdiFileEditOutline } from '@mdi/js';

export default [
  {
    title: 'Content management',
    icon: mdiFileEditOutline,
    to: 'content-management',
    action: 'read',
  },
];
