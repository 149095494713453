<template>
  <v-menu offset-y nudge-bottom="22" min-width="55" left :elevation="$vuetify.theme.dark ? 9 : 8">
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <div
            class="d-flex align-center"
            :class="activeRole === 'ROLE_KEY_ACCOUNT' || activeRole === 'ROLE_ADMIN' ? 'no--border' : 'has--border'"
            v-on="{ ...onMenu, ...(!$vuetify.breakpoint.smAndDown && { ...onTooltip }) }"
          >
            <v-img
              :src="$i18n.locale === 'en' ? locales[0].img : locales[1].img"
              :alt="$i18n.locale"
              height="17px"
              width="27px"
              class="me-2 mr-3 cursor-pointer"
            ></v-img>
            <span class="ml-1 mr-1 cursor-pointer">{{
              $i18n.locale === 'en' ? locales[0].title : locales[1].title
            }}</span>
          </div>
        </template>

        <span>{{ $t('switchLanguageToolTip') }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item-group :value="$i18n.locale" @change="updateActiveLocale">
        <v-list-item v-for="locale in availableLocales" :key="locale.locale" :value="locale.locale">
          <v-img :src="locale.img" height="14px" width="22px" :alt="locale.locale" class="me-2"></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig';
import { getCurrentInstance, computed } from '@vue/composition-api';
import { loadLanguageAsync, setI18nLanguage } from '@/plugins/i18n';
import { DETECT_LANGUAGE } from '../../../store/modules/auth/actions';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const { isRtl } = useAppConfig();
    const activeRole = computed(() => vm.$store.getters.activeRole);
    const companyLangForm = computed(() => vm.$store.getters.companyLangForm);
    const { userData } = vm.$store.getters;

    vm.$store.dispatch(DETECT_LANGUAGE, userData);
    vm.$store.dispatch('app/CHANGE_PREFERRED_LANGUAGE', { userId: userData.id, langCode: vm.$i18n.locale });

    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Deutsch',
        img: require('@/assets/images/flags/de.svg'),
        locale: companyLangForm.value,
      },
    ];

    const availableLocales = computed(() => {
      return locales.filter((l) => l.locale !== vm.$i18n.locale);
    });

    const updateActiveLocale = (locale) => {
      // Set to RTL mode if locale is arabic
      // isRtl.value = locale === 'ar'
      if (!locale) return;

      vm.$store.dispatch('app/CHANGE_PREFERRED_LANGUAGE', { userId: userData.id, langCode: locale });
      vm.$i18n.locale = locale;
      document.title = vm.$t(vm.$route.meta.titleKey) || 'Degura';
    };

    return {
      locales,
      updateActiveLocale,
      availableLocales,
      activeRole,
    };
  },
};
</script>
<style lang="scss"></style>
