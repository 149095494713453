var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.text)?_c('div',{attrs:{"name":"snackbars"}},[_c('v-snackbar',{staticStyle:{"z-index":"7"},style:({
      top: _vm.overlayShown
        ? '0px'
        : _vm.$route.name === 'meine-bav'
        ? '98px'
        : _vm.$vuetify.breakpoint.smAndDown
        ? '62px'
        : '56px',
      zIndex: _vm.overlayShown ? '9000' : _vm.$vuetify.breakpoint.smAndDown ? '9000' : '7',
    }),attrs:{"color":_vm.color,"timeout":_vm.timeout,"top":'top',"fixed":"","content-class":"toast"},scopedSlots:_vm._u([(_vm.timeout === '-1')?{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-icon',_vm._b({staticClass:"mr-1",attrs:{"color":"white","size":"20"},on:{"click":function($event){_vm.show = false}}},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiCloseCircle)+" ")])]}}:null],null,true),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }