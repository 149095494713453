export const getDefaultState = () => {
  return {
    notificationText: '',
    notificationColor: '',
    notificationIimeout: '',
  };
};

const defaultState = getDefaultState();

export default defaultState;
