// eslint-disable-next-line object-curly-newline
import { mdiFileEditOutline } from '@mdi/js';

export default [
  {
    title: 'Companies',
    icon: mdiFileEditOutline,
    to: 'companies',
    action: 'read',
  },
];
