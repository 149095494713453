export const getDefaultState = () => {
  return {
    uploadQueue: [],
    uploadedData: [],
    currentStatus: 'IDLE',
    totalProgress: 0,
    failedUploads: [],
    cancelRequest: null,
  };
};
const defaultState = getDefaultState();
export default defaultState;
