import { cloneDeep } from 'lodash';

export default {
  setLimit({ commit, dispatch }, limit) {
    commit('SET_LIMIT', limit);
    dispatch('fetchAll');
  },

  search({ commit, dispatch }, { search }) {
    commit('SET_OFFSET', 0);
    commit('SET_SEARCH', search);
    commit('SET_CURRENT_PAGE_COMPANIES', 1);
    commit('SET_CURRENT_PAGE', 1);
    dispatch('fetchAll');
  },

  previousPage({ state, commit, dispatch }, page) {
    commit('SET_OFFSET', state.skip - state.limit);
    dispatch('fetchAll');
  },

  nextPage({ state, commit, dispatch }, page, fetch) {
    commit('SET_OFFSET', state.skip + state.limit);
    dispatch('fetchAll');
  },

  sort({ state, commit, dispatch }, field) {
    commit('SET_SORT', field);
    dispatch('fetchAll');
  },

  filterCompany({ state, commit, dispatch }, company) {
    const filters = cloneDeep(state.filters);

    if (filters.companyIds.includes(company)) {
      filters.companyIds = filters.companyIds.filter((c) => c !== company);
    } else {
      company ? filters.companyIds.push(company) : (filters.companyIds = []);
    }

    commit('SET_OFFSET', 0);
    commit('SET_CURRENT_PAGE', 1);
    commit('SET_FILTERS', filters);
    dispatch('fetchAll');
  },

  filterStatus({ state, commit, dispatch }, status) {
    const filters = cloneDeep(state.filters);

    if (filters.status === status) {
      status = null;
    }

    filters.status = status;
    commit('SET_FILTERS', filters);
    commit('SET_OFFSET', 0);
    commit('SET_CURRENT_PAGE', 1);
    dispatch('fetchAll');
  },
};
