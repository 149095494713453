<template>
  <v-bottom-sheet ref="cookie-sheet" v-model="sheet" hide-overlay persistent :value="show" :retain-focus="false">
    <div class="mx-auto mb-md-10" :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }">
      <v-container>
        <v-card dark color="#F3F3F3" class="elevation-5 mx-auto v-card--cc">
          <v-row class="ma-0 h-full">
            <v-col cols="12" sm="8" md="12" lg="12">
              <v-card-title class="font-weight-bold mt-2 text-center justify-center justify-lg-start">
                <v-icon size="30" color="primary" class="pr-1">
                  {{ icons.mdiCookie }}
                </v-icon>
                <span class="primary--text">{{ $t('cookieConsentManagement') }}</span>
              </v-card-title>
              <v-card-text class="pb-lg-0" :class="{ 'text-center': $vuetify.breakpoint.smAndDown }">
                <p class="pl-lg-5" style="color: #373844">
                  {{ $t('cookieConsentMessage') }}
                </p>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-row class="d-flex justify-end align-center mr-5 pr-lg-5">
                  <v-col cols="12" lg="3" md="6" class="order-last order-md-first d-flex justify-center align-center">
                    <a class="text-xs text-decoration-underline" target="_blank" @click="cookieSettingsModal = true">{{
                      $t('cookieConsentManage')
                    }}</a>
                  </v-col>
                  <v-col cols="12" lg="3" md="6" class="d-flex justify-center align-center">
                    <v-btn color="primary" class="text-xs" @click="ccAcceptAll">{{
                      $t('cookieConsentAcceptAll')
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <ModalWindow :is-open="cookieSettingsModal" :maxWidth="680" :title="$t('cookieConsentManagement')">
      <template #content>
        <CookieConsentManage
          @acceptedNecessary="ccUpdateNecessary($event)"
          @acceptedAnalytics="ccUpdateAnalytics($event)"
        />
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="ccAcceptAll">
          {{ $t('cookieConsentAcceptAll') }}
        </v-btn>
        <v-btn color="primary" @click="ccSavePrefs">
          {{ $t('formLabelSaveEdit') }}
        </v-btn>
      </template>
    </ModalWindow>
  </v-bottom-sheet>
</template>

<script>
import { ref, watch, getCurrentInstance } from '@vue/composition-api';
import { mdiCookie } from '@mdi/js';
import ModalWindow from '@/components/modal/ModalWindow';
import CookieConsentManage from '@/components/cookie-consent/CookieConsentManage.vue';

export default {
  components: { ModalWindow, CookieConsentManage },
  setup() {
    const vm = getCurrentInstance().proxy;
    const sheet = ref(!vm.$store.getters.necessaryCookies);
    const cookieSettingsModal = ref(false);
    const show = ref('');
    const acceptedNecessary = ref(false);
    const acceptedAnalytics = ref(false);

    const ccUpdateNecessary = ($event) => {
      acceptedNecessary.value = $event.value;
      if (!$event.value) {
        acceptedAnalytics.value = false;
      }
    };
    const ccUpdateAnalytics = ($event) => {
      acceptedAnalytics.value = $event.value;
      acceptedNecessary.value = true;
    };
    const ccSavePrefs = () => {
      cookieSettingsModal.value = false;
      if (!acceptedAnalytics.value && !acceptedNecessary.value) {
        vm.$cc.accept([]);
      } else {
        vm.$cc.accept(acceptedAnalytics.value === true ? 'all' : 'necessary');
      }
    };

    const ccAcceptAll = () => {
      vm.$cc.accept('all');
      cookieSettingsModal.value = false;
    };
    watch(
      () => vm.$store.getters.necessaryCookies,
      () => {
        if (vm.$store.getters.necessaryCookies) {
          sheet.value = false;
        } else {
          sheet.value = true;
        }
      },
    );

    watch(
      () => show,
      () => {
        vm.$nextTick(() => {
          this.$refs.cookiesheet.showScroll();
        });
      },
    );

    return {
      cookieSettingsModal,
      sheet,
      ccAcceptAll,
      ccSavePrefs,
      show,
      ccUpdateAnalytics,
      ccUpdateNecessary,

      icons: {
        mdiCookie,
      },
    };
  },
};
</script>
<style lang="scss">
.v-expansion-panel {
  position: relative;
}
.v-switch--custom {
  position: absolute;
  right: 40px;
  top: -7px;
  z-index: 10;
}
.shadow {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.v-bottom-sheet.v-dialog--animated {
  transition: none !important;
  animation: none !important;
}
.v-application.skin-variant--bordered .theme--dark.v-card--cc:not(.v-card .v-card) {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
.v-dialog.v-bottom-sheet {
  box-shadow: none !important;
}
</style>
