import { createMessenger } from '@userlike/messenger';
import Vue from 'vue';

export async function runUserlike() {
  if (!window.userlike) {
    const script = document.createElement('script');

    script.src =
      'https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/35dbb56f145d4f75bd06e7414cfb051b048b97c0cc7a404faaeb3b1683c43a2f.js';

    script.type = 'text/javascript';
    document.head.appendChild(script);

    script.onload = () => {
      function afterUserlike() {
        if (window.userlike) {
          Vue.prototype.$userlike = window.userlike;
          Vue.prototype.$userlike.userlikeHideButton();
        }
      }
      if (window.userlike) {
        afterUserlike();
      } else {
        Object.defineProperty(window, 'userlike', {
          configurable: true,
          enumerable: true,
          get() {
            return this._userlike;
          },
          set(val) {
            this._userlike = val;
            afterUserlike();
          },
        });
      }
    };
  }
}
