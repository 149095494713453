// Feature Flags
export const FF_MANAGING_CONTRACTS = /true/i.test(process.env.FF_MANAGING_CONTRACTS) || true;

export const envVars = () => {
  let apiBaseUrl = 'https://api.develop.degura.de/2.0';
  let assetsBaseUrl = 'https://assets.develop.degura.de';
  let currentEnv = 'dev';

  if (window.location.host === 'portal.degura.de') {
    apiBaseUrl = 'https://api.degura.de/2.0';
    assetsBaseUrl = 'https://assets.degura.de';
    currentEnv = 'prod';
  } else if (window.location.host === 'portal.staging.degura.de') {
    apiBaseUrl = 'https://api.staging.degura.de/2.0';
    assetsBaseUrl = 'https://assets.staging.degura.de';
    currentEnv = 'staging';
  }

  return {
    API_BASE_URL: apiBaseUrl,
    API_AUTHENTICATION_HEADER: 'X-Api-Key',
    ASSETS_BASE_URL: assetsBaseUrl,
    CURRENT_ENV: currentEnv,
  };
};

export const config = {
  variables: {
    API_BASE_URL: envVars().API_BASE_URL,
    ASSETS_BASE_URL: envVars().ASSETS_BASE_URL,
    CURRENT_ENV: envVars().CURRENT_ENV,
  },
  featureFlags: {
    twoFactorAuth: true,
    managingContracts: FF_MANAGING_CONTRACTS,
  },
  sentry: {
    config: {
      environment: envVars().CURRENT_ENV,
    },
    dsn: 'https://408cb1869f7545238416bda1dacbcb92@o1163979.ingest.sentry.io/4504434818023424',
    sampleRate: 1.0,
  },
};

export const cookieConsent = {
  // See: https://github.com/orestbida/cookieconsent#all-configuration-options
  current_lang: 'en',
  cookie_name: 'cc_cookie', // default: 'cc_cookie'
  cookie_expiration: 365, // default: 182
  force_consent: false, // default: false
  page_scripts: true,
  autorun: false,
  cookie_same_site: 'Lax',
  gui_options: {
    consent_modal: {
      layout: 'cloud', // box/cloud/bar
      position: 'bottom center', // bottom/middle/top + left/right/center
      transition: 'slide', // zoom/slide
      dark: true,
    },
    settings_modal: {
      layout: 'box', // box/bar
      transition: 'slide', // zoom/slide
    },
  },

  // We dont use this but its needed for running CC
  languages: {
    en: {
      consent_modal: {
        title: 'Cookies',
        description: '',
        primary_btn: {
          text: 'Accept',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject',
          role: 'settings', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie preferences',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept all',
        blocks: [
          {
            title: 'Cookie usage',
            description: '',
          },
          {
            title: 'Strictly necessary cookies',
            description: '',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: false,
            },
          },
          {
            title: 'Analytics cookies',
            description: '',
            toggle: {
              value: 'analytics',
              enabled: false,
              readonly: false,
            },
          },
          {
            description: '',
          },
        ],
      },
    },
  },
};
