<template>
  <layout-blank>
    <slot></slot>
  </layout-blank>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue';

const languageOptions = ['en', 'de'];

export default {
  components: {
    LayoutBlank,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentLang = ref('de');

    const changeLanguage = (newValue) => {
      vm.$i18n.locale = newValue === 'en' ? 'en' : 'de_form';
      vm.$store.commit('app/setCurrentLang', newValue);
      currentLang.value = newValue;
      document.title = vm.$t(vm.$route.meta.titleKey) || 'Degura';
    };
    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Deutsch',
        img: require('@/assets/images/flags/de.svg'),
        locale: 'de',
      },
    ];
    return {
      currentLang,
      changeLanguage,
      languageOptions,
      locales,
    };
  },
};
</script>

<style scoped></style>
