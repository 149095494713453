import axios from 'axios';
import Vue from 'vue';
import store from '../store';
import router from '../router';
import { config } from '../../appConfig';

const axiosIns = axios.create({
  baseURL: config.variables.API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosIns.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('accessToken');
          store.commit('resetAuth');
          store.commit('setLoginError', 'errorSessionExpiredMessage');
          router.push('/');
          break;
        case 403:
          store.commit('showMessage', {
            text: 'forbiddenPageTitle',
            color: 'error',
            timeout: '-1',
          });
          break;
        case 404:
          break;
        case 413:
          store.commit('showMessage', {
            text: 'Request failed with status code 413',
            color: 'error',
            timeout: '-1',
          });
          break;
        default:
          store.commit('showMessage', {
            text: `${error.response.data.detail[0]?.name ?? `Error:${error.response.status}`}`,
            color: 'error',
            timeout: '-1',
          });
      }
    }

    return Promise.reject(error);
  },
);

axiosIns.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers['x-api-key'] = accessToken;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
