import * as Sentry from '@sentry/vue';
import { getDefaultState } from './state';
import { ALL_ROLES, HR_ROLES, ROLE_ADMIN, ROLE_EMPLOYEE } from '@/constants';
import adminNav from '../../../navigation/horizontal/admin';
import employeeNav from '../../../navigation/horizontal/employee';
import globalAdminNav from '../../../navigation/horizontal/global-admin';
import { SET_PIWIK_ROLE_DIMENSION } from '@/plugins/piwik/dimensions';

export default {
  setCompanyLangForm(state, payload) {
    state.companyLangForm = payload;
  },
  setAccessToken(state, payload) {
    state.accessToken = payload;
    localStorage.setItem('accessToken', payload);
  },
  setUserData(state, payload) {
    state.userData = payload;
  },
  setCompanyData(state, payload) {
    state.companyData = payload;
  },
  setCompanyConfig(state, payload) {
    state.companyConfig = payload;
  },
  setActiveRole(state, newRole) {
    state.activeRole = newRole;

    if (state.activeRole === ROLE_ADMIN) {
      state.navigationMenu = globalAdminNav;
    } else {
      state.navigationMenu = HR_ROLES.includes(newRole) ? adminNav : employeeNav;
    }
  },
  resetAuth(state) {
    const initialState = getDefaultState();
    Object.keys(initialState).forEach((key) => {
      state[key] = initialState[key];
    });
  },
  setLoggedInStatus(state, status) {
    state.loggedIn = status;
  },
  setLoginError(state, message) {
    state.loginError = message;
  },
  setTwoFactorMethod(state, method) {
    state.twoFactorMethod = method;
  },
  setTwoFactorId(state, id) {
    state.twoFactorId = id;
  },
  setContract(state, payload) {
    state.contract = payload;
  },
  determineActiveRole(state, roles) {
    const SYSTEM_ROLES = {
      ALL: ALL_ROLES,
      HR: HR_ROLES,
    };
    // map the role names to match our uppercase constant format
    // and filter out any bad values that we do not support
    const _availableRoles = roles.map((r) => r.toUpperCase()).filter((r) => SYSTEM_ROLES.ALL.includes(r));

    // if the available roles contain a HR role, set it as activeRole
    if (_availableRoles.some((r) => SYSTEM_ROLES.HR.includes(r))) {
      state.activeRole = _availableRoles.find((r) => SYSTEM_ROLES.HR.includes(r));
      if (_availableRoles.includes(ROLE_EMPLOYEE)) {
        /**
         * Since all roles, but BAV_MANAGER are exclusively set,
         * we can be sure, that if that code is reached - it is a BAV_MANAGER
         */
        state.activeRole = ROLE_EMPLOYEE;
        state.canSwitchRole = true;
      }
    } else {
      // just take the first available role and set it as default
      if (_availableRoles.length) state.activeRole = _availableRoles[0];
      state.canSwitchRole = false;
    }

    Sentry.setTag('role', state.activeRole);
    SET_PIWIK_ROLE_DIMENSION(state.activeRole);

    // determine allowed navigation based on role

    if (state.activeRole === ROLE_ADMIN) {
      state.navigationMenu = globalAdminNav;
    } else {
      state.navigationMenu = HR_ROLES.includes(state.activeRole) ? adminNav : employeeNav;
    }
  },
};
