import { difference, union } from 'lodash';
import { getDefaultState } from './state';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const getSelected = (page) => page.slice().filter((e) => e.__selected && e.__selected === true);

export default {
  _ADD_PERMITTED_ROLES_TO_DOCUMENT(state, { id, roles }) {
    const page = state.page.slice();
    const index = page.findIndex((e) => e.id === id);
    page[index] = {
      ...page[index],
      // https://lodash.com/docs/4.17.15#union
      permittedRoles: union(page[index].permittedRoles, roles),
    };

    state.page = page;
  },

  _REMOVE_PERMITTED_ROLES_FROM_DOCUMENT(state, { id, roles }) {
    const page = state.page.slice();
    const index = page.findIndex((e) => e.id === id);
    page[index] = {
      ...page[index],
      // https://lodash.com/docs/4.17.15#difference
      permittedRoles: difference(page[index].permittedRoles, roles),
    };

    state.page = page;
  },

  changeCategory(state, row) {
    const copy = state.page.slice();
    copy[copy.findIndex((d) => d.id === row.id)].category = row.category;
    state.page = copy;
  },

  SET_INITIALIZED(state) {
    state.initialized = true;
  },

  __RESET(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },

  SET_LOADING(state, flag = true) {
    state.loading = flag;
  },

  SET_LOADED(state, flag = true) {
    state.loaded = flag;
  },

  SET_TYPE(state, type) {
    state.type = type;
  },

  SET_EMPLOYEE(state, employee) {
    state.employee = employee;
  },

  SET_DOCUMENTS(state, data) {
    state.skip = data.data.skip;
    state.limit = data.data.limit;
    state.total = data.data.total;
    state.page = data.data.page.slice().map((e) => ({
      ...e,
      __selected: false,
    }));
  },

  CHANGE_SORT_FIELD(state, field) {
    state.sortField = field;
  },

  CHANGE_SORT_DIRECTION(state, direction) {
    state.sortDirection = direction;
  },

  SET_SORT(state, field) {
    // if (![SORT_ASC, SORT_DESC].includes(direction))
    //   throw new Error("Wrong sorting direction")

    if (field === state.sortField) {
      state.sortDirection = state.sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC;
    } else {
      state.sortField = field;
      state.sortDirection = SORT_DESC;
    }
  },

  SET_STATUS(state, status) {
    state.status = status;
  },

  SET_OFFSET(state, offset) {
    state.skip = parseInt(offset);
  },

  SET_LIMIT(state, limit) {
    state.limit = parseInt(limit);
  },

  SWITCH_SELECT_ALL(state) {
    const newSelectedState = !(getSelected(state.page).length === state.page.length);
    state.page = state.page.slice().map((e) => {
      return { ...e, __selected: newSelectedState };
    });
  },

  SWITCH_SELECTED_DOCUMENT(state, id) {
    const copy = state.page.slice();
    const currentState = copy[copy.findIndex((d) => d.id === id)].__selected === true;
    copy[copy.findIndex((d) => d.id === id)].__selected = !currentState;
    state.page = copy;
  },

  SET_SEARCH(state, search) {
    state.search = search;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
};
