import { cookieConsent } from '../../appConfig';
import store from '@/store';

var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (const p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
      };
    return __assign.apply(this, arguments);
  };

const CookieConsentPlugin = {
  install(Vue, options) {
    const script = document.createElement('script');

    script.src = 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.0/dist/cookieconsent.js';
    script.type = 'application/javascript';
    document.head.appendChild(script);

    script.onload = () => {
      const cookieconsent = initCookieConsent();
      // merge default and client options
      options = { ...cookieConsent, ...options };
      // setup cookieconsent lib event handler
      // see: https://github.com/orestbida/cookieconsent/blob/master/Readme.md#available-callbacks

      options.onAccept = function () {
        const preferences = Vue.prototype.$cc.getUserPreferences().accepted_categories;

        const payload = preferences.includes('analytics')
          ? { necessary: true, analytics: true }
          : !preferences.includes('analytics') && preferences.includes('necessary')
          ? { necessary: true, analytics: false }
          : { necessary: false, analytics: false };

        store.dispatch('SET_COOKIE_PREFS', payload);
        store.dispatch('SET_COOKIE_PREFS', payload);
      };

      options.onChange = function () {
        const preferences = Vue.prototype.$cc.getUserPreferences().accepted_categories;

        const payload = preferences.includes('analytics')
          ? { necessary: true, analytics: true }
          : !preferences.includes('analytics') && preferences.includes('necessary')
          ? { necessary: true, analytics: false }
          : { necessary: false, analytics: false };

        store.dispatch('SET_COOKIE_PREFS', payload);
      };

      // The concept of curried functions, allows us to have
      // a named function with arguments AND later call `removeEventListener`.
      const curryFn = function (eventName, fn) {
        return function curriedFn() {
          fn();
        };
      };

      if (!window._cookieconsent) {
        Vue.prototype.$cc = cookieconsent;
        Vue.prototype.$cc.run(options);
      }

      Vue.mixin({
        unmounted() {
          window.removeEventListener('consentChanged', curryFn(''));
        },
      });
    };
  },
};

export default CookieConsentPlugin;
