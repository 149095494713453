<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      <div
        v-if="$route.name === 'meine-bav'"
        class="navbar-contract-status"
        :class="[
          { 'closed-status': contractStatus !== 'CONTRACT_ONBOARDED' },
          { 'declined-status': contractStatus === 'CONTRACT_OPTED_OUT' },
        ]"
      >
        {{ $t('bavStatus') }}:
        {{ $t(contractStatusTranslation) }}
      </div>
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center pl-12">
          <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
          <router-link to="/" class="d-flex align-center text-decoration-none">
            <!--<v-img-->
            <!--:src="appLogo"-->
            <!--max-height="120px"-->
            <!--max-width="120px"-->
            <!--alt="logo"-->
            <!--contain-->
            <!--class="me-3"-->
            <!--&gt;</v-img>-->
            <app-bar-company-logo></app-bar-company-logo>

            <!--- Admin Title -->
            <span v-if="isAdmin || isGlobalAdmin" class="pl-2 admin-header-title"> Administration </span>
          </router-link>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <!-- <v-badge
      content="!"
      overlap
        color="primary"
        class="v-badge--continue"
    >
      <v-btn
        outlined
         x-small
        color="primary"
      >
          Unverbindliches Angebot fortsetzen
      </v-btn>
    </v-badge> -->
        <div class="pl-11">
          <horizontal-nav-menu
            :nav-menu-items="navMenuItems"
            :class="{
              'horizontal-nav-menu-boxed mx-auto': appContentWidth === 'boxed',
            }"
          ></horizontal-nav-menu>
        </div>
        <div class="d-flex align-center pr-16">
          <app-bar-i18n></app-bar-i18n>
          <app-bar-admin-menu v-if="userCanSwitchRole" class="ms-2" />
          <!--<app-bar-notification />-->
          <div class="pr-7">
            <app-bar-user-menu class="ms-2"></app-bar-user-menu>
          </div>

          <!--<v-btn-->
          <!--color="primary"-->
          <!--depressed-->
          <!--dark-->
          <!--class="v-btn&#45;&#45;continue"-->
          <!--to="meine-bav"-->
          <!--v-if="contractStatus === 'CONTRACT_ONBOARDED' && activeRole == 'ROLE_EMPLOYEE' && $route.path !== '/meine-bav'"-->
          <!--&gt;-->

          <!--<v-icon-->
          <!--color="#06FCCD"-->
          <!--left-->
          <!--small-->
          <!--&gt;-->
          <!--{{ icons.mdiSend }}-->
          <!--</v-icon>-->
          <!--{{$t('stepperButtonContinue4')}}-->
          <!--<span></span>-->
          <!--</v-btn>-->
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>

    <!-- Slot: footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="d-flex align-items-center flex-grow-0 flex-shrink-0">
          <span class="text-xs pr-2 font-weight-semi-bold">Powered by</span>
          <v-img :src="appLogo" max-width="195px" alt="logo" contain class="mr-2"></v-img><span class="text-xs">|</span>
          <span class="text-xs ml-2 pr-2">{{ $t('copyright') }}</span>
        </div>
        <div>
          <div class="d-flex align-center">
            <a class="text-xs text-decoration-underline" href="https://www.degura.de/de/impressum/" target="_blank">{{
              $t('footerLink1')
            }}</a>
            <a
              class="text-xs text-decoration-underline"
              href="./assets/datenschutzhinweise_degura_portal.pdf"
              target="_blank"
              >{{ $t('linkPrivacy') }}</a
            >
          </div>
        </div>
      </div>
    </template>

    <!-- App Content -->
    <template #v-app-content>
      <div class="background"></div>
    </template>
    <SupportBubble v-if="consultant !== null && $route.path !== '/faq'" />
  </layout-content-horizontal-nav>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig';
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue';
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue';
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue';
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue';
import { ref, watch, getCurrentInstance, computed, onMounted, onBeforeMount } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { mdiHeartOutline, mdiVuetify, mdiSend } from '@mdi/js';
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNavCore.vue';
import Footer from '@/components/footer/Footer.vue';
import SupportBubble from '@/components/support-bubble/SupportBubble.vue';
// App Bar Components
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18n.vue';
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue';
import AppBarCompanyLogo from '@/layouts/components/app-bar/AppBarCompanyLogo.vue';
import HorizontalNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue';
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data';
import appBarAdminMenu from '@/layouts/components/app-bar/AppBarAdminMenu.vue';

import { HR_ROLES, ROLE_ADMIN, CONTRACT_TRANSLATION_MAP } from '../../../../constants';

export default {
  components: {
    LayoutContentHorizontalNav,
    AppCustomizer,

    // App Bar Components
    AppBarSearch,
    HorizontalNavMenu,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
    AppBarCompanyLogo,
    Footer,
    appBarAdminMenu,
    SupportBubble,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const navMenuItems = computed(() => vm.$store.getters.navigationMenu);
    const isAdmin = computed(() => HR_ROLES.includes(vm.$store.getters.activeRole));
    const isGlobalAdmin = computed(() => vm.$store.getters.activeRole === ROLE_ADMIN);
    const userCanSwitchRole = computed(() => vm.$store.getters.canSwitchRole);
    const activeRole = computed(() => vm.$store.getters.activeRole);
    const contractStatus = computed(() => {
      return vm.$store.state.auth.contract.contractStatus;
    });
    const contractStatusTranslation = computed(() => {
      return CONTRACT_TRANSLATION_MAP[vm.$store.state.auth.contract.contractStatus];
    });
    // Search
    const appBarSearchQuery = ref('');
    const shallShowFullSearch = ref(false);
    const maxItemsInGroup = 5;
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    });
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      };
    });

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true;

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages';
        if (item.size !== undefined) return 'files';
        if (item.email !== undefined) return 'contacts';

        return null;
      })();

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1;
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1;
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1;
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup;
    };
    const consultant = ref(vm.$store.getters.consultant);
    onBeforeMount(() => {
      vm.$store.dispatch('GET_CONSULTANT');
      if (isGlobalAdmin.value) {
        vm.$i18n.locale = 'en';
      }
    });
    watch(
      () => vm.$store.getters.consultant,
      () => {
        consultant.value = vm.$store.getters.consultant;
      },
    );

    const { appContentWidth } = useAppConfig();

    return {
      navMenuItems,
      isAdmin,
      isGlobalAdmin,
      userCanSwitchRole,
      contractStatus,
      contractStatusTranslation,
      activeRole,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appContentWidth,
      consultant,
      // Icons
      icons: {
        mdiHeartOutline,
        mdiVuetify,
        mdiSend,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar {
  &-contract-status {
    position: fixed;
    max-width: 100vw !important;
    width: 100vw;
    height: 42px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 50;
  }
}
.closed-status {
  background-color: #37b59d;
}

.declined-status {
  background-color: #f98506;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.admin-header-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #0395f9 !important;
}

.v-btn--continue {
  position: absolute;
  right: 9rem;
  top: 64px;
  height: 38px !important;
  width: 375px !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  text-transform: none !important;
  color: #06fccd !important;

  span > span {
    display: block !important;
  }
}
</style>
