const adminRoutes = [
  {
    path: '/mitarbeiter',
    name: 'mitarbeiter',
    component: () => import('@/views/admin/AdminDashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      titleKey: 'adminEmployeesPageTitle',
    },
  },
  {
    path: '/dokumente',
    name: 'dokumente',
    component: () => import('@/views/admin/AdminDocuments.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      titleKey: 'adminCompaniesPageTitle',
    },
  },
  {
    path: '/shared-documents',
    name: 'shared-documents',
    component: () => import('@/views/admin/AdminSharedDocuments.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
      titleKey: 'pageTitleMyDocuments',
    },
  },
];

export default adminRoutes;
