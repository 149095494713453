export default {
  accessToken: (state) => state.accessToken,
  loggedIn: (state) => state.loggedIn,
  userData: (state) => state.userData,
  activeRole: (state) => state.activeRole,
  loginError: (state) => state.loginError,
  twoFactorId: (state) => state.twoFactorId,
  twoFactorMethod: (state) => state.twoFactorMethod,
  navigationMenu: (state) => state.navigationMenu,
  canSwitchRole: (state) => state.canSwitchRole,
  companyLangForm: (state) => state.companyLangForm,
};
