import { changePreferredLang } from '../../api/fusion-auth';

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    currentLang: 'de_form',
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay;
    },
    setCurrentLang(state, payload) {
      state.currentLang = payload.langCode;
      payload.rootState.auth.userData.preferredLanguages = [`${payload.langCode.substring(0, 2).toLowerCase()}`];
    },
  },
  actions: {
    CHANGE_PREFERRED_LANGUAGE({ state, commit, rootState }, payload) {
      changePreferredLang(payload.userId, payload.langCode);
      commit('setCurrentLang', { langCode: payload.langCode, rootState });
    },
  },
};
