export const STATUS_ACTIVE = 'ACTIVE';
export const STATUS_DELETED = 'DELETED';

export const getDefaultState = () => {
  return {
    initialized: false,
    type: null,
    employee: null,
    loading: false,
    loaded: false,
    status: STATUS_ACTIVE,
    page: [],
    skip: 0,
    total: 0,
    limit: 20,
    sortField: 'createdOn',
    sortDirection: 'DESC',
    search: '',
    filters: {
      companyIds: [],
      category: null,
    },
  };
};

const defaultState = getDefaultState();

export default defaultState;
