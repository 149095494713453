<template :key="rerenderKey">
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <slot></slot>
    <div
      v-if="$route.name === 'meine-bav'"
      class="navbar-contract-status"
      :class="[
        { 'closed-status': contractStatus !== 'CONTRACT_ONBOARDED' },
        { 'declined-status': contractStatus === 'CONTRACT_OPTED_OUT' },
      ]"
    >
      {{ $t('bavStatus') }}:
      {{ $t(contractStatusTranslation) }}
    </div>

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container" :class="{ 'expanded-search': shallShowFullSearch }">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            color="primary"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>
        <div class="mr-0">
          <app-bar-company-logo></app-bar-company-logo>
        </div>
        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <!--Admin menu-->
          <!--<app-bar-admin-menu class="ms-2" v-if="userCanSwitchRole"></app-bar-admin-menu>-->
          <app-bar-user-menu></app-bar-user-menu>

          <!--<v-btn-->
          <!--color="primary"-->
          <!--depressed-->
          <!--dark-->
          <!--class="v-btn&#45;&#45;continue-mobile"-->
          <!--to="meine-bav"-->
          <!--v-if="contractStatus === 'CONTRACT_ONBOARDED' && activeRole == 'ROLE_EMPLOYEE' && $route.name !== 'meine-bav'"-->
          <!--&gt;-->

          <!--<v-icon-->
          <!--color="#06FCCD"-->
          <!--left-->
          <!--small-->
          <!--&gt;-->
          <!--{{ icons.mdiSend }}-->
          <!--</v-icon>-->
          <!--{{$t('stepperButtonContinue4')}}-->
          <!--<span></span>-->
          <!--</v-btn>-->
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-items-center">
          <span class="d-none d-md-inline-block text-xs pr-2 font-weight-semi-bold">Powered by</span>
          <v-img :src="appLogo" max-width="75px" alt="logo" contain class="mr-2"></v-img
          ><span class="text-xs d-none d-md-inline-block">|</span>
          <span class="text-xs ml-2 pr-2 d-none d-md-inline-block">{{ $t('copyright') }}</span>
        </div>

        <div>
          <a class="text-xs text-decoration-underline" href="https://www.degura.de/de/impressum/" target="_blank">{{
            $t('footerLink1')
          }}</a>
          <a
            class="text-xs text-decoration-underline"
            href="./assets/datenschutzhinweise_degura_portal.pdf"
            target="_blank"
            >{{ $t('linkPrivacy') }}</a
          >
        </div>
      </div>

      <span class="text-xs pr-2 d-md-none">{{ $t('copyright') }}</span>
    </template>
    <SupportBubble v-if="consultant !== null && $route.path !== '/faq'" />
    <template #v-app-content>
      <div class="background"></div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue';

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue';
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue';
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue';
import { mdiMenu, mdiHeartOutline, mdiSend } from '@mdi/js';
import { getVuetify } from '@core/utils';
import { ref, watch, getCurrentInstance, onBeforeMount, computed } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18n.vue';
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue';
import AppBarCompanyLogo from '@/layouts/components/app-bar/AppBarCompanyLogo.vue';
import appBarAdminMenu from '@/layouts/components/app-bar/AppBarAdminMenu.vue';
import SupportBubble from '@/components/support-bubble/SupportBubble.vue';
// Search Data
import appBarSearchData from '@/assets/app-bar-search-data';

import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNavCore.vue';
import { CONTRACT_TRANSLATION_MAP } from '../../../../constants';

export default {
  components: {
    LayoutContentVerticalNav,
    AppCustomizer,

    // App Bar Components
    AppBarSearch,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    AppBarNotification,
    AppBarCompanyLogo,
    appBarAdminMenu,
    SupportBubble,
  },
  setup() {
    const $vuetify = getVuetify();
    const vm = getCurrentInstance().proxy;
    const rerenderKey = ref(0);
    const navMenuItems = computed(() => vm.$store.getters.navigationMenu);
    const userCanSwitchRole = computed(() => vm.$store.getters.canSwitchRole);
    const activeRole = computed(() => vm.$store.getters.activeRole);
    const contractStatus = computed(() => {
      return vm.$store.state.auth.contract.contractStatus;
    });
    const contractStatusTranslation = computed(() => {
      return CONTRACT_TRANSLATION_MAP[vm.$store.state.auth.contract.contractStatus];
    });

    // Search
    const appBarSearchQuery = ref('');
    const shallShowFullSearch = ref(false);
    const maxItemsInGroup = 5;
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    });
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      };
    });
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true;

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages';
        if (item.size !== undefined) return 'files';
        if (item.email !== undefined) return 'contacts';

        return null;
      })();

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1;
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1;
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1;
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup;
    };

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive();
      }
    };
    const consultant = ref(vm.$store.getters.consultant);
    onBeforeMount(() => {
      vm.$store.dispatch('GET_CONSULTANT');
    });
    watch(
      () => vm.$store.getters.consultant,
      () => {
        consultant.value = vm.$store.getters.consultant;
      },
    );
    return {
      rerenderKey,
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      contractStatusTranslation,
      appBarSearchData,
      searchFilterFunc,
      consultant,
      userCanSwitchRole,
      contractStatus,
      mdiSend,
      activeRole,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiSend,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  &-contract-status {
    position: fixed;
    max-width: 100vw !important;
    width: 100vw;
    height: 35px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 50;
  }
}
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

.closed-status {
  background-color: #37b59d;
}
.declined-status {
  background-color: #f98506;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

.v-btn--continue-mobile {
  position: fixed;
  right: 0;
  top: 54px;
  border-radius: 0 !important;
  height: 38px !important;
  width: 100% !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  text-transform: none !important;
  color: #06fccd !important;

  span > span {
    display: block !important;
  }
}
</style>
