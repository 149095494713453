// eslint-disable-next-line object-curly-newline
import { mdiFileDocumentEdit } from '@mdi/js';

export default [
  {
    title: 'menuMyBav',
    icon: mdiFileDocumentEdit,
    to: 'meine-bav',
    action: 'read',
  },
];
