<template>
  <div class="icon-admin">
    <v-fade-transition mode="out-in">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon class="mx-2" v-bind="attrs" @click="switchRole" v-on="on">
            <v-icon class="mx-0" color="#059180" size="26">
              {{ icons.mdiAccountSwitch }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ isAdmin ? $t('linkHeaderSwitchRoleToEmployee') : $t('linkHeaderSwitchRoleToHR') }}</span>
      </v-tooltip>
    </v-fade-transition>
  </div>
</template>

<script>
import { getCurrentInstance, computed } from '@vue/composition-api';
import { mdiAccount, mdiAccountCog, mdiAccountSwitch } from '@mdi/js';
import { HR_ROLES } from '../../../constants';

export default {
  setup() {
    const vm = getCurrentInstance().proxy;
    const isAdmin = computed(() => HR_ROLES.includes(vm.$store.getters.activeRole));

    const switchRole = () => {
      window.open('https://pensioncloud.degura.de');
    };

    return {
      isAdmin,
      switchRole,

      icons: {
        mdiAccount,
        mdiAccountCog,
        mdiAccountSwitch,
      },
    };
  },
};
</script>
<style>
.icon-admin {
  margin-right: -7px;
}
</style>
