export const getDefaultState = () => {
  return {
    companyLangForm: 'de_form',
    loggedIn: false,
    userData: null,
    navigationMenu: [],
    canSwitchRole: false,
    companyData: null,
    companyConfig: null,
    accessToken: null,
    _unimpersonatedUser: null,
    twoFactorId: null,
    twoFactorMethod: null,
    loginError: null,
    showStickyCta: false,
    contract: {},
    contractLoading: false,
    hasTriedLoadingContract: false,
    activeRole: null,
    changePasswordOldPassword: '',
    changePasswordToken: '',
    changePasswordRequiresTerms: false,
    changePasswordAuthenticationType: 'TOKEN',
    changePasswordEmployeeId: null,
    confirmModal: {
      icon: null,
      title: '',
      message: '',
      open: false,
      onConfirmed: () => {},
      onDeclined: () => {},
    },
    dirtyFormConfirmModal: {
      diff: [],
      open: false,
      onConfirmed: () => {},
      onDeclined: () => {},
    },

    acceptPrivacyUser: null,
    acceptPrivacyAuthToken: null,
    fromWelcomePage: null,
  };
};

const defaultState = getDefaultState();

export default defaultState;
