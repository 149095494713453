import { getDefaultState } from './state';

const states = {
  IDLE: 'Idle',
  IN_PROGRESS: 'In Progress',
  SUCCEEDED: 'Successful',
  CANCELLED: 'Cancelled',
};

export default {
  reset(state) {
    const initialState = getDefaultState();
    Object.keys(initialState).forEach((key) => {
      state[key] = initialState[key];
    });
    updateStatus(state, states.IDLE);
  },
  addRequest(state, payload) {
    state.cancelRequest = payload;
    state.totalProgress = 0;
    updateStatus(state, states.IN_PROGRESS);
  },
  cancelRequest(state) {
    if (state.cancelRequest) {
      state.cancelRequest.abort();
    }
    state.totalProgress = 0;
    state.cancelRequest = null;
    updateStatus(state, states.CANCELLED);
  },
  removeItem(state, payload) {
    const index = state.uploadedData.findIndex((s) => s.id === payload);
    if (index > -1) {
      state.uploadedData.splice(index, 1);
    }
  },
  updateUploadState(state, payload) {
    if (payload.uploadedData) {
      state.uploadedData.push(payload.uploadedData);
    }
    if (payload.cancelRequest === null) {
      state.cancelRequest = payload.cancelRequest;
      updateStatus(state, states.CANCELLED);
    }
    if (payload.failedUploads) {
      state.failedUploads = payload.failedUploads;
    }
    if (payload.uploadQueue) {
      state.uploadQueue = payload.uploadQueue;
    }
    if (payload.totalProgress) {
      state.totalProgress = payload.totalProgress;
    }
    if (payload.totalProgress == 100) {
      state.totalProgress = payload.totalProgress;
      updateStatus(state, states.SUCCEEDED);
    }
  },
};
function updateStatus(state, msg) {
  state.currentStatus = msg;
}
