import { SORT_ASC, SORT_DESC } from '@/constants';
import { getDefaultState } from './state';

export default {
  __RESET(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },

  SET_INITIALIZED(state) {
    state.initialized = true;
  },

  SET_LOADING(state, flag = true) {
    state.loading = flag;
  },

  SET_ERRORS(state, flag = false) {
    state.errors = flag;
  },

  SET_INFINITE_LOADING(state, flag = true) {
    state.infiniteLoading = flag;
  },

  SET_EMPLOYEES(state, data) {
    state.skip = data.skip;
    state.limit = data.limit;
    state.total = data.total;
    state.page = data.page;
    state.lastPage = data.lastPage;
    state.totalPages = data.totalPages;
    state.currentPage = data.currentPage;
    state.currentPageCompanies = data.currentPageCompanies;
    state.pagination = data.pagination;
    state.totalPagesComputed = data.totalPagesComputed;
  },

  SET_SORT(state, field) {
    // if (![SORT_ASC, SORT_DESC].includes(direction))
    //   throw new Error("Wrong sorting direction")

    if (field === state.sortField) {
      state.sortDirection = state.sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC;
    } else {
      state.sortField = field;
      state.sortDirection = SORT_DESC;
    }
  },

  SET_OFFSET(state, offset) {
    state.skip = parseInt(offset);
  },

  SET_CURRENT_PAGE(state, page) {
    state.currentPage = parseInt(page);
  },

  SET_CURRENT_PAGE_COMPANIES(state, page) {
    state.currentPageCompanies = parseInt(page);
  },

  SET_LIMIT(state, limit) {
    if (limit === 'all') {
      state.limit = 50;
      state.skip = 0;
      state.showAll = true;
      state.page = [];
    } else {
      state.limit = parseInt(limit);
      state.skip = 0;
      state.showAll = false;
      state.currentPage = 1;
    }
  },

  SWITCH_SELECTED_EMPLOYEE(state, id) {
    const copy = state.page.slice();
    const currentState = copy[copy.findIndex((d) => d.id === id)].__selected === true;
    copy[copy.findIndex((d) => d.id === id)].__selected = !currentState;
    state.page = copy;
  },

  SET_SEARCH(state, search) {
    state.search = search;
  },

  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
};
