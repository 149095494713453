// eslint-disable-next-line object-curly-newline
import { mdiFileDocumentMultipleOutline } from '@mdi/js';

export default [
  {
    title: 'sharedDocumentsPageNavTitle',
    icon: mdiFileDocumentMultipleOutline,
    to: 'shared-documents',
    action: 'read',
  },
];
