<template>
  <div class="wrapper" :class="{ 'wrapper-hidden': !bubbleState.opened }">
    <v-card
      v-click-outside="onClickOutside"
      tile
      :class="{
        'bubble-hidden': !bubbleState.opened,
        'elevation-9': bubbleState.opened,
      }"
      class="bubble-card v-card--no-border"
    >
      <v-card-text
        class="position-relative d-flex flex-row align-center pa-0"
        :class="{ 'elevation-8 pa-5': bubbleState.opened }"
      >
        <v-avatar
          outlined
          size="72"
          class="mr-4 v-avatar_outer primary--text"
          :class="{
            'bubble-hidden': bubbleState.opened,
          }"
        >
          <v-avatar
            color="white"
            size="70"
            :class="{
              'bubble-hidden': bubbleState.opened,
            }"
          >
            <v-avatar size="60" class="primary" :loading="true" @click="toggleOpened" @mouseenter="toggleOpened">
              <v-img :src="consultant.profilePicture" @load="loaded = true"></v-img>
              <v-overlay :value="!loaded" class="v-overlay--absolute v-overlay--support" primary>
                <v-progress-circular v-if="!loaded" indeterminate size="60" color="primary"></v-progress-circular>
              </v-overlay>
            </v-avatar>
          </v-avatar>
        </v-avatar>

        <div class="mr-3 ml-1" :class="{ 'bubble-content-hidden elevetion-11': !bubbleState.opened }">
          <v-card-title class="pt-0 px-0 text-base primary--text font-weight-bold">
            <span class="primary--text">
              {{ consultant.firstName }}
              {{ consultant.lastName }}
            </span>
          </v-card-title>

          <v-card-subtitle class="text-xs pa-0">
            {{ $t(consultant['headlineTranslationKey']) }}<br />
            {{ $t('PHONE') }}: {{ consultant.phoneNumber }}
          </v-card-subtitle>
        </div>
        <v-tooltip v-if="bubbleState.opened" top open-delay="700">
          <template #activator="{ on, chatAttrs }">
            <v-btn
              fab
              dark
              color="primary"
              :disabled="!acceptedNecessary"
              x-small
              v-bind="chatAttrs"
              @click="bookingBtnPressed"
              v-on="on"
            >
              <v-icon size="24">
                {{ icons.mdiChat }}
              </v-icon>
            </v-btn>
          </template>
          <span> {{ $t('buttonStartChat') }}</span>
        </v-tooltip>
        <v-tooltip v-if="bubbleState.opened" top open-delay="700">
          <template #activator="{ on, videoAttrs }">
            <v-btn
              fab
              dark
              color="primary"
              x-small
              :disabled="!acceptedNecessary"
              class="mr-2 ml-2"
              v-bind="videoAttrs"
              @click="videoBtnPressed"
              v-on="on"
            >
              <v-icon size="24">
                {{ icons.mdiVideoOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span> {{ $t('formLabelVideoCall') }}</span>
        </v-tooltip>
      </v-card-text>
    </v-card>
    <ModalWindow :is-open="videoBookingModal" :title="$t('formLabelVideoCall')" :maxWidth="800">
      <template #content>
        <div class="iframe-container d-flex flex-row align-items-center" style="height: 100%">
          <iframe
            v-if="videoBookingModal"
            :src="
              currentLang.startsWith('de')
                ? `${consultant.appointmentSchedulingLink}&lang=de_CH`
                : `${consultant.appointmentSchedulingLink}&lang=en_US`
            "
            style="border: 0; width: 100%"
            scrolling="yes"
          ></iframe>
        </div>
      </template>
      <template #actions>
        <v-btn outlined block color="primary" class="ml-0 mt-3" @click="cancelBtnPressed">
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>
<style lang="scss">
.wrapper {
  position: fixed;
  bottom: 70px;
  left: auto;
  right: 0;
  transform: translateX(0%);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  z-index: 99;
}
.wrapper-hidden {
  transform: translateX(60%);
  bottom: 90px;
}
.avatar-center {
  cursor: pointer;
}

.bubble-card {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  .v-image__image {
    background-color: white;
  }
  .v-avatar {
    cursor: pointer;
    border: 3px solid;
  }
  .v-avatar_outer {
    cursor: pointer;
    border: 1px solid;
  }
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
  //  transition: all ease-in-out .3s;
}
.bubble-hidden {
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;
  overflow: hidden;
}
.bubble-content-hidden {
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: all;
}
.iframe-container {
  height: 100%;
  iframe {
    min-height: calc(100vh - 340px);
  }
}
.v-overlay--support {
  .v-overlay__scrim {
    background-color: white !important;
  }
}
</style>
<script>
import { reactive, computed } from 'vue-demi';
import { getCurrentInstance, ref, watch } from '@vue/composition-api';
import { mdiChat, mdiVideoOutline } from '@mdi/js';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  name: 'SupportBubble',
  components: { ModalWindow },
  props: {
    floating: Boolean,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);

    const bubbleState = reactive({
      opened: false,
    });
    const toggleOpened = () => {
      bubbleState.opened = !bubbleState.opened;
    };
    const onClickOutside = () => {
      bubbleState.opened = false;
    };
    const videoBtnPressed = () => {
      bubbleState.opened = false;
      setTimeout(() => {
        videoBookingModal.value = true;
      }, '250');
    };
    const bookingBtnPressed = () => {
      bubbleState.opened = false;
      setTimeout(() => {
        vm.$userlike.userlikeStartChat();
      }, '250');
    };
    const cancelBtnPressed = () => {
      videoBookingModal.value = false;
      vm.$store.commit('bookConsultation/setVideoBooking', videoBookingModal.value);
    };
    const videoBookingModal = ref(vm.$store.state.bookConsultation.videoBooking);
    const consultant = ref(vm.$store.getters.consultant);
    const fetchConsultant = () => {};
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const loaded = ref(false);

    watch(
      () => vm.$store.getters.consultant,

      () => {
        consultant.value = vm.$store.getters.consultant;
      },
    );
    watch(
      () => vm.$store.state.bookConsultation.videoBooking,

      () => {
        videoBookingModal.value = vm.$store.state.bookConsultation.videoBooking;
      },
    );
    watch(
      () => vm.$store.getters.necessaryCookies,

      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedNecessary.value = true;
        } else {
          acceptedNecessary.value = false;
        }
      },
    );
    return {
      bookingBtnPressed,
      videoBtnPressed,
      cancelBtnPressed,
      acceptedNecessary,
      bubbleState,
      toggleOpened,
      onClickOutside,
      videoBookingModal,
      fetchConsultant,
      consultant,
      currentLang,
      loaded,
      icons: {
        mdiVideoOutline,
        mdiChat,
      },
    };
  },
};
</script>
