<template>
  <div>
    <v-card-text class="text-base">
      <v-row>
        <v-col sm="8" md="12" lg="12">
          <p class="mt-lg-4" :class="{ 'text-sm mb-1': $vuetify.breakpoint.smAndDown }">
            {{ $t('cookieConsentDescription') }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="px-0 px-sm-5">
      <v-row>
        <v-col cols="12" sm="8" md="7">
          <!-- <p class="font-weight-bold mb-2">{{ $t("cookieConsentUse") }}</p> -->

          <v-expansion-panels accordion multiple focusable>
            <v-expansion-panel :class="{ 'v-expansion-panel--custom': $vuetify.breakpoint.smAndDown }">
              <v-switch
                v-model="acceptedNecessary"
                inset
                input-value="true"
                class="v-switch--custom"
                @click="updateNecessary"
              >
              </v-switch>
              <v-expansion-panel-header class="primary--text">
                {{ $t('cookieConsentStrict') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ $t('cookieConsentNecessary') }}
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel :class="{ 'v-expansion-panel--custom': $vuetify.breakpoint.smAndDown }">
              <v-switch
                v-model="acceptedAnalytics"
                inset
                input-value="true"
                class="v-switch--custom"
                @click="updateAnalytics"
              >
              </v-switch>
              <v-expansion-panel-header class="primary--text">
                {{ $t('cookieConsentAnalytics') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ $t('cookieConsentAnalyticsDesc') }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiCookie } from '@mdi/js';
import { ref, getCurrentInstance, watch } from '@vue/composition-api';

export default {
  components: {},
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const acceptedNecessary = ref(vm.$store.getters.necessaryCookies);
    const acceptedAnalytics = ref(vm.$store.getters.analyticsCookies);
    const updateNecessary = () => {
      vm.$emit('acceptedNecessary', {
        value: acceptedNecessary.value,
        fromGetters: false,
      });
      !acceptedNecessary.value ? (acceptedAnalytics.value = false) : (acceptedAnalytics.value = false);
    };
    const updateAnalytics = () => {
      vm.$emit('acceptedAnalytics', {
        value: acceptedAnalytics.value,
        fromGetters: false,
      });
      acceptedAnalytics.value ? (acceptedNecessary.value = true) : '';
    };
    watch(
      () => vm.$store.getters.necessaryCookies,
      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedNecessary.value = true;
          vm.$emit('acceptedNecessary', { value: true, fromGetters: true });
        } else {
          acceptedNecessary.value = false;
          vm.$emit('acceptedNecessary', { value: false, fromGetters: true });
        }
      },
    );

    watch(
      () => vm.$store.getters.analyticsCookies,
      () => {
        if (vm.$store.getters.analyticsCookies) {
          acceptedAnalytics.value = true;
          vm.$emit('acceptedAnalytics', { value: true, fromGetters: true });
        } else {
          acceptedAnalytics.value = false;
          vm.$emit('acceptedAnalytics', { value: false, fromGetters: true });
          vm.$userlike.userlikeQuitChat();
        }
      },
    );
    return {
      acceptedNecessary,
      acceptedAnalytics,
      updateNecessary,
      updateAnalytics,

      icons: {
        mdiCookie,
      },
    };
  },
};
</script>
