<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--bordered`">
    <ToastNotification />

    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view :key="$route.path"></router-view>
    </transition>
    <CookieConsent />
  </component>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig';
import { useRouter } from '@core/utils';
import { useLayout } from '@core/layouts/composable/useLayout';

// Layouts
import useDynamicVh from '@core/utils/useDynamicVh';
import { ref, getCurrentInstance, watch, computed } from '@vue/composition-api';
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue';
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue';
import ToastNotification from '@/components/notification/ToastNotification';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';
import SupportBubble from '@/components/support-bubble/SupportBubble.vue';

// Dynamic vh

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    ToastNotification,
    CookieConsent,
    SupportBubble,
  },
  setup() {
    const { route } = useRouter();
    const vm = getCurrentInstance().proxy;
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig();

    const { handleBreakpointLayoutSwitch } = useLayout();
    handleBreakpointLayoutSwitch();
    const activeRole = ref(vm.$store.getters.activeRole);
    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank';
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`;

      return null;
    });

    useDynamicVh();

    watch(
      () => vm.$store.getters.activeRole,
      () => {
        activeRole.value = vm.$store.getters.activeRole;
      },
    );

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      activeRole,
      route,
    };
  },
};
</script>
