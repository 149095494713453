import Vue from 'vue';
import { runUserlike } from '@/plugins/userlike';

export const SET_COOKIE_PREFS = 'SET_COOKIE_PREFS';
export default {
  [SET_COOKIE_PREFS]({ commit }, payload) {
    commit('setCookiePrefs', payload);
    if (payload.necessary) {
      runUserlike().catch((error) => {});
    }
  },
};
