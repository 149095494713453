<template>
  <footer class="v-footer v-sheet theme--light v-footer--padless v-footer--inset transparent ma-0" data-booted="true">
    <div class="col col-12">
      <div class="d-flex" :class="[{ 'justify-space-between': withSpace }, { 'justify-end': !withSpace }]">
        <span class="text-xs pr-2" :class="{ 'flex-grow-1 flex-shrink-1': withSpace }">
          {{ $t('copyright') }}
        </span>
        <div class="d-flex align-center flex-shrink-0">
          <a
            class="text-xs text-decoration-underline"
            href="./assets/datenschutzhinweise_degura_portal.pdf"
            target="_blank"
            >{{ $t('linkPrivacy') }}</a
          >
          <a class="text-xs text-decoration-underline" href="https://www.degura.de/de/impressum/" target="_blank">{{
            $t('footerLink1')
          }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    withSpace: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
