import { cloneDeep } from 'lodash';
import { archiveDocument, changeDocumentCategory, deleteDocument } from '@/api/document';
import axios from '@/plugins/axios';

import { ROLE_BAV_MANAGER, ROLE_ORG_ADMIN } from '@/constants';

export const TYPE_COMPANY = 'COMPANY';
export const TYPE_EMPLOYEE = 'EMPLOYEE';

export const DELETE_DOCUMENT_ACTION = 'DELETE_DOCUMENT';
export const ARCHIVE_DOCUMENT_ACTION = 'ARCHIVE_DOCUMENT';
export const DELETE_SELECTED_DOCUMENTS_ACTION = 'DELETE_SELECTED_DOCUMENTS';

export const GRANT_DOCUMENT_ACCESS_TO_HR_ACTION = 'GRANT_DOCUMENT_ACCESS_TO_HR_ACTION';
export const REVOKE_DOCUMENT_ACCESS_TO_HR_ACTION = 'REVOKE_DOCUMENT_ACCESS_TO_HR_ACTION';

export default {
  changeCategory({ commit, dispatch, rootState }, row) {
    return new Promise(async (resolve, reject) => {
      await changeDocumentCategory(row.id, rootState.auth.activeRole, row.category);
      commit('changeCategory', row);

      // dispatch("notifications/new", {
      //     title: this.app.i18n.t("successHeadline"),
      //     level: "success",
      //     message: this.app.i18n.t("successDocumentCategoryChangeMessage"),
      // }, { root: true })

      resolve();
    });
  },

  setLimit({ commit, dispatch }, limit) {
    commit('SET_LIMIT', limit);
    dispatch('fetchAll');
  },

  setStatus({ commit, dispatch }, status) {
    commit('SET_OFFSET', 0);
    commit('SET_STATUS', status);
    dispatch('fetchAll');
  },

  search({ commit, dispatch }, search) {
    commit('SET_OFFSET', 0);
    commit('SET_SEARCH', search);
    dispatch('fetchAll');
  },

  previousPage({ state, commit, dispatch }, page) {
    commit('SET_OFFSET', state.skip - state.limit);
    dispatch('fetchAll');
  },

  nextPage({ state, commit, dispatch }, page) {
    commit('SET_OFFSET', state.skip + state.limit);
    dispatch('fetchAll');
  },

  sort({ state, commit, dispatch }, field) {
    commit('SET_SORT', field);
    dispatch('fetchAll');
  },

  filterCompany({ state, commit, dispatch }, company) {
    const filters = cloneDeep(state.filters);

    if (filters.companyIds.includes(company)) {
      filters.companyIds = filters.companyIds.filter((c) => c !== company);
    } else {
      filters.companyIds.push(company);
    }

    commit('SET_OFFSET', 0);
    commit('SET_FILTERS', filters);
    dispatch('fetchAll');
  },

  filterCategory({ state, commit, dispatch }, category) {
    const filters = cloneDeep(state.filters);

    if (filters.category === category) {
      category = null;
    }

    filters.category = category;

    commit('SET_OFFSET', 0);
    commit('SET_FILTERS', filters);
    dispatch('fetchAll');
  },

  fetchAll({ state, commit, rootState }) {
    if (!state.initialized) commit('SET_INITIALIZED');

    commit('SET_LOADING');

    let query = '';

    query += `&documentStatus=${state.status}`;

    if (state.skip) {
      query += `&offset=${state.skip}`;
    }

    if (state.limit) {
      query += `&limit=${state.limit}`;
    }

    if (state.search) {
      query += `&text=${state.search}`;
    }

    if (state.filters.category) {
      query += `&category=${state.filters.category}`;
    }

    if (state.sortField && state.sortDirection) {
      query += `&sort=${state.sortField}:${state.sortDirection}`;
    }

    if (state.filters.companyIds.length) {
      query += `&filterByCompanyId=${state.filters.companyIds[0]}`;
    }

    let url;

    switch (state.type) {
      case TYPE_COMPANY:
        url = `documents/docs/?scope=COMPANY${query}`;
        break;

      case TYPE_EMPLOYEE:
        url = `documents/docs/?scope=EMPLOYEE${state.employee ? `&employeeId=${state.employee}` : ''}${query}`;
        break;

      default:
        throw new Error('No such document type');
    }

    return new Promise(async (resolve, reject) => {
      try {
        const data = await axios.get(url);
        commit('SET_DOCUMENTS', data);
        resolve(data);
      } catch (e) {
        reject(e);
      } finally {
        commit('SET_LOADING', false);
        commit('SET_LOADED', true);
      }
    });
  },

  [DELETE_DOCUMENT_ACTION]({ state, dispatch, rootState }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        deleteDocument(id, rootState.auth.activeRole);
        // dispatch("notifications/new", {
        //     title: this.app.i18n.t("successDocumentsDeletedHeadline"),
        //     level: "success",
        //     message: this.app.i18n.t("successDocumentsDeleted"),
        // }, { root: true })
        //

        if (state.loaded) dispatch('fetchAll');
        resolve();
      } catch (e) {
        /*
                dispatch("notifications/new", {
                  title: "oops",
                  level: "danger",
                  message: "oops",
                }, { root: true })
                */
      }
    });
  },

  [ARCHIVE_DOCUMENT_ACTION]({ state, dispatch, rootState }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        await archiveDocument(id);

        if (state.loaded) dispatch('fetchAll');
        resolve();
      } catch (e) {}
    });
  },

  async [DELETE_SELECTED_DOCUMENTS_ACTION]({ state, getters, dispatch, rootState }) {
    return new Promise(async (resolve, reject) => {
      const promises = [];

      for (const doc of getters.SELECTED) {
        promises.push(deleteDocument(doc.id, rootState.auth.activeRole));
      }

      await Promise.all(promises);

      // dispatch("notifications/new", {
      //     title: this.app.i18n.t("successDocumentsDeletedHeadline"),
      //     level: "success",
      //     message: this.app.i18n.t("successDocumentsDeleted"),
      // }, { root: true })
      //

      if (state.loaded) dispatch('fetchAll');
      resolve();
    });
  },

  [GRANT_DOCUMENT_ACCESS_TO_HR_ACTION]({ commit, dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      const roles = [ROLE_BAV_MANAGER, ROLE_ORG_ADMIN];

      try {
        // eslint-disable-next-line no-console
        console.log('This action is no longer supported.');
      } catch (e) {
        return reject();
      }

      commit('_ADD_PERMITTED_ROLES_TO_DOCUMENT', {
        id,
        roles,
      });

      resolve();
    });
  },

  [REVOKE_DOCUMENT_ACCESS_TO_HR_ACTION]({ commit, dispatch }, id) {
    return new Promise(async (resolve, reject) => {
      const roles = [ROLE_BAV_MANAGER, ROLE_ORG_ADMIN];

      try {
        // eslint-disable-next-line no-console
        console.log('This action is no longer supported.');
      } catch (e) {
        return reject();
      }

      commit('_REMOVE_PERMITTED_ROLES_FROM_DOCUMENT', {
        id,
        roles,
      });

      resolve();
    });
  },
};
