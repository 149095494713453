const employeeRoutes = [
  {
    path: '/bav-info',
    name: 'bav-info',
    component: () => import('@/views/employee/BavInfo.vue'),
    meta: {
      layout: 'content',
      resource: 'Employee',
      titleKey: 'bavInfoTitle',
    },
  },
  {
    path: '/startseite',
    name: 'startseite',
    component: () => import('@/views/employee/EmployeeDashboard.vue'),
    meta: {
      layout: 'content',
      resource: 'Employee',
      titleKey: 'homeTitle',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/employee/Faq.vue'),
    meta: {
      layout: 'content',
      resource: 'Employee',
      titleKey: 'faqTitle',
    },
  },
  {
    path: '/meine-bav',
    name: 'meine-bav',
    component: () => import('@/views/employee/my-bav/MeineBav.vue'),
    meta: {
      layout: 'content',
      resource: 'Employee',
      titleKey: 'myBavTitle',
    },
  },
];

export default employeeRoutes;
