import regularGerman from './de';

export default {
  ...regularGerman,
  notFoundPageDescriptionNotLogged:
    'Vielleicht verwendest du gerade einen Link, der nicht mehr verfügbar ist. Bitte logge dich ein und aktualisiere dein Lesezeichen (optional).',
  notFoundPageDescriptionLogged:
    'Vielleicht verwendest du gerade einen Link, der nicht mehr verfügbar ist. Bitte logge dich ein und aktualisiere dein Lesezeichen (optional).',

  welcomeChangePwTitle: 'Herzlichen Willkommen in deinem bAV-Portal',
  welcomeChangePwDescription:
    'Bitte wähle ein sicheres Passwort. Das Passwort sollte mindestens 8 Zeichen, davon 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Zahl und 1 Sonderzeichen enthalten. Im nächsten Schritt kannst du dich mit deiner E-Mail-Adresse und deinem Passwort einloggen.',
  enterInformation4: 'Bitte überprüfe deine eingegebenen Daten und korrigiere diese gegebenenfalls.',
  enterInformation3:
    'Hier kannst du angeben, wie viel du von deinem Nettolohn in die bAV investieren möchtest. Diesen Betrag kannst du jederzeit verändern.',
  enterInformation2:
    'Um ein unverbindliches Angebot für dich zu erstellen, benötigen wir noch einige steuerliche Angaben. Natürlich gehen wir sehr vertraulich mit deinen Daten um und halten die aktuellen Datenschutzgesetze ein.',
  enterInformation2Direct:
    'Um direkt eine bAV für dich einzurichten, benötigen wir noch einige steuerliche Angaben. Natürlich gehen wir sehr vertraulich mit deinen Daten um und halten die aktuellen Datenschutzgesetze ein.',
  profileDeleteUser: 'Du bist im Begriff <b>{USER}</b> zu entfernen. Bist du dir sicher?',
  deleteEmployeeConfirmation: 'Du bist im Begriff {count} Mitarbeiter zu entfernen. Bist du dir sicher?',
  // my Bav
  myBavNetContributionDescription:
    'Bitte gib nachfolgend den Nettobeitrag ein, den du monatlich in deine bAV einzahlen möchtest.',
  myBavBeneficiaryDescription:
    'Wen möchtest du als bezugsberechtigte Person hinterlegen, die im Falle deines Todes die vereinbarte Leistung erhalten soll? Dieses Bezugsrecht kann jederzeit von dir geändert werden.',
  myBavDocumentsDescription:
    'In diesem Bereich findest du alle wichtigen Dokumente, die von deiner persönlichen Beraterin oder deiner Personalabteilung für dich hochgeladen wurden. Für Dokumente, die du in der Vergangenheit hochgeladen hast, wird angezeigt, ob diese mit deiner persönlichen Beraterin oder deiner Personalabteilung geteilt wurden.',
  myBavRecycleBinConfirmationMultiple: 'Bist du sicher, dass du alle Dokumente in den Papierkorb verschieben möchtest?',
  myBavRecycleBinConfirmation: 'Möchtest du das ausgewählte Dokument wirklich in den Papierkorb verschieben?',
  // Forgot Password
  forgotPwTitle: '⁣Degura bAV-Portal - Passwort vergessen',
  forgotPwWelcome: 'Dein Passwort zurücksetzen',
  forgotPwWelcomeMsg:
    'Bitte gib deine E-Mail an, damit wir dir einen Link zum Zurücksetzen deines Passwortes zusenden können.',
  forgotPwEmail: 'E-Mail / Benutzername',
  forgotPwReset: 'Zurücksetzen',
  forgotPwCancel: 'Abbrechen',
  forgotPwProblem: 'Du hast Probleme dich einzuloggen?',
  forgotPwSupportLink: 'Support kontaktieren',
  forgotPwConfirm: 'Du hast Post',
  forgotPwConfirmMsg:
    'Du hast von uns eine E-Mail mit einem \n' + 'Link zum Zurücksetzen deines Passworts \n' + 'erhalten.',
  forgotPwBack: 'Zurück zum Login',
  // Login
  loginTitle: '⁣Degura bAV-Portal - Login',
  loginWelcome: 'Herzlichen Willkommen in deinem bAV-Portal',
  loginWelcomeToken:
    '"Bitte gebe die E-Mail-Adresse oder \n' +
    'deinen Benutzernamen ein, welche(n) du \n' +
    'mit deinen Unterlagen zum bAV-Portal von \n' +
    'uns erhalten hast und lege ein \n' +
    'Passwort fest."',

  loginEmail: 'E-Mail / Benutzername',
  loginPassword: 'Passwort',
  loginForgetPw: 'Passwort vergessen?',
  loginBtnText: 'Login',
  loginProblem: 'Du hast Probleme dich einzuloggen?',
  loginSupportLink: 'Support kontaktieren',
  // Outdated
  menuFAQ: 'FAQ',
  linkHeaderOwnProfile: 'Persönliches Profil',
  formLabelPensionSlider: 'Dein Beitrag',
  buttonUpload: 'Upload',
  tooltipNotificCompany: 'Du kannst für jedes Unternehmen eine E-Mail-Adresse für Benachrichtigungen festlegen.',
  tooltipNotificEmail: 'Hier kannst du die E-Mail-Adresse anpassen, an die deine Benachrichtigungen geschickt werden.',
  tooltipRemindMultiple: 'Du kannst mehrere Mitarbeiter auswählen und allen einen Reminder senden.',
  tooltipDeleteMultiple: 'Du kannst mehrere Mitarbeiter auswählen, um diese zu löschen.',
  tooltipLeftArrowDisabled: 'Du befindest dich auf der ersten Seite.',
  tooltipDeleteMultipleDocuments: 'Wähle zunächst Dokumente aus, bevor du diese löschst.',
  tooltipDownloadMultipleDocuments: 'Wähle zunächst Dokumente aus, bevor du diese herunterlädst.',
  tooltipDocsPaginationLeft: 'Du befindest dich auf der ersten Seite der Dokumentenliste.',
  tooltipUploadButtonDisabled: 'Bevor der Upload starten kann, lege für jedes Dokument eine Kategorie fest.',
  tooltipAlertIcon: 'Upload fehlgeschlagen. Bitte versuche es noch einmal!',
  tooltipConcludeButtonDisabled: 'Du kannst den Upload abschließen, sobald alle Datein geladen sind.',
  userGreeting: 'Hallo, {USER_FIRSTNAME}!',
  personalConsultant: '{CONSULTANT_FIRSTNAME}',
  faqAnswer1:
    '<p>Mit einer betrieblichen Altersvorsorge baust du dir dank der Zuschüsse von deinem Arbeitgeber und Staat eine zusätzliche Altersvorsorge auf. Das lohnt sich für dich als Arbeitnehmer, weil sich dadurch dein finanzieller Spielraum im Ruhestand erweitert. Zum Ende der Laufzeit hast du dann auch die Möglichkeit dich zu entscheiden - lebenslange Rente, egal wie alt du wirst, eine einmalige Kapitalauszahlung zum Rentenbeginn oder aber auch ein Mix aus beidem.</p>',
  faqAnswer2:
    '<p>Das entscheidest du selbst. Der Staat fördert einen Beitrag bis 604,00 EUR Brutto inkl. Arbeitgeberzuschüsse pro Monat. Bis zu 302,00 EUR kannst du ohne Abzug von Steuern und Sozialversicherungsbeiträgen in deinen Vertrag einzahlen. Zusätzliche 302,00 EUR sind außerdem steuerfrei.</p><img src="{imgValueMaxContributonDE}"/>',
  faqAnswer3:
    '<p>Wie viel du zurücklegen möchtest, entscheidest du. Ein guter Richtwert für deinen Eigenbeitrag sind etwa 5 bis 8 Prozent des Nettoverdienstes. Dieser Beitrag wird vom Staat und deinem Arbeitgeber noch einmal stark aufgestockt. Bereits mit einem Eigenbeitrag von 50,00 EUR kannst du einen Beitrag von 120,00 EUR generieren, während bei einem Eigenbeitrag von 100,00 EUR ein Beitrag von 240,00 EUR möglich ist.</p><p>Generell gilt die Regel: lieber am Anfang mehr, als am Ende zu wenig. Wenn du den Beitrag zu Beginn oder in der Mitte deines Arbeitslebens etwas höher ansetzt, kannst du ihn jederzeit reduzieren. Setzt du ihn aber von Anfang an zu niedrig an, mag es am Ende zu spät sein, ihn so anzupassen, dass dir im Alter deine Rente ausreicht.</p><img src="{imgRecomContrDE}"/>',
  faqAnswer4:
    'Du entscheidest, wie viel du in deine betriebliche Altersvorsorge einzahlst. Die gewählte Beitragshöhe kann von dir erhöht, reduziert oder pausiert und reaktiviert werden.',
  faqAnswer5:
    '<p>Du kannst deinen Vertrag jederzeit und ohne Angabe von Gründen pausieren.</p><ul><li>Wenn du deine Arbeit nach deiner Elternzeit wieder aufnimmst, kannst du deinen Vertrag wie gewohnt fortführen. Solltest du dann einen neuen Arbeitgeber haben, springe zur Frage: "Was passiert mit meinem Vertrag, wenn sich mein Arbeitgeber ändert?"</li><<li>Du hast immer die Möglichkeit, mit eigenen Mitteln weiter in deine betriebliche Altersvorsorge einzuzahlen. Das kann vor allem im Fall einer Selbstständigkeit interessant sein.</li><li>„Hartz-IV-sicher“: Dein Erspartes der betriebliche Altersvorsorge (bAV) zählt nicht zu den Ersparnissen, die du aufbrauchen musst, bevor du Anspruch auf Arbeitslosengeld II hast. In diesem Fall wäre dein bAV-Kapital sicher vor einer Anrechnung durch den Staat.</li></ul>',
  faqAnswer6:
    '<p>Bei einigen Anbietern kann man einen Vertrag auch aus dem Ausland privat weiter fortführen. Wende dich am besten in diesem Fall an deinen zuständigen Berater.</p>',
  faqAnswer7:
    '<p>Deine bisher eingezahlten Beiträge gehen bei einem Arbeitsplatzwechsel nicht verloren.</p><p>Hier gibt es grundsätzlich drei Möglichkeiten:</p><ol><li>Dein Vertrag wird von deinem neuen Arbeitgeber weitergeführt.</li><li>Dein angespartes Kapital aus der betrieblichen Altersvorsorge  wird in die Versorgungseinrichtung des neuen Arbeitgebers eingezahlt.</li><li>Du lässt deinen bisherigen Vertrag ruhen und erhälst einen neuen. Dein Leistungsanspruch aus der bisherigen betrieblichen Altersvorsorge bleibt dabei selbstverständlich erhalten. Welche dieser Optionen gewählt wird, entscheidet dein neuer Arbeitgeber. </li></ol>',
  faqAnswer8:
    '<p>Deine betriebliche Altersvorsorge ist bei einer Insolvenz geschützt. Selbst wenn es für dein Unternehmen schlecht laufen sollte und die Firma insolvent wird, bekommst du die betriebliche Altersvorsorge wie vereinbart ausgezahlt.</p>',
  faqAnswer9:
    '<p>Einen Leistungsanspruch erfolgt, sobald du die gesetzliche Altersrente beziehst. Der Eintritt in die Bezugszeit der gesetzlichen Altersrente erfolgt abhängig von deinem gesetzlichen Renteneintrittsalter.</p>',
  faqAnswer10:
    '<p>Das entscheidest du selbst. Du kannst dir dein angespartes Kapital zum Rentenbeginn entweder einmalig oder in Form einer lebenslangen monatlichen Rente auszahlen lassen. Auch eine Mischform ist möglich, indem du dir zu Rentenbeginn 30% vom Kapital herausnimmst und dir die restlichen ersparnissen in Form einer monatlichen lebenslangen Rente auszahlen lässt.</p>',
  faqAnswer11:
    '<p>In einem Todesfall vor Renteneintritt gehen alle bis dato eingezahlten Beiträge an die zuvor vertraglich festgelegten Hinterbliebenen. <p> Im Fall des Todes nach Rentenbeginn bekommen die Hinterbliebenen* weiterhin eine Hinterbliebenenrente ausgezahlt, die durch die Rentengarantiezeit festgelegt ist.</p><img src="{imgDeathDE}"/>',
  faqAnswer12:
    '<p>Deine Ansprüche zur gesetzlichen Rente minimieren sich anteilig, da es sich bei den Beitägen zur betriebliche Altersvorsorge um eine sogenannte Entgeltumwandlung handelt. Diese Differenz wird jedoch, durch die zusätzlichen Ansprüche aus deiner betrieblichen Altersvorsorge um ein Vielfaches ausgeglichen.</p><p>Das veranschaulicht dir diese Grafik:</p><img src="{imgStatutory}"/>',
  faqAnswer13:
    '<p>Die Leistung* deiner betriebliche Altersvorsorge hängt von der Wertentwicklung deines Tarifs ab. Informationen zu den Tarifen der betrieblichen Altersvorsorge, wie auch Beispielrechnungen findest du unter dem Menüpunkt “bAV-Info”.<p>In dieser Grafik siehst du, wie sich die Leistung deiner betriebliche Altersvorsorge mit verschiedenen Wertenwicklungen auf deine monatliche Rente und auf das Kapital auswirken kann.</p><img src="{imgBenefits}"/>',
  faqAnswer14:
    '<p>Deine betriebliche Altersvorsorge hat keinerlei Auswirkungen auf den Lohnsteuerjahresausgleich, weshalb du diese nicht gesondert in der Steuererklärung angeben musst. Beachten bei der Erstellung deiner Steuererklärung musst du nur, das setzen eines Kreuzes im Mantelbogen - bei Fragen, wende dich gerne an deinen Steuerberater.</p>',
  faqAnswer15:
    '<p>Ja, die lebenslange Rente unterliegt der Steuer- und Pflegeversicherungspflicht somit sind Abgaben fällig. Beiträge zur Krankenversicherung hingegen, zahlst du nur dann, wenn deine monatliche betriebliche Altersvorsorge über dem sich jährlichen erhöhenden Freibetrag liegt.</p><p>Bei den meisten Arbeitnehmenden fällt die Rente geringer aus, als ihr Gehalt im Arbeitsleben. In diesem Fall sind die Steuereinsparungen während deines Arbeitslebens höher, als im Rentenalter.</p>',
  faqAnswer16:
    '<p>Bei einer Kapitalauszahlung deiner betriebliche Altersvorsorge in Deutschland können verschiedene Abgaben anfallen. Du musst Einkommensteuer auf die Auszahlung zahlen, die sich nach deinem individuellen Steuersatz richtet. Je nach deinen individuellen Umständen kann auch eine Kranken- und Pflegeversicherungspflicht bestehen.</p>',
  faqAnswer17:
    '<p>Beim Abschluss einer betrieblichen Altersvorsorge im Durchführungsweg der betriebliche Altersvorsorge fallen Abschlusskosten, Vertriebskosten und laufende Verwaltungskosten an. Nähere Informationen dazu erhältst du durch deinen Berater und in den Verbraucherinformationen.</p>',
  switchRoleInfo:
    'Du bist als {USER_ROLE} aktiv. Wechsele zu deinem persönlichen Mitarbeiter-Profil, um deine persönlichen und steuerlichen Daten zu bearbeiten.',
  uploadInfo1: 'Bitte ziehe deine Datei in das Feld oder ',
  messageMaxUploadAmount: 'Bitte wähle maximal fünf Dateien gleichzeitig aus!',
  myBavUploadDocumentText2: 'deine Dateien (max. 5 Dateien)',
  myBavUploadDocumentText1: 'Bitte ziehe deine Datei in das Feld oder ',
  paragraphFunctionality1:
    '{COMPANY} zahlt dir ein Bruttogehalt. Von diesem werden Steuern und Sozialversicherungen abgezogen. Zur freien Verfügung bleibt dir damit nur das Netto.',
  paragraphFunctionality3:
    'Die bAV ermöglicht es dir in Form einer sogenannten Entgeltumwandlung Teile des Bruttogehaltes für deine Altersvorsorge zu verwenden, die sonst an den Staat gehen würden. Weil {COMPANY} deine Altersvorsorge am Herzen liegt, erhältst du einen weiteren Zuschuss obendrauf. Du sparst im Endeffekt also doppelt!',
  noticeConfirmCorrectness:
    'Durch den Klick auf „Weiter“ bestätige ich die Richtigkeit meiner Daten und stimme zu, für eventuelle Rückfragen telefonisch kontaktiert werden zu dürfen.\n\n Weiterhin bestätige ich, dass mir wichtige Informationen sowie Kopien von persönlichen Formularen per E-Mail zugesendet werden dürfen.',
  noticeConfirmRenunciation: 'Deine Auswahl: Ich verzichte auf eine bAV.',
  noticeConfirmAccept: 'Deine Auswahl: Eine bAV soll für mich eingerichtet werden.',
  noticeConfirmRequest: 'Deine Auswahl: Ich möchte ein individuelles Angebot für eine bAV anfragen.',
  noticeContributionExample:
    'Diese Berechnung dient lediglich der Veranschaulichung ohne Berücksichtigung deiner persönlichen Steuer- und Sozialversicherungsbeiträge. Eine auf deine persönliche Situation abgestimmte Berechnung bekommst du von uns im individuellen Angebot zugesendet.',
  noticePensionCalculatorTaxData:
    'Um mit dem Pensionsrechner noch genauere Ergebnisse zu erzielen, brauchen wir einige steuerliche Angaben von dir. Diese kannst du hier hinterlegen.',
  noticeSendLoginLink:
    'Fordere hier deinen persönlichen Login-Link per E-Mail an, um dich im bAV-Portal über die betriebliche Altersvorsorge zu informieren.',
  noticeAlreadyCreatedPassword: "Du hast dir bereits ein Passwort erstellt? {0} geht's direkt zum Login.",
  noticeSubsidyLimit:
    '{COMPANY} bezuschusst deine bAV mit {SUBSIDY} bis zu einem Gehaltniveau der Beitragsbemessungsgrenze (BBG). Liegt dein Gehalt über der BBG, so entfällt dieser Zuschuss.',
  infoDecline: 'Du erhältst eine Bestätigung deiner Verzichtserklärung.',
  infoDeclineEmail:
    'Gib eine E-Mail-Adresse an, wenn du eine Bestätigung deines Verzichts auf diesem Weg erhalten möchtest.',
  infoAccept: 'Wir melden uns umgehend für alle weiteren Schritte bei dir.',
  pensionSliderWageLabel: 'Dein Bruttolohn in EUR (monatlich)',
  introFAQ: 'Deine Fragen, unsere Antworten',
  introDecline: 'Bist du sicher, dass du die Vorteile einer bAV nicht in Anspruch nehmen möchtest?',
  introAcceptOrRequest: 'Dein erster Schritt hin zu einem sorgenfreien Ruhestand.',
  introSubFAQ: 'In diesem Bereich findest du die wichtigsten Fragen und Antworten zur bAV zusammengefasst.',
  subheadlineFAQ: 'Allgemein',
  subHeadingFAQ: 'Meistgestellte Fragen',
  introSubEmployeeWelcome:
    'Schön, dass du hier bist. In diesem Bereich kannst du dich über die Leistungen und Vorteile einer betrieblichen Altersvorsorge (bAV) informieren.',
  introSubDecline:
    'Schade, dass du nicht mit dabei bist. Nach deiner Abmeldung wirst du keine weiteren E-Mails von uns erhalten. Solltest du es dir anders überlegen, kannst du deine Entscheidung jederzeit rückgängig machen.',
  introSubAcceptOrRequest:
    'Schön, dass du dich für eine bAV entschieden hast. Du hast es gleich geschafft! Fülle die erforderlichen Angaben zu deiner Person aus und teile uns deinen gewünschten Monatsbeitrag mit. Im Anschluss kontaktiert dich dein persönlicher Berater, um alle weiteren Inhalte mit dir zu klären.',
  introSubMyCP: 'In diesem Bereich kannst du deinen aktuellen bAV-Status abrufen.',
  headlineConsultantMale: 'Dein Berater',
  headlineConsultantFemale: 'Deine Beraterin',
  headlineContactMale: 'Dein persönlicher Ansprechpartner',
  headlineContactFemale: 'Deine persönliche Ansprechpartnerin',
  headlineLogin: 'Willkommen in deinem bAV-Portal!',
  headlineResetPassword: 'Dein Passwort zurücksetzen',
  headlineCheckInbox: 'Schau in dein E-Mail Postfach',
  headlineFAQ: 'Deine Fragen, unsere Antworten',
  headlineEmployerSettings: 'Deine Unternehmens-Einstellungen',
  headlineEmployerRoleAssignment: 'Deine Unternehmens-Rollen',
  headlineEmployeeProfile: 'Dein persönliches Profil',
  headlineEmployeeSettings: 'Deine persönlichen Einstellungen',
  headlineContactFAQ: 'Deine Frage war nicht dabei?',
  subheadlineContactFAQ: 'Dein(e) persönliche(r) Berater(in) nimmt sich gerne Zeit, diese zu beantworten.',
  headlineContact: 'Du hast noch Fragen?',
  headlineEmployerWelcome: 'Herzlich Willkommen in deinem bAV-Portal',
  headlineContractualDocuments: 'Deine Auftragsunterlagen',
  headlineDocuments: 'Deine Dokumente',
  headlineUploadOverlay1: 'Lege die Dokumentenkategorie fest',
  headlineFileUploadOverlay: 'Deine Dateien werden hochgeladen',
  headlineCTAAcceptOffer: 'Bereit für deinen Ruhestand?',
  headlineCTARequestOffer: 'Mehr Informationen gefällig?',
  headlineCTAPrivateChat: 'Hast du eine individuelle Frage?',
  headlineStatusProgressBar: 'Alles, was du wissen musst',
  headlineExamplesTotalContribution: 'Mit einer bAV profitierst du gleich doppelt!',
  headlineProductDataSheetDownload: 'Deine {COMPANY} Produktdatenblätter',
  headlineConsultationCTA: 'Herzlichen Glückwunsch! Du bist jetzt bAV-Experte.',
  headlinePersonalDataInput: 'Bitte gib zur Bestätigung deine Daten ein',
  headlineAcceptOffer: 'Bitte fülle zunächst folgende Felder zu deiner Person aus',
  headlineContactDataInput: 'Bitte sag uns, wie wir dich erreichen können',
  headlineCompanyPensionInput: 'Lege deinen individuellen Monatsbeitrag fest',
  headlineCompanyPensionCalculator: 'Berechne deine Vorteile',
  headlinePensionCalculator: 'Dein potenzieller Gesamtbeitrag nach Arbeitgeberzuschuss',
  headlineTaxDataInput: 'Um das Angebot auf dich abzustimmen, benötigen wir noch einige steuerliche Angaben',
  headlineCheckData: 'Deine persönlichen Angaben',
  headlineEvents: 'Bleib auf dem Laufenden',
  headlineCompanyPensionStatusBar: 'Dein bAV-Antragsstatus',
  headlineMoveCompanyPension: 'Du wechselst das Unternehmen?',
  headlineReminder: 'Reminder versenden',
  headlineAcceptPrivacyPolicy: 'Deine Privatsphäre ist uns wichtig',
  headlineFileNotFound: 'Die Seite, die du suchst, existiert nicht',
  messageDeleteSelectedDocuments: 'Du bist im Begriff {count} Dokument(e) zu löschen. Bist du dir sicher?',
  messageArchiveDeleteSelectedDocuments:
    'Du bist im Begriff {count} Dokument(e) unwiderruflich zu löschen. Bist du dir sicher?',
  messageEmployeeEdit:
    'Hier kannst du steuerliche und persönliche Informationen bearbeiten, den bAV-Status einsehen oder Dokumente mit dem Mitarbeiter teilen.',
  messageAcceptPrivacyPolicy:
    'In unserer {0} kannst du nachlesen, wie wir deine Daten sichern und verarbeiten.\n\n Damit wir das dürfen, benötigen wir deine Zustimmung.',
  messageDeleteSelectedEmployees: 'Du bist im Begriff {count} Mitarbeiter zu entfernen. Bist du dir sicher?',
  subheadingLogin: 'Bitte gib hier deine Login-Daten ein, die du mit deinen Unterlagen zum bAV-Portal erhalten hast.',
  receivedEmailNoticeResetPassword:
    'Wenn deine E-Mail-Adresse in unserem System erfasst ist, hast du eine E-Mail mit einem Link zum Zurücksetzen deines Passworts erhalten.',
  emailMissingNoticeResetPassword: 'Falls du keine E-Mail bekommen hast, wende dich bitte an deine Personalabteilung.',
  subheadingResetPassword:
    'Bitte gib deine E-Mail-Adresse an, damit wir dir einen Link zum Zurücksetzen deines Passwortes zusenden können.',
  messageFAQ:
    'Die betriebliche Altersvorsorge kann ein komplexes Thema sein. Wie läuft das mit der Steuererklärung? Mit welcher Rendite kann ich rechnen? Wie hoch oder niedrig ist der ideale Beitrag? Hier findest du die wichtigsten Fragen und Antworten zur bAV zusammengefasst.',
  messageConsultationCTA:
    'Nun entscheidest du!\n \nSollten noch Fragen offen sein, kannst du diese im privaten Chat mit deinem persönlichen Berater klären.\n \nFalls du dich schon entschieden hast, kannst du auch direkt eine bAV abschließen oder ein unverbindliches, auf dich individuell abgestimmtes Angebot anfragen. Bei beiden Varianten wird dich im Anschluss dein Berater kontaktieren und alle weiteren vertraglichen Inhalte persönlich mit dir klären.\n \nWofür möchtest du dich entscheiden?',
  messageContactFAQ: 'Unsere Berater nehmen sich gerne Zeit, sie dir zu beantworten.',
  messageContact: 'Unsere Berater beantworten sie dir gerne.',
  messageEmployerRoleAssignment:
    'In diesem Bereich kannst du innerhalb deiner zu verwaltenden Unternehmen anderen Mitarbeitern Rollen zuweisen, damit diese dich bei administrativen Aufgaben unterstützen können.',
  messageEmployerSettings: 'In diesem Bereich kannst du die Einstellungen für dein Benutzerkonto verwalten.',
  subHeaderOwnProfile:
    'Dieser Bereich ist eine Übersicht über deine persönlichen und steuerlichen Daten. Du kannst hier beliebig Anpassungen vornehmen. Beachte hierbei, dass eventuell Änderungen in deinen vertraglichen Unterlagen notwendig werden, falls du bereits eine bAV bei uns abgeschlossen hast.',
  messageEmployeeSettings: 'In diesem Bereich kannst du die Einstellungen für dein Benutzerkonto verwalten.',
  messageEmployerWelcome:
    'Dieser Bereich bietet dir eine Übersicht über alle {COMPANY} Mitarbeiter. Nachdem du deine Mitarbeiterliste hochgeladen hast, kannst du hier ihren bAV-Status einsehen und Dokumente mit ihnen austauschen.',
  messageEmployeeOverview:
    'Füge neue Mitarbeiter in einer Excel-Datei (eine Vorlage kannst du {0} herunterladen) oder manuell in der Übersicht hinzu. Du kannst nachträglich jederzeit neue Mitarbeiter hinzufügen und alte aus der Liste entfernen.',
  messageDeleteEmployee: '{NAME} ist ein fehlerhafter Eintrag? Entferne ihn/sie aus der Mitarbeiterliste.',
  messageSaveEditing:
    'Du bist im Begriff, die Angaben von {NAME} zu verändern. Gegebenenfalls sind Vertragsanpassungen notwendig. Sollen die folgenden Felder angepasst werden?',
  messageUnsavedChanges:
    'Du bist im Begriff, die Seite zu verlassen, ohne deine vorgenommenen Änderungen zu speichern. Wenn du fortfährst, gehen folgende Änderungen verloren:',
  messageCancelEdit:
    'Du bist im Begriff, die Bearbeitung abzubrechen. Wenn du fortfährst, gehen folgende Änderungen verloren:',
  messageSaveEmployeeStatus:
    'Du bist im Begriff, {NAME} als ausgetreten zu melden. Er/Sie erscheint dann nicht mehr in der Mitarbeiterübersicht. Möchtest du fortfahren?',
  messageDeleteEmployeeOverlay:
    'Du bist im Begriff, {NAME} zu löschen. Er/Sie wird damit unwiderruflich entfernt. Möchtest du fortfahren?',
  messageChangeBavStatus:
    'Du bist im Begriff, den bAV-Status des Mitarbeiters zu ändern. Bitte bestätige, dass du diese Änderung wirklich vornehmen möchtest.',
  messageStatusProgressBar:
    'Verschaffe dir in drei einfachen Schritten einen Überblick über die Funktionsweise einer bAV. Im Anschluss entscheidest du!',
  messageContractualDocuments:
    'In diesem Bereich findest du alle für relevanten Dokumente, wie Versorgungsordnung und Gruppenverträge der Mitarbeiter.',
  messageCTAAcceptOffer:
    'Nutze die finanziellen Vorteile, die eine bAV für dich bereithält und beginne heute, für deine Zukunft vorzusorgen.',
  messageeIntroVideo:
    'Im folgenden Video erfährst du mehr über die Leistungen einer bAV und wie du diese für dich nutzen kannst.',
  messageExampleCalculations:
    'Anhand der folgenden Beispiele werden dir die Vorteile einer bAV – basierend auf unterschiedlichen Bruttolöhnen und bAV-Beiträgen – veranschaulicht. Wähle einfach das Beispiel aus, das deiner Situation am nächsten kommt. Weiter unten stehen dir alle drei Beispiele auch zum Download zur Verfügung.\n \nUm ein vollkommen auf dich und deine Situation abgestimmtes Angebot zu erhalten, kannst du später gerne ein kostenloses, individuelles und unverbindliches Angebot anfragen.',
  messageTaxDataInput: 'Alle erforderlichen Daten findest du auf deinem Gehaltsauszug.',
  messageCompanyPensionInput:
    'Hier kannst du angeben, wie viel deines Nettolohns du in die bAV investieren möchtest.\n \nDiesen Betrag kannst du später auch nochmal verändern.',
  messageCaseOfDeath:
    'Wenn du derzeit keine Person angeben möchtest, wähle die Option "Keine Auswahl". Du kannst diese Einstellung jederzeit ändern.',
  messageCheckData:
    'Bitte überprüfe nochmals deine eingegebenen Daten. Falls notwendig, kannst du sie hier korrigieren.',
  messageProductDataSheetDownload:
    'Lade dir hier das Produktinformationsblatt herunter, um mehr über das bAV-Produkt deines Arbeitgebers zu erfahren.',
  messageExampleCalculationsDownload:
    'Hier kannst du dir die PDFs zur Beispielrechnung herunterladen. Darin findest du alle oben dargestellten Informationen etwas ausführlicher am Beispiel von Lohnabrechnungen, sowie Auswertungen von Kapitalleistungen und Zuschüssen.',
  messageCompanyInfoDownload:
    'Hier kannst du dir weitere Informationen zur genauen Ausgestaltung der bAV-Versicherung in deinem Unternehmen herunterladen.',
  messageInsuranceProductOverview:
    'Im Folgenden sind alle Produkte aufgelistet, die dein Arbeitgeber dir im Rahmen der bAV zur Verfügung stellt.\n\nDu kannst frei wählen, für welches dieser Produkte du dich entscheiden möchtest. In den PDF’s, die wir dir unten zum Download bereit stellen, erhältst du alle wichtigen Informationen für deine Entscheidung.',
  messageEmployeeEvents: 'Dieser Bereich bietet dir eine Übersicht über alle aktuellen Vorgänge rund um deine bAV.',
  messageEmployerEvents:
    'Dieser Bereich bietet dir eine Übersicht über alle aktuellen Vorgänge innerhalb deines Unternehmens.',
  messageEmployeeDocuments:
    'In diesem Bereich sind deine wichtigsten persönlichen Dokumente hinterlegt. Du findest hier zum Beispiel Policen oder Angebotsrechnungen für deine bAV.',
  messageEmployerDocuments:
    'In diesem Bereich findest du eine Übersicht aller Dokumente, die du mit den {COMPANY} Mitarbeitern teilst.',
  messageCompanyPensionCalculator:
    'Mit dem Pensionsrechner kannst du prüfen, welche Zuschüsse du bei verschiedenen Monatsbeiträgen erhälst. So trifft sich manche Entscheidung leichter!',
  messageUploadOldContract: 'Du hast bereits eine bAV? Hinterlege hier ganz einfach deinen Bestandsvertrag.',
  messageMoveCompanyPension:
    'Deine bAV-Beiträge gehen bei einem Arbeitsplatzwechsel nicht verloren. Teile uns deinen neuen Arbeitgeber mit und sprich mit ihm darüber, in welcher Form die bAV fortgeführt werden soll.',
  messageDeleteDocuments:
    'Du bist dabei, Dokumente zu löschen. Mit dieser Aktion verschiebst du die ausgewählten Dokumente unwiderruflich in den „Gelöscht“-Ordner. Möchtest du fortfahren?',
  messageImportEmployee:
    'Fülle die Felder für den neuen Mitarbeiter aus, um ihn zur Liste deiner Mitarbeiter hinzuzufügen.',
  messageSelectEmployee: 'Bitte lege einen Mitarbeiter fest, mit dem du die Dokumente teilen willst.',
  messageSelectCompany: 'Bitte wähle ein Unternehmen aus, für das du die Dokumente bereitstellen möchtest.',
  messageFileNotFound:
    '<p>Leider konnten wir die Seite, nach der du suchst, nicht finden. Prüfe die URL, ob sich ein Schreibfehler eingeschlichen hat.</p><p>Es kann auch sein, dass diese Seite umgezogen wurde oder nicht mehr existiert. Sollte dieses Problem weiter bestehen, kontaktiere gerne unser Degura Support Team.</p>',
  messageShareWithHR:
    'Deine Dokumente werden automatisch mit deinem persönlichen Berater geteilt. Solltest du die Dokumente zusätzlich mit deiner zuständigen HR-Abteilung teilen wollen, setze bitte unten ein Häkchen in der Checkbox.',
  messageCookieConsentForFeature:
    'Um unsere Funktionen wie Chat und Terminvereinbarung nutzen zu können, ist es notwendig, dass du der Verwendung von Cookies zustimmst. Durch Klick auf [Bestätigen] kannst du uns deine Zustimmung dafür geben.',
  successDocumentsUploaded: 'Deine Dokumente wurden erfolgreich hochgeladen.',
  successChangeFieldChangeContract:
    'Das Feld wurde erfolgreich aktualisiert. In den kommenden Tagen veranlasst dein Degura Ansprechpartner eine Korrektur in den Vertragsunterlagen.',
  successDeclineOfferMessage: 'Dein Verzicht auf eine bAV wurde erfolgreich eingereicht.',
  successAcceptOfferMessage:
    'Dein Vertragswunsch wurde erfolgreich eingereicht. In den kommenden Tagen wird sich dein persönlicher Berater bei dir melden.',
  successRequestOfferMessage:
    'Deine Angebotsanfrage wurde erfolgreich eingereicht. In den kommenden Tagen wird sich dein persönlicher Berater bei dir melden.',
  errorLoginCredentials:
    'Der von dir eingegebene Username oder das Passwort ist nicht korrekt. Bitte versuche es nochmal.',
  errorLoginChangePassword: 'Die Passwörter stimmen nicht überein. Bitte wiederhole deine Eingabe.',
  errorExcelImportHeadline:
    'Folgende Einträge in der Excel-Datei waren leider fehlerhaft. Bitte korrigiere diese und wiederhole den Import für diese Zeilen.',
  errorChangeField:
    'Dieses Feld konnte nicht aktualisiert werden. Sollte der Fehler erneut auftreten, wende dich bitte an das Degura Support-Team.',
  errorChangePassword:
    'Dein Passwort konnte nicht geändert werden. Bitte versuche es erneut. Sollte das Problem weiterhin auftreten, wende dich bitte an das Degura Support-Team.',
  errorPasswordForgottenInvalidEmail:
    'Es sieht so aus, als gäbe es keine mit deinem Konto verknüpfte E-Mail-Adresse. Bitte wende dich an das Degura Support-Team.',
  errorInvalidEmail: 'Bitte gib eine E-Mail mit gültigem Format ein.\n Bsp.: max.mustermann@gmail.com',
  errorInvalidPhone: 'Nummer ungültig. Bitte überprüfe diese nochmal.',
  errorPhoneLength: 'Die Telefonnummer muss mindestens 10 Zeichen lang sein.',
  errorGrantChangeMessage:
    'Die Freigabeeinstellungen für das Dokument konnten leider nicht aktualisiert werden. Sollte das Problem weiterhin bestehen, wende dich bitte an das Degura Support-Team.',
  errorSendLoginLink:
    'Leider konnten wir keine gültige E-Mail-Adresse finden, an die wir deinen Login-Link senden können. Bitte gib hier deine E-Mail-Adresse an:',
  changePasswordTokenExpiredMessage: 'Das Token zum Ändern deines Passworts ist abgelaufen.',
  redirectMessageSuccessChangePassword:
    'Passwort erfolgreich geändert! Du wirst in {0} Sekunden auf die Anmeldeseite umgeleitet.',
  keyAccountYourClientCompaniesAssigned: 'deinen Kundenunternehmen',
  keyAccountYourClientCompanies: 'deine Kundenunternehmen',
  keyAccountYourClientCompaniesEmployees: 'deinen Kundenunternehmen zugeordnete Mitarbeiter',
  keyAccountYourClientCompaniesEmployeeDocuments: 'deinen Kundenunternehmen zugeordneten Mitarbeiter-Dokumente',
  successTitle: 'Das hat geklappt!',
  error404Message:
    'Unser System konnte etwas nicht dort finden, wo es sein sollte. Bitte melde Dich bei unserem Support.',
  errorSessionExpiredMessage: 'Deine Sitzung wurde vom System automatisch beendet. Bitte melde dich erneut an.',
  successExistingContractPosted: 'Dein bestehender bAV-Vertrag wurde in deinem Profil hinterlegt.',
  errorExistingContract:
    'Leider ist etwas schiefgelaufen. Versuche es noch einmal oder kontaktiere deinen persönlichen Degura Berater.',
  errorChangePasswordNoEmail:
    'Leider ist in deinem Konto keine E-Mail-Adresse hinterlegt. Bitte wende dich an deinen Degura Berater.',
  messageToggleShareWithHR:
    'Du bist im Begriff folgendes Dokument für deine Personalabteilung freizugeben. Möchtest du fortfahren?',
  personalProfileDescription:
    'In diesem Bereich kannst du deine Profilinformationen bearbeiten, die für deine betriebliche Altersvorsorge verwendet werden. Bitte beachte: Wenn du Nach Abschluss einer betrieblichen Altersvorsorge deine Entscheidung ändern möchtest, musst du dies über die Seite "Meine bAV" tun.',
  personalProfileTitle: 'Eigenes Profil',
  auth2faPhoneLabel: 'Bitte gib deine Mobilnummer ein',
  passwordNeedsToBeChanged:
    'Dein Passwort entspricht nicht den Anforderungen. Bitte ändere es, um dich einloggen zu können.',
  errorBreachedPassword: 'Bitte verwende ein neues Passwort, da dieses als sehr unsicher eingestuft wird.',

  successContractRequestNotification: 'Wir haben deine Anfrage erhalten und werden uns bei dir melden.',
  successOfferRequestNotification: 'Wir haben deine Anfrage erhalten und werden uns bei dir melden.',

  waiverText: 'Du möchtest keine bAV abschließen?',
};
