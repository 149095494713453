import { SORT_ASC, SORT_DESC } from '@/constants';

export const getDefaultState = () => {
  return {
    initialized: false,
    loading: false,
    errors: false,
    page: [],
    skip: 0,
    total: 0,
    limit: 10,
    showAll: false,
    lastPage: false,
    infiniteLoading: false,
    currentPage: 1,
    totalPagesComputed: 1,
    currentPageCompanies: 1,
    sortField: 'personalDetails.lastName',
    sortDirection: SORT_ASC,
    search: '',
    pagination: [],
    filters: {
      companyIds: [],
      status: null,
    },
  };
};

// initial state
const state = getDefaultState();

export default state;
