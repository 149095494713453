import { getDefaultState } from './state';

export default {
  resetConsultant(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CONSULTANT(state, data) {
    state.consultant = data;
  },
  SET_LOADING(state, flag = true) {
    state.loading = flag;
  },
};
