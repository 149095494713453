var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'wrapper-hidden': !_vm.bubbleState.opened }},[_c('v-card',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"bubble-card v-card--no-border",class:{
      'bubble-hidden': !_vm.bubbleState.opened,
      'elevation-9': _vm.bubbleState.opened,
    },attrs:{"tile":""}},[_c('v-card-text',{staticClass:"position-relative d-flex flex-row align-center pa-0",class:{ 'elevation-8 pa-5': _vm.bubbleState.opened }},[_c('v-avatar',{staticClass:"mr-4 v-avatar_outer primary--text",class:{
          'bubble-hidden': _vm.bubbleState.opened,
        },attrs:{"outlined":"","size":"72"}},[_c('v-avatar',{class:{
            'bubble-hidden': _vm.bubbleState.opened,
          },attrs:{"color":"white","size":"70"}},[_c('v-avatar',{staticClass:"primary",attrs:{"size":"60","loading":true},on:{"click":_vm.toggleOpened,"mouseenter":_vm.toggleOpened}},[_c('v-img',{attrs:{"src":_vm.consultant.profilePicture},on:{"load":function($event){_vm.loaded = true}}}),_c('v-overlay',{staticClass:"v-overlay--absolute v-overlay--support",attrs:{"value":!_vm.loaded,"primary":""}},[(!_vm.loaded)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"60","color":"primary"}}):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"mr-3 ml-1",class:{ 'bubble-content-hidden elevetion-11': !_vm.bubbleState.opened }},[_c('v-card-title',{staticClass:"pt-0 px-0 text-base primary--text font-weight-bold"},[_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.consultant.firstName)+" "+_vm._s(_vm.consultant.lastName)+" ")])]),_c('v-card-subtitle',{staticClass:"text-xs pa-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.consultant['headlineTranslationKey']))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('PHONE'))+": "+_vm._s(_vm.consultant.phoneNumber)+" ")])],1),(_vm.bubbleState.opened)?_c('v-tooltip',{attrs:{"top":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var chatAttrs = ref.chatAttrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","color":"primary","disabled":!_vm.acceptedNecessary,"x-small":""},on:{"click":_vm.bookingBtnPressed}},'v-btn',chatAttrs,false),on),[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChat)+" ")])],1)]}}],null,false,1761525070)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttonStartChat')))])]):_vm._e(),(_vm.bubbleState.opened)?_c('v-tooltip',{attrs:{"top":"","open-delay":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var videoAttrs = ref.videoAttrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 ml-2",attrs:{"fab":"","dark":"","color":"primary","x-small":"","disabled":!_vm.acceptedNecessary},on:{"click":_vm.videoBtnPressed}},'v-btn',videoAttrs,false),on),[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" "+_vm._s(_vm.icons.mdiVideoOutline)+" ")])],1)]}}],null,false,763966343)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('formLabelVideoCall')))])]):_vm._e()],1)],1),_c('ModalWindow',{attrs:{"is-open":_vm.videoBookingModal,"title":_vm.$t('formLabelVideoCall'),"maxWidth":800},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"iframe-container d-flex flex-row align-items-center",staticStyle:{"height":"100%"}},[(_vm.videoBookingModal)?_c('iframe',{staticStyle:{"border":"0","width":"100%"},attrs:{"src":_vm.currentLang.startsWith('de')
              ? ((_vm.consultant.appointmentSchedulingLink) + "&lang=de_CH")
              : ((_vm.consultant.appointmentSchedulingLink) + "&lang=en_US"),"scrolling":"yes"}}):_vm._e()])]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"ml-0 mt-3",attrs:{"outlined":"","block":"","color":"primary"},on:{"click":_vm.cancelBtnPressed}},[_vm._v(" "+_vm._s(_vm.$t('buttonCancel'))+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }