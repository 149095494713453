<template>
  <v-list-item
    v-if="canViewVerticalNavMenuLink(item)"
    v-bind="linkProps"
    :class="{ 'bg-secondary': isActive }"
    class="vertical-nav-menu-link pr-0"
    active-class="bg-secondary"
  >
    <v-list-item-icon>
      <v-icon :class="{ 'alternate-icon-small': !item.icon }" :color="'primary'">
        {{ item.icon || alternateIcon }}
      </v-icon>
    </v-list-item-icon>

    <v-list-item-title :color="isActive ? 'primary' : null">
      {{ t(item.title) }}
    </v-list-item-title>
    <v-list-item-action v-if="item.badge" class="flex-shrink-0">
      <v-badge :color="item.badgeColor" inline :content="item.badge"> </v-badge>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import themeConfig from '@themeConfig';
import { useUtils } from '@core/libs/i18n';
import { useUtils as useAclUtils } from '@core/libs/acl';
import useVerticalNavLink from '@core/layouts/composable/vertical-nav/useVerticalNavLink';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps } = useVerticalNavLink(props.item);
    const { t } = useUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      alternateIcon: themeConfig.menu.groupChildIcon,

      // i18n
      t,

      // ACL
      canViewVerticalNavMenuLink,
    };
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item:before {
}

.bg-secondary {
  background: rgba(40, 48, 206, 0.25);
  &::before {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}
.vertical-nav-menu-link {
  color: #2830ce !important;
  &:before {
  }
  &.v-list-item--active {
    box-shadow: none;
    @include elevationTransition();
  }
}
</style>
