import axios from '@/plugins/axios';

export const getCustomerInfoDocuments = () => {
  return axios.get(`/documents/docs/customer-info`);
};

export const uploadCustomerInfoDocument = (doc) => {
  // eslint-disable-next-line no-console
  console.log('This action is no longer supported.');
};

export const changeDocumentCategory = (id, role, category) => {
  // eslint-disable-next-line no-console
  console.log('This action is no longer supported.');
};

export const archiveDocument = (id) => axios.patch(`/documents/docs/${id}`, { status: 'ARCHIVED' });

export const deleteDocument = (id) => {
  // eslint-disable-next-line no-console
  console.log('This action is no longer supported.');
};

export const uploadDocument = (formData, controller) => {
  const config = {
    onUploadProgress: (progressEvent) => progressEvent,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: controller.signal,
  };

  return axios.post('/documents/docs', formData, config);
};

export const uploadExistingBav = (id, formData, role, type = 'employee') => {
  // eslint-disable-next-line no-console
  console.log('This action is no longer supported.');
};

export const downloadDocument = async (id) => {
  const res = await axios.get(`/documents/docs/${id}/download`);

  // creating an invisible element
  const element = document.createElement('a');
  element.setAttribute('href', res.data.uri);
  element.setAttribute('target', '_blank');

  document.body.appendChild(element);
  element.click();

  document.body.removeChild(element);
};

export const categorizeDocument = (fileId, categoryCode) => {
  // eslint-disable-next-line no-console
  console.log('This action is no longer supported.');
};
