// eslint-disable-next-line object-curly-newline
import { mdiAccountGroup } from '@mdi/js';

export default [
  {
    title: 'employeePageNavTitle',
    icon: mdiAccountGroup,
    to: 'mitarbeiter',
    action: 'read',
  },
];
