import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/index.scss';
import Vue from 'vue';
import App from './App.vue';
import './plugins/acl';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import cookieConsentPlugin from '@/plugins/cookie-consent';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { initializePiwik } from '@/plugins/piwik';
import { config } from '../appConfig';

const consentOptions = {};

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(cookieConsentPlugin, consentOptions);

Vue.config.productionTip = false;

initializePiwik(config.variables.CURRENT_ENV);
Sentry.init({
  Vue,
  environment: config.variables.CURRENT_ENV,
  dsn: config.sentry.dsn,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//],
    }),
  ],
  tracesSampleRate: config.sentry.sampleRate,
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
