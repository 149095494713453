import piwikPROD from '@/plugins/piwik/piwikPROD';
import piwikSTAGING from '@/plugins/piwik/piwikSTAGING';
import piwikDEV from '@/plugins/piwik/piwikDEV';

export const initializePiwik = (env) => {
  switch (env) {
    case 'develop':
      piwikDEV();
      break;
    case 'prod':
      piwikPROD();
      break;
    case 'stage':
      piwikSTAGING();
      break;
    default:
      piwikDEV();
      break;
  }
};
